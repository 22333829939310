import React from 'react';
import { connect } from 'react-redux';

// @material-ui/core components
import { Slide, Dialog, DialogContent, DialogActions, FormControlLabel, Radio, withStyles } from '@material-ui/core/';

// icons
import { Close, Add, FiberManualRecord } from '@material-ui/icons';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import SelectUnitaOrganizzative from '../Unita Organizzative/SelectUnitaOrganizzative.jsx';
import SelectUtenti from '../Unita Organizzative/SelectUtenti.jsx';
import PosizionamentoFirmaWrapper from './PosizionamentoFirmaWrapper.jsx';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import { alertActions, userActions } from '../../Actions';

import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

function Transition(props) {
	return <Slide direction='down' {...props} />;
}

const styles = {
	...regularFormsStyle
};

const initialState = {
	selectedIdUnitaOrganizzativa: '',
	selectedUtente: '',
	initSelectUO: true,
	firma_visto: 'F',
	showPosFirma: false,
	tipo_pos: undefined
};

class FirmatarioModalForm extends Dialog {
	constructor(props, context) {
		super(props, context);
		this.state = initialState;
	}

	handleClose() {
		this.setState(initialState);
		this.props.onClose();
		this.state.id_uo_group = '';
		this.state.id_utente = '';
		this.props.dispatch(userActions.resetSelectedUser());
	}

	handleSubmitPosizionamento(firmatario, coordinate) {
		console.log('firmatario :>> ', firmatario);
		this.props.onSubmit(firmatario, coordinate);
		this.setState(initialState);
	}

	handleRadio = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	handleSelectedUO = (selectedValue) => {
		this.setState({
			selectedIdUnitaOrganizzativa: selectedValue
		});
	};

	handleSelectedUtente = (selectedValue) => {
		this.setState({
			selectedUtente: selectedValue
		});
	};

	handleSubmit() {
		let { selectedIdUnitaOrganizzativa, selectedUtente, firma_visto } = this.state;
		const { t, users, utils, viewPosizionamento, tipoPosizionamento, documento, documenti, isEdit } = this.props;
		const { unita_organizzative } = utils;

		const currentFlusso = documenti?.current?.flusso_operativo ?? undefined;

		let id_uo_finale = '';
		let nome_uo_finale = '';

		if (!selectedIdUnitaOrganizzativa.id_unita_organizzativa && !selectedUtente.id_utente) {
			this.props.dispatch(alertActions.error('noUO'));
			return;
		}

		// check that uo or id_utente are not already in the flow
		if (currentFlusso) {
			for (let i = 0; i < currentFlusso.length; i++) {
				if (currentFlusso[i].id_utente === selectedUtente.id_utente && !currentFlusso[i].delegato) {
					this.props.dispatch(alertActions.error('noSignatory'));
					return;
				}
			}
		}

		if (!selectedUtente || !selectedUtente.id_utente) selectedUtente = { id_utente: null };

		if (currentFlusso) {
			for (let i = 0; i < currentFlusso.length; i++) {
				if (
					currentFlusso[i].id_uo === selectedIdUnitaOrganizzativa.id_unita_organizzativa &&
					currentFlusso[i].id_utente === selectedUtente.id_utente
				) {
					this.props.dispatch(alertActions.error('noUO'));
					return;
				}
			}
		}

		if (typeof selectedIdUnitaOrganizzativa === 'object' && selectedIdUnitaOrganizzativa?.id_unita_organizzativa) {
			for (let i = 0; i < utils.unita_organizzative.length; i++) {
				if (unita_organizzative[i].id_uo_group === selectedIdUnitaOrganizzativa.id_unita_organizzativa) {
					id_uo_finale = this.state.selectedIdUnitaOrganizzativa.id_unita_organizzativa;
					nome_uo_finale = unita_organizzative[i].nome;
					break;
				}
			}
		} else if (typeof selectedUtente === 'object' && selectedUtente?.id_utente) {
			const { id_uo_group } = users.current.unita_organizzative[0];
			let userUO = unita_organizzative.filter((uo) => uo.id_uo_group === id_uo_group)[0];

			id_uo_finale = userUO.id_uo_group;
			nome_uo_finale = userUO.nome;
		}

		let id_utente = selectedUtente === undefined ? undefined : selectedUtente.id_utente?.id_utente;
		let returnData = {
			id_utente,
			id_uo: id_uo_finale,
			nome_uo: nome_uo_finale,
			nome_utente: selectedUtente === undefined ? undefined : selectedUtente.nome_utente,
			firma_visto: this.state.firma_visto
		};

		if (firma_visto === 'F' && documento && !isEdit) {
			if (viewPosizionamento) {
				this.setState({
					showPosFirma: true,
					tipo_pos: tipoPosizionamento,
					return_data: returnData
				});
			} else {
				let defaultCoord = this.getDefaultCoordinates(
					documento.coordinate,
					documento.id_documento,
					id_utente,
					id_uo_finale
				);
				this.props.onSubmit(returnData, defaultCoord);
				this.setState(initialState);
			}
		} else {
			this.props.onSubmit(returnData);
			this.setState(initialState);
			this.props.dispatch(userActions.resetSelectedUser());
		}
	}

	getDefaultCoordinates(coordinate, id_documento, id_utente, id_uo) {
		const { authentication } = this.props;
		let result;
		if (coordinate?.length > 0) {
			coordinate.forEach((element) => {
				if (element.default_coord) {
					result = element;
					result.id_utente = id_utente;
					result.id_uo = id_uo;
				}
			});
		} else {
			result = {
				id_uo,
				id_utente,
				id_documento,
				coordinata_x: 340,
				coordinata_y: 50,
				larghezza_rettangolo: 145,
				altezza_rettangolo: 38,
				default_coord: true,
				utente_ult_modif: authentication.user.nome + ' ' + authentication.user.cognome
			};
		}
		delete result.firmatario;
		return result;
	}

	getFormFieldValue(pFieldName) {
		if (typeof this.state[pFieldName] !== 'undefined') {
			return this.state[pFieldName];
		} else if (typeof this.props[pFieldName] !== 'undefined') {
			return this.props[pFieldName];
		}

		return undefined;
	}

	render() {
		const { t, classes } = this.props;
		const { showPosFirma, initSelectUO, selectedIdUnitaOrganizzativa, selectedUtente } = this.state;
		const { firma_visto, return_data } = this.state;

		return (
			<Dialog
				open={this.props.open}
				PaperProps={{
					style: {
						maxWidth: '50%',
						width: '50%',
						height: '60%'
					}
				}}
				scroll='body'
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby='notice-modal-slide-title'
				aria-describedby='notice-modal-slide-description'
			>
				<DialogContent id='notice-modal-slide-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<SelectUnitaOrganizzative
								init={initSelectUO && selectedIdUnitaOrganizzativa === '' && selectedUtente === ''}
								ref={(instance) => {
									this.selectUO = instance;
								}}
								id_utente={selectedUtente.id_utente}
								id_uo_group={this.getFormFieldValue('selectedIdUnitaOrganizzativa')}
								onChange={(selectedvalue) => this.handleSelectedUO(selectedvalue)}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<SelectUtenti
								init={selectedUtente === ''}
								id_utente={this.getFormFieldValue('selectedUtente')}
								id_unita_organizzativa={selectedIdUnitaOrganizzativa}
								onChange={(selectedvalue) => this.handleSelectedUtente(selectedvalue)}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<FormControlLabel
								control={
									<Radio
										checked={firma_visto === 'F'}
										onChange={this.handleRadio}
										value='F'
										name='firma_visto'
										aria-label='A'
										icon={<FiberManualRecord className={classes.radioUnchecked} />}
										checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
										classes={{ checked: classes.radio }}
									/>
								}
								classes={{ label: classes.label }}
								label={t('action.sign')}
							/>
							<FormControlLabel
								control={
									<Radio
										checked={firma_visto === 'V'}
										onChange={this.handleRadio}
										value='V'
										name='firma_visto'
										aria-label='B'
										icon={<FiberManualRecord className={classes.radioUnchecked} />}
										checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
										classes={{ checked: classes.radio }}
									/>
								}
								classes={{ label: classes.label }}
								label={t('action.view')}
							/>
						</GridItem>
					</GridContainer>
				</DialogContent>
				<DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
					<Button color='success' onClick={() => this.handleSubmit()}>
						<Add style={{ margin: '0px' }} /> {t('action.add')}
					</Button>
					<Button color='info' onClick={() => this.handleClose()}>
						<Close style={{ margin: '0px' }} /> {t('action.close')}
					</Button>
				</DialogActions>
				{showPosFirma && (
					<PosizionamentoFirmaWrapper
						returnData={return_data}
						onClose={() => this.handleClose()}
						onSubmit={(firmatario, coordinate) => this.handleSubmitPosizionamento(firmatario, coordinate)}
					/>
				)}
			</Dialog>
		);
	}
}

function mapStateToProps(state) {
	const { alert, authentication, raccoglitori, utils, users, documenti } = state;

	return {
		documenti,
		authentication,
		alert,
		raccoglitori,
		utils,
		users
	};
}

const connectedFirmatarioModalForm = compose(
	connect(mapStateToProps),
	withStyles(styles),
	withTranslation()
)(FirmatarioModalForm);
export default connectedFirmatarioModalForm;
