import React from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const selectStyles = {
  tc_sign_searchSelectLabel: {
    fontSize: '10px',
    //textTransform: "uppercase",
    color: '#AAA !important',
    top: '8px',
    padding: '0px',
  },
  control: (styles, state) => ({
    ...styles,
    padding: '0px',
    backgroundColor: 'transparent',
    borderColor: state.isFocused ? '#07a89e' : 'rgb(204, 204, 204)',
    boxShadow: state.isFocused ? `0 0 0 1px "#07a89e"` : '0px',
    borderTopWidth: '0px',
    borderLeftWidth: '0px',
    borderRightWidth: '0px',
    borderRadius: '0px',
    //top: "-8px",

    '&:hover': {
      borderColor: state.isFocused ? '#07a89e' : 'rgb(204, 204, 204)',
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled ? null : isSelected ? '#07a89e' : isFocused ? '#07a89e' : null,
      color: isDisabled ? '#ccc' : isSelected ? 'white' : isFocused ? 'white' : null,
      cursor: isDisabled ? 'not-allowed' : 'default',
      fontSize: '13px',
      fontWeight: '400',
      lineHeight: '2',
      ':active': {
        backgroundColor: isSelected ? '#07a89e' : null,
      },
    };
  },
  input: (styles) => ({
    ...styles,
    padding: '0px',
    backgroundColor: 'white',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.42857',
    width: '100%',
    div: { width: '100%' },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '0px',
  }),
  singleValue: (styles) => ({
    ...styles,
    opacity: '1',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1.42857',
  }),
  menuList: (styles) => ({
    ...styles,
    zIndex: '9999',
    position: 'relative',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '9999',
    //top: "-12px"
  }),
  placeholder: (styles) => ({
    ...styles,
    top: '13px',
    color: '#AAA !important',
    fontSize: '14px',
    fontWeight: '400',
    padding: '0px',
  }),
};

class AsyncSearch extends React.Component {
  handleSelect = (selectedOption) => {
    const { onChange, id } = this.props;
    const { value, label } = selectedOption || {};

    const event = {
      target: {
        name: id,
        value,
        label,
      },
    };

    onChange(event);
  };

  jsonEqual = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
  };

  selectOption = (value) => {
    const selected = value ? { value: value, label: value.utente } : {};
    return selected;
  };

  render() {
    const { label, success, error } = this.props;

    const SelectControlComponent = (props) => (
      <div>
        {<label style={selectStyles.tc_sign_searchSelectLabel}>{label}</label>}
        <components.Control {...props} />
      </div>
    );

    const Option = (props) => {
      let wProp = props;
      if (props.children != undefined && props.children.length >= 50) {
        let split = props.children.split('->');
        let label = '';
        if (split.length === 1) {
          label = split[0];
        } else if (split.length >= 2) {
          label = '...' + split[split.length - 2] + '->' + split[split.length - 1];
        }

        wProp = {
          ...props,
          children: label,
        };
      }

      return <components.Option {...wProp} />;
    };

    let currentStyle = {
      ...selectStyles,
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    };
    if (error) {
      currentStyle = {
        ...selectStyles,
        placeholder: (styles) => ({
          ...selectStyles.placeholder,
          color: '#F00 !important',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      };
    } else if (success) {
      currentStyle = {
        ...selectStyles,
        placeholder: (styles) => ({
          ...selectStyles.placeholder,
          color: '#4daf51 !important',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      };
    }

    return (
      <AsyncSelect
        value={this.selectOption(this.props.value)}
        defaultOptions={this.props.options}
        loadOptions={this.props.loadOptions}
        placeholder={this.props.placeholder}
        isClearable={this.props.isClearable}
        isSearchable={this.props.isSearchable}
        styles={currentStyle}
        onChange={this.handleSelect}
        components={{ Control: SelectControlComponent, Option }}
        isDisabled={this.props.disabled}
        isOptionDisabled={this.props.isOptionDisabled}
      />
    );
  }
}

AsyncSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
};

export default withStyles(selectStyles)(AsyncSearch);
