import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Table from "components/Table/Table.jsx";

const styles = theme => ({
    ...extendedFormsStyle,
    tcSignTableButton: {
        margin: "0px",
        padding: "0px",
        height: "auto!important"
    },
    tcSignTableRow: {
        height: "28px!important",

    },
    tcSignTableHeadRow: {
        height: "28px"
    },
    tcSignTableCell: {
        height: "28px!important",
        opacity: "1",
        fontSize: "14px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "400",
        lineHeight: "1.42857"
    },
    tcSignTableHead: {
        color: "#AAA !important",
        padding: "14px 0px 0px 0px!important",
        fontSize: "11px!important",
        lineHeight: "1rem!important",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "400"
    },
    tcSignInputStyle: {
        fontSize: "8px",
    },
    selectLabel: {
        fontSize: "14px",
        //textTransform: "uppercase",
        color: "#AAA !important",
        top: "8px",
    },
    tcSignSelectControl: {
        margin: "7px 1px 0px 0px !important",
    },
    tcsign_card: {
        "margin-top": "0px",
        "margin-bottom": "0px"
    },
    tcsign_cardbody: {
        "padding-top": "0",
        "padding-bottom": "0"
    },
    tcsign_switchIconChecked: {
        "border-color": "#AAA"
    },
    tcsign_dialog: {
        "max-width": "700px"
    }
});

class ProfiloUnitaOrganizzativa extends React.Component {
    
    render() {
        const { t, classes, user } = this.props;

        let elencoUnitaOrganizzative = [];
        if (typeof (user.unita_organizzative) !== "undefined") {
            elencoUnitaOrganizzative = user.unita_organizzative.map((prop, key) => {
                return [prop.nome]
            });
        }

        return (
            <Table
                hover
                tableData={elencoUnitaOrganizzative}
                customCellClasses={[
                    classes.tcSignTableCell,
                ]}
                customClassesForCells={[0]}
            />
        );
    }
}


function mapStateToProps(state) {
    const { utils, authentication } = state;
    const { user } = authentication ?? {};

    return {
        utils,
        user
    };
}

const connectedProfiloUnitaOrganizzativa = connect(mapStateToProps)(withStyles(styles)(ProfiloUnitaOrganizzativa));
export default connectedProfiloUnitaOrganizzativa;
//export default withStyles(styles)(SelectUnitaOrganizzativa);