import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Loading from '../ReusableComponents/Loading';
import AnalysisInputs from './Filters';
import Chart from './Chart';
import DownloadChart from './DownloadChart';

const CounterAnalysis = () => {
	const { t } = useTranslation();
	const { perUserDocs } = t('analytics', { returnObjects: true }).chartsLabels;
	const cardColor = useSelector(({ tema }) => tema.theme_color);
	const { loading, options, result, timeframe } = useSelector((state) => state.analytics.counter);

	const [selectedUser, setSelectedUser] = useState(null);

	return (
		<Card>
			<CardHeader style={{ width: 'fit-content' }} color={cardColor}>
				<Typography
					style={{
						color: 'white',
						fontWeight: 600,
						textTransform: 'uppercase',
						fontSize: '0.65rem'
					}}
				>
					{perUserDocs}
				</Typography>
			</CardHeader>
			<CardBody
				style={{
					height: '400px',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'center'
				}}
			>
				<AnalysisInputs setSelectedUser={setSelectedUser} selectedUser={selectedUser} />
				{!options.user ? (
					<FallbackMessage />
				) : loading && !result ? (
					<Loading />
				) : result ? (
					<div style={{ width: '100%', height: '100%' }}>
						<Chart selectedUser={selectedUser} />
						<div style={{ opacity: 0, position: 'absolute' }}>
							<DownloadChart selectedUser={selectedUser} />
						</div>
					</div>
				) : null}
			</CardBody>
		</Card>
	);
};

const FallbackMessage = () => {
	const { t } = useTranslation();
	const { selectUser } = t('analytics', { returnObjects: true }).helpers;

	return (
		<div
			style={{
				width: '100%',
				height: '90%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<Typography>{selectUser}</Typography>
		</div>
	);
};

export default CounterAnalysis;
