import { v4 as uuidv4 } from 'uuid';
import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MenuItem, withStyles, TextField, Typography } from '@material-ui/core';
import AsyncSearchOutlined from './AsyncSearchOutlined';

import { analyticsActions } from '../../../../Actions';
import { analyticsService } from '../../../../Services';

import { COUNTER, MenuProps, STYLES } from '../Constants';
const { scopeOptions } = COUNTER;

const styles = () => ({
  ...STYLES,
  flexHorizontal: {
    ...STYLES.flexHorizontal,
    justifyContent: 'space-between',
  },
  flexColEnd: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '18px',
  },
});

const AnalysisFilters = ({ classes, setSelectedUser, selectedUser }) => {
  const { t } = useTranslation();
  const { documentsState } = t('analytics', { returnObjects: true }).filtersLabels;
  const states = t('analytics.states', { returnObjects: true });

  const dispatch = useDispatch();
  const { id_utente, nome, cognome } = useSelector((state) => state.authentication.user);
  const commonFilters = useSelector((state) => state.analytics.overall.options);
  const options = useSelector((state) => state.analytics.counter.options);
  const filteredUsers = useSelector((state) => state.analytics.filteredUsers);
  const { scope, user } = options;

  useEffect(() => {
    setSelectedUser({ value: id_utente, label: `${nome} ${cognome}` });
    dispatch(analyticsActions.setCounterOptions({ user: id_utente }));
    dispatch(
      analyticsActions.getCounterAnalysis({ ...commonFilters, ...options, user: id_utente })
    );
    dispatch(analyticsActions.getFilteredUsers());
  }, []);

  const onChange = ({ target: { value, name } }) => {
    dispatch(analyticsActions.setCounterOptions({ [name]: value }));
    if (name === 'user' || user)
      dispatch(
        analyticsActions.getCounterAnalysis({ ...commonFilters, ...options, [name]: value })
      );
  };

  const handleSelect = (value) => {
    setSelectedUser(value);
    onChange({ target: { value: value?.value, name: 'user' } });
  };

  const analysisInputs = [
    {
      value: scope,
      name: 'scope',
      label: documentsState,
      onChange: onChange,
      options: scopeOptions(states),
    },
  ];

  const loadOptions = async (inputValue) => {
    try {
      const result = await analyticsService.getFilteredUsers({ inputValue });
      return result;
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={classes.flexHorizontal}>
      {analysisInputs.map(({ value, name, label, options, onChange }) => (
        <TextField
          key={uuidv4()}
          select
          name={name}
          margin="dense"
          value={value}
          label={label}
          style={{ width: '200px', margin: 0 }}
          InputLabelProps={{ FormLabelClasses: { focused: classes.selectLabel } }}
          SelectProps={{
            style: { fontSize: '14px' },
            MenuProps,
            classes: { outlined: classes.outlined },
          }}
          variant="outlined"
          onChange={onChange}
        >
          {options.map(({ label, value }) => (
            <MenuItem key={uuidv4()} value={value} dense classes={{ root: classes.selectMenuItem }}>
              <Typography
                noWrap
                title={label}
                style={{ fontWeight: 'lighter', fontSize: '0.8rem', lineHeight: 'normal' }}
              >
                {label}
              </Typography>
            </MenuItem>
          ))}
        </TextField>
      ))}
      <div>
        <AsyncSearchOutlined
          id={'id_utente'}
          label={t('document.labelUser')}
          placeholder={t('document.labelSelectUser')}
          loadOptions={loadOptions}
          defaultOptions={filteredUsers}
          isClearable={true}
          isSearchable={true}
          value={selectedUser}
          onChange={handleSelect}
        />
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(AnalysisFilters));
