//import config from 'config';
import { fetch as fetchPolyfill } from 'whatwg-fetch'
var url = require('url');


var wMount;
if (process.env.NODE_ENV === 'production') {
    wMount = process.env.REACT_APP_SERVICES_URL;
} else {
    wMount = process.env.REACT_APP_AUTH_URL;
}

export const tokenHelper = {
    getToken,
    getAuthorize,
    handleResponse
};

//OAuth2 get a valid token for the user.
function getToken(username, password, company) {

    //plsmnl02
    //var username = 'rlnfnc02';
    //var password = 'Teleconsys!';
    var auth = 'Basic ' + new Buffer(username + ':' + password).toString('base64');
    const requestOptions = {
        method: 'GET',
        //mode: 'cors',
        headers: {
            //'Content-Type': 'application/x-www-form-urlencoded',
            //"Access-Control-Allow-Origin": "*",
            "Authorization": auth
        },
    };

    let wUrl = wMount + "/api/v1/oauth2/UserAuthorize?client_id=TCSIGNWEB@" + company.id_company + "&response_type=code&redirect_uri=" + wMount + "/api/v1/oauth2/redirect/auth";

    return fetch(wUrl, requestOptions)
        .then(handleResponseToken)
        .then(getBearer)
        .then(token => {
            if (token.access_token) {
                localStorage.setItem('token', JSON.stringify(token));
            }

            return token;
        })
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
};

function getAuthorize(code) {

    var clientID = 'TCSIGNWEB';
    var clientSecret = '$2a$05$2kwblowsDmvah9cG6OKhUONN4IphZoSRuJWyGn419OTfzbh0aQrmy';
    var clientAuth = 'Basic ' + new Buffer(clientID + ':' + clientSecret).toString('base64');
    var clientCode = code;
    var clientheader = {
        'Host': 'www.example.com',
        'Authorization': clientAuth,
        'Content-Type': 'application/x-www-form-urlencoded'
    };


    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("code", clientCode);
    urlSearchParams.append("grant_type", "authorization_code");
    urlSearchParams.append("redirect_uri", wMount + "/api/v1/oauth2/redirect/auth");


    let wMethod = "POST";
    let wUrl = wMount + "/api/v1/oauth2/token";
    const requestOptions = {
        method: wMethod,
        headers: clientheader,
        body: urlSearchParams
    };

    return fetchPolyfill(wUrl, requestOptions)
        .then(handleResponse).then(token => {
            if (token.access_token) {
                console.log(token);
                localStorage.setItem('token', JSON.stringify(token));
            }

            return token;
        }).catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function getBearer(response) {
    //var url = wMount + "/api/v1/oauth2/token"
    var queryData = url.parse(response.url, true).query;
    let id_company = queryData.id_company;
    var clientID = 'TCSIGNWEB@' + id_company;
    var clientSecret = '$2a$05$2kwblowsDmvah9cG6OKhUONN4IphZoSRuJWyGn419OTfzbh0aQrmy';
    var clientAuth = 'Basic ' + new Buffer(clientID + ':' + clientSecret).toString('base64');
    //DA COMMENTARE IN PROD E COLLAUDO
    //var queryData = url.parse(response.headers.get("x-final-url"), true).query;
    //DA DECOMMENTARE IN PROD E COLLAUDO

    var clientCode = queryData.code;
    var clientheader = { 'Host': 'www.example.com', 'Authorization': clientAuth, 'Content-Type': 'application/x-www-form-urlencoded' };

    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append("code", clientCode);
    urlSearchParams.append("grant_type", "authorization_code");
    urlSearchParams.append("redirect_uri", wMount + "/api/v1/oauth2/redirect/auth");

    let wMethod = "POST";
    let wUrl = wMount + "/api/v1/oauth2/token";
    const requestOptions = {
        method: wMethod,
        headers: clientheader,
        body: urlSearchParams
    };

    return fetchPolyfill(wUrl, requestOptions).then(handleResponse);
}

function handleResponseToken(response) {
    if (response.status === 401) {
        const error = "Non si dispone delle autorizzazioni necessarie per accedere al sistema";
        return Promise.reject(error);
    }

    return response.text().then(text => {
        let data = "";
        try {
            data = text && JSON.parse(text);
        }
        catch (err) {
            return response;
        };

        if (!response.ok) {
            if (response.status === 401) {
                const error = "Non si dispone delle autorizzazioni necessarie per accedere al sistema";
                return Promise.reject(error);
            }

            const error = (data && data.error && data.error_description) || response.statusText;
            return Promise.reject(error);
        } else {
            if (data.Result.Code !== "E100") {
                const error = (data && data.Result && data.Result.Message);
                return Promise.reject(error);
            }
        }

        return response;
    });
}

function handleResponse(response) {

    if (response.status === 401) {
        const error = "Non si dispone delle autorizzazioni necessarie per accedere al sistema";
        return Promise.reject(error);
    }

    return response.text().then(text => {


        let data = "";
        try {
            data = text && JSON.parse(text);
        }
        catch (err) {
            data = "Errore di sistema.";
        };

        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
                const error = "Non si dispone delle autorizzazioni necessarie per accedere al sistema";
                return Promise.reject(error);
            }

            const error = (data && data.error && data.error_description) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}



