import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import Extension from '@material-ui/icons/Extension';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.jsx';
import Tooltip from '@material-ui/core/Tooltip';
import { store } from './store.js';

export const Constants = {
    tipo_notifica: {
        1: 'Priorit� documento',
        2: 'Riepilogo',
    },
    frequenza_notifica: {
        '1G': 'Una volta al giorno',
        '1S': 'Una volta alla settimana',
    },
    priorita_notifica: {
        A: 'A',
        B: 'B',
    },
    ruoli: [
        { id_ruolo: 1, desc_ruolo: 'ADMIN', cod_ruolo: 'ADMIN' },
        { id_ruolo: 2, desc_ruolo: 'Editor Documenti', cod_ruolo: 'EDITOR_DOC' },
        { id_ruolo: 3, desc_ruolo: 'Editor Raccoglitori', cod_ruolo: 'EDITOR_RAC' },
        { id_ruolo: 4, desc_ruolo: 'VIEWER', cod_ruolo: 'VIEWER' },
        { id_ruolo: 5, desc_ruolo: 'OPERATORE', cod_ruolo: 'OPERATORE' },
        { id_ruolo: 6, desc_ruolo: 'IMPEGNO_OPERATORE', cod_ruolo: 'IMPEGNO_OPERATORE' },
        { id_ruolo: 7, desc_ruolo: 'ADMIN UTENTI', cod_ruolo: 'USERS_ADMIN' },
    ],
    documento: {
        priorita: {
            A: 'Urgente',
            B: 'Standard',
        },
        confidenzialita: {
            "A": "Riservato",
            "B": "Divulgabile",
        },
        stato: {
            inBozza: 1,
            inLavorazione: 2,
            rifiutato: 3,
            completato: 4,
            protocollato: 5,
            sospeso: 6,
            inviatoProtocollo: 7,
            annullato: 10,
        },
    },
    raccoglitore: {
        tipo_percorso: {
            int: 'Interno',
            ext: 'Esterno',
            mix: 'Misto'
        },
    },
    percorso: {
        tipo_firma: { enable_feq: true, enable_fea: false, enable_upload: false },
        label_firma: {
            Firma: 'F',
            Visto: 'V',
        },
    },
    azioni: {
        byStatoFlusso: {
            1: 2,
            2: 3,
        },
    },
};

export const ModelViewHelper = {
    getAzioneByStato,
    raccoglitori_ModelToView,
    percorso_ViewToModel,
    document_ModelToView,
    function_ModelToView,
    note_ModelToView,
    user_ModelToView,
    meeting_ModelToView,
    topic_ModelToView,
    step_percorso_ModelToView,
};

function getAzioneByStato(idStatoFlusso) {
    //In attesa di firma
    if (idStatoFlusso === 1) {
        return 2; //Azione firma
    } else if (idStatoFlusso === 2) {
        return 3;
    }
}

//CAMUSI 25072020 inizio
function step_percorso_ModelToView(model) {
    return {
        //flag_firma_vista: model.flag_firma_vista,
        ordinamento: model.ordinamento,
        flag_firma_vista: model.flag_firma_vista === 'F' ? 'FIRMA' : 'VISTO',
        nome_owner: model?.contatto_owner
            ? model.contatto_owner
            : model?.utente_owner
            ? model.utente_owner
            : model.uo_owner,
        tipo_utente: model.id_contatto_owner ? 'Esterno' : 'Interno',
    };
}
//CAMUSI 25072020 fine

function raccoglitori_ModelToView(model) {
    return {
        id: model.id_raccoglitore,
        nome: model.nome_raccoglitore,
        descrizione: model.descrizione,
        data_creazione: (
            <Moment parse="YYYY-MM-DDTHH:mm:ss.sss+01:00" format="DD/MM/YYYY HH:mm" locale="it">
                {model.data_creazione}
            </Moment>
        ),
        data_ult_modif: (
            <Moment parse="YYYY-MM-DDTHH:mm:ss.sss+01:00" format="DD/MM/YYYY HH:mm" locale="it">
                {model.data_ult_modif}
            </Moment>
        ),
        flag_validita: model.flag_validita !== 'N' ? true : false,
        utente_orig: model.utente_orig,
        uo_orig: model.uo_orig,
        id_titolario_classificazione: model.id_titolario_classificazione === '' ? null : model.id_titolario_classificazione,
        desc_titolario_classificazione: model.desc_titolario_classificazione,
        id_tipo_percorso: model.tipo_percorso,
        tipo_percorso: Constants.raccoglitore.tipo_percorso[model.tipo_percorso],
        percorsi: typeof model.percorsi === 'undefined' ? [] : model.percorsi,
    };
}

function percorso_ViewToModel(view) {
    return {
        id_utente_owner: view[5],
        id_uo_owner: view[4],
        flag_firma_vista: Constants.percorso.label_firma[view[1]],

        ordinamento: view[0],
    };
}

function user_ModelToView(model) {
    let wReturn = model;

    wReturn.notifica_priorita_standard = false;
    wReturn.notifica_priorita_urgente = false;
    wReturn.notifica_riepilogo_settimana = false;
    wReturn.notifica_riepilogo_giorno = false;

    let wNotifiche = wReturn.notifiche;
    if (typeof wNotifiche !== 'undefined' && wNotifiche !== null && wNotifiche !== []) {
        for (var i = 0; i < wNotifiche.length; i++) {
            if (wNotifiche[i].id_tipologia_notifica === 1 && wNotifiche[i].priorita === 'A') {
                wReturn.notifica_priorita_urgente = true;
            } else if (wNotifiche[i].id_tipologia_notifica === 1 && wNotifiche[i].priorita === 'B') {
                wReturn.notifica_priorita_standard = true;
            } else if (wNotifiche[i].id_tipologia_notifica === 2 && wNotifiche[i].frequenza === '1G') {
                wReturn.notifica_riepilogo_giorno = true;
            } else if (wNotifiche[i].id_tipologia_notifica === 2 && wNotifiche[i].frequenza === '1S') {
                wReturn.notifica_riepilogo_settimana = true;
            }
        }
    }

    return wReturn;
}

function document_ModelToView(model, t) {
    return {
        tag_posizionato_int: model.tag_posizionato_int,
        tag_posizionato_est: model.tag_posizionato_est,
        uuid: model.uuid,
        id: model.id_documento,
        file_name: model.file_name,
        attenzionato: model.attenzionato === null ? false : true,
        livello_attenzione: model.livello,
        verificato: model.verificato === null ? false : true,
        utente_verifica: model.utente_verifica === null ? '' : model.utente_verifica,
        data_verifica: model.data_verifica === null ? '' : moment(model.data_verifica).format('DD/MM/YYYY HH:mm:ss'),
        oggetto_protocollo: model.oggetto_protocollo === null ? '' : model.oggetto_protocollo,
        num_protocollo: model.num_protocollo === null ? '' : model.num_protocollo,
        num_repertorio: model.num_repertorio === null ? '' : model.num_repertorio,
        data_protocollo:
            model.data_protocollo === null ? '' : moment(model.data_protocollo).format('DD/MM/YYYY HH:mm:ss'),
        //data_protocollo: (model.data_protocollo === null) ? "" : <Moment parse="YYYY-MM-DDTHH:mm:ss.sss+01:00" format="DD/MM/YYYY HH:mm:ss">
        //    {model.data_protocollo}
        //</Moment>,
        uo_ult_firmatario: model.uo_ult_firmatario === null ? '' : model.uo_ult_firmatario,
        uo_created: model.uo_created === null ? '' : model.uo_created,
        id_uo_created: model.id_uo_created === null ? '' : model.id_uo_created,
        id_uo_richiedente: model.id_uo_richiedente === null ? '' : model.id_uo_richiedente,
        id_uo_rif_interno_protocollo:
            model.id_uo_rif_interno_protocollo === null ? '' : model.id_uo_rif_interno_protocollo,
        id_utente_rif_interno_protocollo:
            model.id_utente_rif_interno_protocollo === null ? '' : model.id_utente_rif_interno_protocollo,
        uo_rif_interno_protocollo: model.uo_rif_interno_protocollo === null ? '' : model.uo_rif_interno_protocollo,
        utente_rif_interno_protocollo:
            model.utente_rif_interno_protocollo === null ? '' : model.utente_rif_interno_protocollo,
        letto: model.letto === null ? false : true,
        des_letto: (model.letto === null) ? t('yourDashboard.toRead') : t('table.headers.read'),
        nome: model.nome,
        descrizione: model.descrizione,
        id_stato_documento: model.id_stato_documento,
        desc_stato_documento: t(`document.status.${model.desc_stato_documento}`),
        id_stato_flusso: model.id_stato_flusso_op,
        desc_stato_flusso: t(`flowStatus.${model.desc_stato_flusso_op}`),
        utente_ult_firmatario: model.utente_ult_firmatario === null ? '' : model.utente_ult_firmatario,
        id_utente_ult_firmatario: model.id_utente_ult_firmatario,
        id_tipo_documento: model.id_tipo_documento,
        desc_tipo_documento: t(`document.documentType.${model.desc_tipo_documento}`),
        id_raccoglitore: model.id_raccoglitore,
        nome_raccoglitore: model.nome_raccoglitore,
        id_priorita: model.priorita,
        id_confidenzialita: model.confidenzialita,
        priorita: t(`document.priorityType.${Constants.documento.priorita[model.priorita]}`),
        noteVerif: model.note_verifica,
        note: model.note,
        impegno_spesa: model.impegno_spesa === null ? '' : model.impegno_spesa,
        flusso_operativo:
            typeof model.flusso_operativo === 'undefined'
                ? []
                : model.flusso_operativo.map((prop, key) => {
                      return [
                          prop.ordinamento,
                          typeof prop.utente === 'undefined' ? prop.uo : prop.utente,
                          prop.desc_stato_flusso_op,
                          prop.data_operazione === null || typeof prop.data_operazione === 'undefined' ? (
                              ''
                          ) : (
                              <Moment parse="YYYY-MM-DDTHH:mm:ss.sss+01:00" format="DD/MM/YYYY HH:mm:ss">
                                  {prop.data_operazione}
                              </Moment>
                          ),
                      ];
                  }),
        flag_protocollo: model.flag_protocollo,
        qr_code_enable: model.qr_code_enable,
        repertorio: model.repertorio,
        flag_protocollo_uffici: model.flag_protocollo_uffici,
        data_creazione_str:
            model.data_creazione === null ? '' : moment(model.data_creazione).format('DD/MM/YYYY HH:mm:ss'),
        data_creazione: (
            <Moment parse="YYYY-MM-DDTHH:mm:ss.sssZ+01:00" format="DD/MM/YYYY HH:mm:ss">
                {model.data_creazione}
            </Moment>
        ),
        data_ult_modif_str:
            model.data_ult_modif === null ? '' : moment(model.data_ult_modif).format('DD/MM/YYYY HH:mm:ss'),
        data_ult_modif: (
            <Moment parse="YYYY-MM-DDTHH:mm:ss.sssZ+01:00" format="DD/MM/YYYY HH:mm:ss">
                {model.data_ult_modif}
            </Moment>
        ),

        id_utente_orig: model.id_utente_created,
        utente_originatore: model.utente_created,
        uo_orig: model.uo_orig,
        id_utente_delegante: model.id_utente_delegante === null ? '' : model.id_utente_delegante,
        id_utente_delegato: model.id_utente_delegato === null ? '' : model.id_utente_delegato,
        stato_verifica: model.stato,
        delegato: model.delegato,
        id_riunione: model.id_riunione,
        id_argomento: model.id_argomento,
        proposta_inviata: model.status,
        link_fea: model.link_fea, 
        tipo_firma: model.tipo_firma,
        sign_info: model.sign_info
    };
}

function function_ModelToView(model) {
    return {
        id_func: model.id_funzione,
        nome_funzione: model.nome_funzione,
        cod_funzione: model.cod_funzione,
        menu: model.menu,
        cod_menu: model.cod_menu,
        tipo: model.tipo_funzione,
    };
}

function note_ModelToView(model, id_documento, delete_callback) {
    return {
        inverted: true,
        badgeColor: 'success',
        badgeIcon: Extension,
        title: (
            <div>
                {model.utente}
                <Tooltip id="tooltip-top" title="Elimina commento" placement="bottom">
                    <Button
                        style={{
                            width: '20px',
                            height: '20px',
                            minWidth: '20px',
                            margin: '0px',
                            padding: '0px',
                        }}
                        justIcon
                        round
                        simple
                        onClick={delete_callback(id_documento, model.utente)}
                        color="danger"
                    >
                        <Close />
                    </Button>
                </Tooltip>
            </div>
        ),
        titleColor: 'success',
        body: <p>{model.descrizione}</p>,
        footerTitle: (
            <div>
                <Button
                    justIcon
                    round
                    simple
                    //onClick={() => this.handleClickDeleteDocumento(wReturn.id)}
                    color="danger"
                >
                    <Close />
                </Button>
                Elimina
                <Moment parse="YYYY-MM-DDTHH:mm:ss.ms+01:00" format="DD/MM/YYYY HH:mm:ss">
                    {model.data_creazione}
                </Moment>
            </div>
        ),
    };
}

function meeting_ModelToView(model) {
    let elem = {
        id_riunione: model.id,
        titolo_riunione: model.title,
        data_riunione: moment(model.date, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm'),
    };
    return elem;
}

function topic_ModelToView(model) {
    let elem = {
        id_argomento: model.id,
        titolo_argomento: model.title,
    };
    return elem;
}

export const Authorize = {
    checkAuthorize,
    checkAuthorizeFunc,
};

//Administrator 1
//Editor documento 2
//Editor raccoglitore 3
//Viewer 4
//Operatore 5
//Editor metadata 6

export const ServiceMatrix = {
    CreateDocument: [1, 2],
    SaveDocument: [1, 2],
    ViewDocument: [1, 2, 4, 5, 6, 0],
    ViewFileDocument: [1, 2, 4, 5, 6, 0],
    DeleteDocument: [1, 2],
    DonwloadDocument: [1, 2, 4, 5, 6, 0],
    SignCheckDocument: [1, 5],
    RifiutaDocument: [1, 5, 6],
    ModificaPercorsoFirma: [1, 2],
    SostituisciDocument: [1, 2],
    AttenzionatiDocument: [1, 5],
    ButtonAvvia: [1, 2],
    AnnullaProcesso: [1, 2],
    AddNote: [1, 2, 4, 5],
    ModifyMetadata: [6],
    SendSollecito: [1, 2, 4, 5, 6],

    CreateFolder: [1, 3],
    SaveFolder: [1, 3],
    ViewFolder: [1, 2, 3, 4, 5],
    RemoveFolder: [1, 3],

    GestioneUtenti: [1, 7],
    GestioneUO: [1, 7],
    LinkScrivania: [1, 5, 6, 0],
    LinkAllDocuments: [1, 2, 4],
    LinkMyDocuments: [1, 2, 4],
    LinkCancellati: [1, 2],
    LinkAttenzionati: [1, 5, 6, 0],
    LinkFirmatiVistati: [1, 5, 6, 0],
    LinkInArrivo: [1, 5, 6, 0],
    LinkRifiutati: [1, 5, 6, 0],
    PercorsiFirma: [1, 2, 3, 4, 5, 6],

    CreateUser: [1, 7],
    SaveUser: [1, 7],
    RemoveUser: [1, 7],

    CreateUO: [1, 7],
    SaveUO: [1, 7],
    RemoveUO: [1, 7],

    //CAMUSI 06072020
    CreateProfilo: [1, 7],
    SaveProfilo: [1, 7],
    RemoveProfilo: [1, 7],
};

const delegatoRoles = ['LNKSCR', 'LNKTSC', 'LNKATZ', 'LNKDVF', 'LNKDRF', 'LNKDAR', 'FNCVWD', 'FNCDWL', 'FNCDDC'];

function checkAuthorize(pService) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (store.getState().documenti.deleganteSelected.id_delegante) {
        if (!pService.includes(0)) return false;
        else return true;
    } else {
        if (typeof user !== 'undefined' && user != null && typeof user.ruoli !== 'undefined') {
            for (var i = 0; i < user.ruoli.length; i++) {
                for (var j = 0; j < pService.length; j++) {
                    if (user.ruoli[i].id_ruolo === pService[j]) {
                        return true;
                    }
                }
            }
        }

        return false;
    }
}

function checkAuthorizeFunc(cod_funzione) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (store.getState().documenti.deleganteSelected.id_delegante) {
        if (!delegatoRoles.includes(cod_funzione)) return false;
        else return true;
    } else {
        if (typeof user !== 'undefined' && user != null && typeof user.funzioni !== 'undefined') {
            for (var i = 0; i < user.funzioni.length; i++) {
                if (user.funzioni[i].cod_funzione === cod_funzione) {
                    return true;
                }
            }
        }
        return false;
    }
}
