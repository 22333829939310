import React from "react";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import User from "@material-ui/icons/Person";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";
import CheckboxTree from "react-checkbox-tree";
import Tooltip from "@material-ui/core/Tooltip";
import Add from "@material-ui/icons/AddCircle";
import Remove from "@material-ui/icons/RemoveCircle";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import FormValidationHelper from "../../Helpers/form-validation.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import { userActions, alertActions, utilsActions } from "../../Actions";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

import { Authorize } from "Helpers/model-view.js";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = {
    ...extendedFormsStyle,
    selectLabel: {
        fontSize: "16px",
        //textTransform: "uppercase",
        color: "rgba(0, 0, 0, 0.87)",
        top: "8px",
    },
    tcSignSelectControl: {
        margin: "7px 1px 0px 0px !important",
    },
    tcSign_tree_item: {
        color: "black",
    },
};

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
`;

const initialState = {
    checked: undefined,
    expanded: undefined,
    desc_ruolo: undefined, //descrizione
    desc_ruolo_State: undefined,
    cod_ruolo: undefined,
    cod_ruolo_State: undefined,
    id: undefined,
    selectedFunctions: [],
    ids_fn_abilitate: undefined,
    ids_fn_abilitate_expanded: undefined,
};

class ProfiloModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);

        this.state = initialState;

        this.props.dispatch(utilsActions.getAllFunctions());
        if (typeof props.id !== "undefined") {
            this.getData(this.props.id);
        }

        if (
            (typeof this.props.users.loading === "undefined" || !this.props.users.loading) &&
            typeof this.props.users.current === "undefined"
        ) {
            //this.props.dispatch(userActions.getAllProfili());
        }
    }

    getData(idProfilo) {
        this.props.dispatch(userActions.getProfiloById(idProfilo));
    }

    handleSelect = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }

    handleClose() {
        this.setState(initialState);
        this.props.dispatch(userActions.resetProfilo());
        this.props.dispatch(userActions.getAllProfili());
        this.props.onClose();
    }

    handleComprimiVisibilita() {
        this.setState({ ids_fn_abilitate_expanded: [] });
    }

    handleEspandiVisibilita() {
        const { utils } = this.props;

        let espandi = [];
        if (typeof utils.funzioni !== "undefined") {
            espandi = utils.funzioni.map((prop, key) => {
                return prop.id_funzione;
            });
        }
        this.setState({ ids_fn_abilitate_expanded: espandi });
    }

    handleSubmit() {
        const { users, user } = this.props;

        let desc_ruolo_State = FormValidationHelper.validateField("required", this.getFormFieldValue("desc_ruolo"), 1); //descrizione
        let cod_ruolo_State = FormValidationHelper.validateField("required", this.getFormFieldValue("cod_ruolo"), 1);

        if (desc_ruolo_State === "error" || cod_ruolo_State === "error") {
            this.props.dispatch(alertActions.error("Errori di compilazione nella scheda GENERALE"));
            this.setState({
                desc_ruolo_State: desc_ruolo_State,
                cod_ruolo_State: cod_ruolo_State,
            });
            return;
        }
        this.setState({
            desc_ruolo_State: desc_ruolo_State,
            cod_ruolo_State: cod_ruolo_State,
        });

        let funzioni_abilitate = this.getFormFieldValue("ids_fn_abilitate");
        this.riciclo(funzioni_abilitate);

        let idRuoloCorrente = users.current ? users.current.id_ruolo : undefined;
        let wProfilo = {
            //id_ruolo: 42,
            id_ruolo: this.props.id ? this.props.id : idRuoloCorrente,
            desc_ruolo: this.getFormFieldValue("desc_ruolo"), //descrizione
            cod_ruolo: this.getFormFieldValue("cod_ruolo"),
            funzioni_abilitate: funzioni_abilitate,
            utente_ult_modif: user.nome + " " + user.cognome,
        };
        this.props.dispatch(userActions.saveProfilo(wProfilo));
    }

    ciclo(ls) {
        const { utils } = this.props;
        let result = [];
        ls.forEach((el) => {
            utils.funzioni.forEach((elc) => {
                if (elc.id_funzione === parseInt(el)) result.push(elc);
            });
        });
        return result;
    }

    riciclo(ls) {
        let lr = this.ciclo(ls);
        let result = [];
        lr.forEach((elc) => {
            let found = false;
            ls.forEach((el) => {
                if (elc.id_parent && elc.id_parent === parseInt(el)) {
                    found = true;
                }
            });
            if (elc.id_parent && !found) {
                result.push(elc.id_parent);
            }
        });
        if (result.length > 0) {
            let unique = [...new Set(result)];
            unique.forEach((re) => {
                ls.push(re);
            });
            this.riciclo(ls);
        }
    }

    getFormFieldValue(pFieldName) {
        const { id, users, utils } = this.props;

        if (pFieldName === "ids_fn_abilitate") {
            if (typeof this.state.checked === "undefined") {
                if (users && users.current && users.current.funzioni) {
                    return this.getFunctionId(users.current.funzioni);
                } else {
                    return [];
                }
                //return (users && users.current && users.current.funzioni) ? users.current.funzioni : [];
            } else {
                return this.state.checked;
            }
        }
        if (typeof this.state[pFieldName] !== "undefined") {
            return this.state[pFieldName];
        } else if (typeof id !== "undefined" && typeof users.current !== "undefined") {
            if (pFieldName === "ids_fn_abilitate_expanded") {
                if (typeof utils.funzioni !== "undefined") {
                    let appo = utils.funzioni.map((prop, key) => {
                        return prop.id_funzione;
                    });
                    return appo;
                }
            }
            let wReturn = users.current;
            return wReturn[pFieldName];
        }
        if (pFieldName === "ids_fn_abilitate_expanded") {
            if (typeof utils.funzioni !== "undefined") {
                let appo = utils.funzioni.map((prop, key) => {
                    return prop.id_funzione;
                });
                return appo;
            } else {
                return [];
            }
        }

        return "";
    }

    getFunctionId(list) {
        let result = [];
        if (list && list.length > 0)
            list.forEach((el) => {
                result.push(el.id_funzione);
            });
        return result;
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        const { value } = event.target;
        let wState = {};
        wState[stateName] = value;
        wState[stateName + "State"] = FormValidationHelper.validateField(type, event.target.value, 1);
        this.setState(wState);
    }

    selectAllFunc() {
        if (this.state.selectedFunctions.length === 0) {
            const { selectedFunctions } = this.state;
            const newChecked = [...selectedFunctions];
            this.props.utils.funzioni.forEach((x) => {
                newChecked.push(x.id_funzione);
            });
            this.setState({
                selectedFunctions: newChecked,
            });
        } else {
            this.setState({
                selectedFunctions: [],
            });
        }
    }

    handleFunctionSelection(idFunction) {
        const { selectedFunctions } = this.state;
        console.log("FUNZIONI SELEZIONATE : " + selectedFunctions);
        const currentIndex = selectedFunctions.indexOf(idFunction);
        const newChecked = [...selectedFunctions];

        if (currentIndex === -1) {
            newChecked.push(idFunction);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            selectedFunctions: newChecked,
        });
    }

    flatToHierarchy(flat) {
        let roots = []; // things without parent
        // make them accessible by guid on this map
        let all = {};
        flat.forEach(function (item) {
            all[item.id_funzione] = item;
        });
        // connect childrens to its parent, and split roots apart
        Object.keys(all).forEach(function (id_funzione) {
            let item = all[id_funzione];
            if (item.parent_id === null) {
                roots.push(item);
            } else if (item.parent_id in all) {
                let p = all[item.parent_id];
                if (!("children" in p)) {
                    p.children = [];
                }
                p.children.push(item);
            }
        });
        return roots;
    }

    render() {
        const { t, classes, users, utils, tema } = this.props;
        let cardColor = tema.theme_color;
        let alertMessage = "";
        if (alert && typeof alert.message !== "undefined") {
            const message = t(`alert.${alert.message}`);
            if (alert.type === "alert-success") {
                alertMessage = (
                    <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
                        {message}
                    </SweetAlert>
                );
            } else if (alert.type === "alert-danger") {
                alertMessage = (
                    <SweetAlert
                        danger
                        title={t("alert.warning")}
                        onConfirm={() => {
                            this.handleCloseAlert();
                            if (alert.message === "sessionExpired") {
                                this.props.dispatch(userActions.logout());
                            }
                        }}
                    >
                        {message}
                    </SweetAlert>
                );
            }
        }

        let loading;
        if (users && users.loading) {
            loading = (
                <div css={overlay}>
                    <div css={override}>
                        <ClipLoader sizeUnit={"px"} size={80} color={"#3a783d"} loading={users.loading} />
                    </div>
                </div>
            );
        }

        let wButtonSalva = "";
        if (Authorize.checkAuthorizeFunc("FNCCPF")) {
            wButtonSalva = (
                <Button color={cardColor} onClick={() => this.handleSubmit()}>
                    <Save style={{ margin: "0px" }} /> {t("action.save")}
                </Button>
            );
        }

        let wTitle = this.props.title;
        if (typeof users.current !== "undefined") {
            //CAMUSI ci stava già......wTitle = "Profilo: " + this.getFormFieldValue("nome");
        }

        let dataList = [];
        let menuList = [];
        if (utils.funzioni && utils.funzioni.length > 0) {
            utils.funzioni.forEach((elem) => {
                dataList.push(elem);
                if (elem.tipo_funzione === "M") menuList.push(elem);
            });
        }

        let functionTree = [];
        if (utils.funzioni && utils.funzioni.length > 0) {
            let functions = utils.funzioni.map((item) => ({
                id_funzione: item.id_funzione,
                parent_id: item.id_parent,
                value: item.id_funzione,
                label: t(`functions.${item.nome_funzione}`),
                title: item.path,
                className: "tcSign_tree_item",
            }));
            functionTree = this.flatToHierarchy(functions);
        }

        return (
            <div>
                <Dialog
                    PaperProps={
                        {
                            //style: {
                            //    maxWidth: "700px"
                            //},
                        }
                    }
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    scroll="body"
                    fullScreen
                    onClose={() => this.handleClose()}
                    aria-labelledby="notice-modal-slide-title"
                    aria-describedby="notice-modal-slide-description"
                >
                    {loading}
                    {alertMessage}
                    <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
                        <Card style={{ marginBottom: "0px" }}>
                            <CardHeader color={cardColor} icon>
                                <CardIcon color={cardColor}>
                                    <User />
                                </CardIcon>
                                <h4 className={classes.cardTitle} style={{ color: "#777" }}>
                                    {wTitle}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <NavPills
                                    color={cardColor}
                                    tabs={[
                                        {
                                            tabButton: t("profiles.generalTab"),
                                            tabContent: (
                                                <GridContainer style={{ width: "100%", margin: "0px" }}>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <CustomInput
                                                            success={this.state.desc_ruolo_State === "success"}
                                                            error={this.state.desc_ruolo_State === "error"}
                                                            labelText={t("profiles.description")}
                                                            id="desc_ruolo" //descrizione
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            inputProps={{
                                                                onChange: (event) =>
                                                                    this.change(event, "desc_ruolo", "required"), //descrizione
                                                                type: "desc_ruolo", //descrizione
                                                                value: this.getFormFieldValue("desc_ruolo") || "", //descrizione
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12} style={{ minHeight: "50vh" }}>
                                                        <CustomInput
                                                            success={this.state.cod_ruolo_State === "success"}
                                                            error={this.state.cod_ruolo_State === "error"}
                                                            labelText={t("profiles.code")}
                                                            id="cod_ruolo"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            inputProps={{
                                                                onChange: (event) =>
                                                                    this.change(event, "cod_ruolo", "required"),
                                                                type: "cod_ruolo",
                                                                value: this.getFormFieldValue("cod_ruolo") || "",
                                                            }}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            ),
                                        },
                                        {
                                            tabButton: t("profiles.functionTab"),
                                            tabContent: (
                                                <GridContainer style={{ width: "100%" }} direction="column">
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <Tooltip
                                                            id="tooltip-top"
                                                            title={t("profiles.expandTree")}
                                                            placement="bottom"
                                                            classes={{ tooltip: classes.tooltip }}
                                                            //style={{ float: "left" }}
                                                        >
                                                            <Button
                                                                simple
                                                                style={{ padding: "0px", margin: "5px" }}
                                                                onClick={this.handleEspandiVisibilita.bind(this)}
                                                                color="info"
                                                                className={classes.tcsign_action}
                                                            >
                                                                <Add />
                                                                {t("profiles.expand")}
                                                            </Button>
                                                        </Tooltip>
                                                        <Tooltip
                                                            id="tooltip-top"
                                                            title={t("profiles.compressTree")}
                                                            placement="bottom"
                                                            classes={{ tooltip: classes.tooltip }}
                                                            //style={{ float: "right" }}
                                                        >
                                                            <Button
                                                                style={{ padding: "0px", margin: "5px" }}
                                                                simple
                                                                onClick={this.handleComprimiVisibilita.bind(this)}
                                                                color="info"
                                                                className={classes.tcsign_action}
                                                            >
                                                                <Remove />
                                                                {t("profiles.compress")}
                                                            </Button>
                                                        </Tooltip>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <CheckboxTree
                                                            icons={{
                                                                leaf: "",
                                                                parentClose: "",
                                                                parentOpen: "",
                                                            }}
                                                            showExpandAll={true}
                                                            showNodeTitle={true}
                                                            nodes={functionTree}
                                                            checked={this.getFormFieldValue("ids_fn_abilitate")}
                                                            expanded={this.getFormFieldValue(
                                                                "ids_fn_abilitate_expanded"
                                                            )}
                                                            onCheck={(checked) => this.setState({ checked })}
                                                            onExpand={(expanded) =>
                                                                this.setState({ ids_fn_abilitate_expanded: expanded })
                                                            }
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            ),
                                        },
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </DialogContent>
                    <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                        {wButtonSalva}
                        <Button color="info" onClick={() => this.handleClose()}>
                            <Close style={{ margin: "0px" }} /> {t("action.close")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, users, utils, tema } = state;
    const { user } = authentication ?? {};
    return {
        authentication,
        alert,
        users,
        utils,
        user,
        tema,
    };
}

const connectedProfiloModalForm = compose(
    connect(mapStateToProps),
    withStyles(styles),
    withTranslation()
)(ProfiloModalForm);
export default connectedProfiloModalForm;
