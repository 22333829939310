import React from 'react';

import { connect } from 'react-redux';

// @material-ui/core components
import { withStyles, Slide, Dialog, DialogContent, DialogActions, DialogTitle } from '@material-ui/core/';
import { FormControlLabel, Switch, CircularProgress } from '@material-ui/core';

import Close from '@material-ui/icons/Close';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import FormValidationHelper from '../../Helpers/form-validation.js';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react.jsx';

import { userActions } from '../../Actions';
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next';

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = {
    ...extendedFormsStyle,
    ...regularFormsStyle,
    selectLabel: {
        fontSize: '14px',
        //textTransform: "uppercase",
        color: '#AAA !important',
        top: '8px',
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        color: primaryColor,
    },
};

const initialState = {
    username: undefined,
    password: undefined,
    otp: undefined,
    principale: undefined,
    nome: undefined,
    id_digital_signature: undefined,
    usernameState: undefined,
    passwordState: undefined,
    nomeState: undefined,
};

class FirmaEditModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);

        this.state = initialState;
    }

    handleClose() {
        this.setState(initialState);
        this.props.dispatch(userActions.addEditFirma(false));
    }

    handleSubmit() {
        const { id_utente, user } = this.props;
        let otpState;
        let authByProvider = false;

        let usernameState = FormValidationHelper.validateField('required', this.getFormFieldValue('username'), 1);
        let passwordState = FormValidationHelper.validateField('required', this.getFormFieldValue('password'), 1);
        let id_digital_signature = this.getFormFieldValue('id_digital_signature');

        if (user.providerFirma === 'aruba')
            otpState = FormValidationHelper.validateField('required', this.getFormFieldValue('otp'), 1);

        this.setState({
            usernameState: usernameState,
            passwordState: passwordState,
            otpState: otpState
        });

        if (usernameState === 'error' || (passwordState === 'error' && !id_digital_signature)
            || (user.providerFirma === 'aruba' && otpState === 'error')) {
            return;
        } else {
            let returnData = {
                id_utente,
                username: this.getFormFieldValue('username'),
                pwd: this.getFormFieldValue('password'),
                principale: this.getFormFieldValue('principale'),
                id_digital_signature: id_digital_signature === '' ? undefined : id_digital_signature,
                authByProvider
            };

            if (user.providerFirma === 'aruba') {
                returnData['otp'] = this.getFormFieldValue('otp');
                returnData['authByProvider'] = true;
            }

            this.props.dispatch(userActions.editSignature(returnData));

            this.setState(initialState);
        }
    }

    getFormFieldValue(pFieldName) {
        if (typeof this.state[pFieldName] !== 'undefined') {
            return this.state[pFieldName];
        }

        if (typeof this.props.firma !== 'undefined' && typeof this.props.firma[pFieldName] !== 'undefined') {
            return this.props.firma[pFieldName];
        }

        if (pFieldName === 'principale') {
            return false;
        }

        return '';
    }

    change(event, stateName, type) {
        const { value, checked } = event.target;

        this.setState({ [stateName]: stateName === 'principale' ? checked : value });

        if (type === 'required')
            this.setState({
                [stateName + 'State']: FormValidationHelper.validateField('required', value, 1),
            });
    }

    render() {
        const { usernameState, passwordState, otpState } = this.state;
        const { t, classes, firma, loading, addEditFirma, tema, user } = this.props;
        let cardColor = tema.theme_color;
        return (
            <Dialog
                open={addEditFirma ?? false}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description">
                <DialogTitle>{firma ? t('action.modify') : t('action.add')} {t('user.digitalSignatures.dsign')}</DialogTitle>
                <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
                    <GridContainer style={{ width: '550px', height: '275px' }}>
                        {loading ? (
                            <div className={classes.loading}>
                                <CircularProgress color="inherit" thickness={3} size={60} />
                            </div>
                        ) : (
                                <>
                                    {firma && (
                                        <GridItem xs={12} sm={12} md={12}>
                                            <CustomInput
                                                labelText="Id"
                                                id="id_digital_signature"
                                                formControlProps={{ fullWidth: true, required: true }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.getFormFieldValue('id_digital_signature'),
                                                }}
                                            />
                                        </GridItem>
                                    )}
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            success={usernameState === 'success'}
                                            error={usernameState === 'error'}
                                            labelText="Username"
                                            id="username"
                                            formControlProps={{ fullWidth: true, required: true }}
                                            inputProps={{
                                                onChange: (event) => this.change(event, 'username', 'required'),
                                                type: 'text',
                                                value: this.getFormFieldValue('username'),
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            success={passwordState === 'success'}
                                            error={passwordState === 'error'}
                                            labelText="Password/Pin"
                                            id="password"
                                            formControlProps={{ fullWidth: true, required: true }}
                                            inputProps={{
                                                onChange: (event) => this.change(event, 'password', 'required'),
                                                type: 'password',
                                                value: this.getFormFieldValue('password'),
                                            }}
                                        />
                                    </GridItem>
                                    {user.providerFirma === 'aruba' ? (<GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            success={otpState === 'success'}
                                            error={otpState === 'error'}
                                            labelText="OTP"
                                            id="otp"
                                            formControlProps={{ fullWidth: true, required: true }}
                                            inputProps={{
                                                onChange: (event) => this.change(event, 'otp', 'required'),
                                                value: this.getFormFieldValue('otp'),
                                            }}
                                        />
                                    </GridItem>) : ''}
                                    <GridItem xs={12} sm={12} md={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    color="primary"
                                                    checked={this.getFormFieldValue('principale')}
                                                    onChange={(event) => this.change(event, 'principale', 'principale')}
                                                />
                                            }
                                            classes={{ label: classes.selectLabel }}
                                            label={t('user.digitalSignatures.dafaultSign')}
                                        />
                                    </GridItem>
                                </>
                            )}
                    </GridContainer>
                </DialogContent>
                <DialogActions className={classes.modalFooter + ' ' + classes.modalFooterCenter}>
                    <Button color={cardColor} onClick={() => this.handleSubmit()}>
                        {t('action.save')}
                    </Button>
                    <Button color="info" onClick={() => this.handleClose()}>
                        <Close /> {t('action.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, users, tema } = state;
    const { id_utente } = authentication.user ?? {};
    const { user } = authentication ?? {};
    const { loading, addEditFirma } = users.current ?? {};

    return {
        id_utente,
        alert,
        loading,
        addEditFirma,
        users,
        tema,
        user
    };
}

const connectedFirmaEditModalForm = compose(connect(mapStateToProps), withStyles(styles), withTranslation())(FirmaEditModalForm);
export default connectedFirmaEditModalForm;
