export const analyticsConstants = {
  SET_CHART_REF: 'SET_CHART_REF',

  SET_OVERALL_OPTIONS_REQUEST: 'SET_OVERALL_OPTIONS_REQUEST',

  GET_OVERALL_ANALYSIS_REQUEST: 'GET_OVERALL_ANALYSIS_REQUEST',
  GET_OVERALL_ANALYSIS_SUCCESS: 'GET_OVERALL_ANALYSIS_SUCCESS',
  GET_OVERALL_ANALYSIS_FAILURE: 'GET_OVERALL_ANALYSIS_FAILURE',

  SET_TIMEFRAME_OPTIONS_REQUEST: 'SET_TIMEFRAME_OPTIONS_REQUEST',

  GET_TIMEFRAME_ANALYSIS_REQUEST: 'GET_TIMEFRAME_ANALYSIS_REQUEST',
  GET_TIMEFRAME_ANALYSIS_SUCCESS: 'GET_TIMEFRAME_ANALYSIS_SUCCESS',
  GET_TIMEFRAME_ANALYSIS_FAILURE: 'GET_TIMEFRAME_ANALYSIS_FAILURE',

  SET_COUNTER_OPTIONS_REQUEST: 'SET_COUNTER_OPTIONS_REQUEST',

  GET_COUNTER_ANALYSIS_REQUEST: 'GET_COUNTER_ANALYSIS_REQUEST',
  GET_COUNTER_ANALYSIS_SUCCESS: 'GET_COUNTER_ANALYSIS_SUCCESS',
  GET_COUNTER_ANALYSIS_FAILURE: 'GET_COUNTER_ANALYSIS_FAILURE',

  GET_FILTERED_USERS_REQUEST: 'GET_FILTERED_USERS_REQUEST',
  GET_FILTERED_USERS_SUCCESS: 'GET_FILTERED_USERS_SUCCESS',
  GET_FILTERED_USERS_FAILURE: 'GET_FILTERED_USERS_FAILURE',

  GET_ANALYSIS_DOCS_DETAILS_REQUEST: 'GET_ANALYSIS_DOCS_DETAILS_REQUEST',
  GET_ANALYSIS_DOCS_DETAILS_SUCCESS: 'GET_ANALYSIS_DOCS_DETAILS_SUCCESS',
  GET_ANALYSIS_DOCS_DETAILS_FAILURE: 'GET_ANALYSIS_DOCS_DETAILS_FAILURE',

  SET_PAGINATION: 'SET_PAGINATION',
  SET_DOCS_SORTING: 'SET_DOCS_SORTING',
  SET_FILTERS: 'SET_FILTERS',
  SET_DOCS_DETAILS: 'SET_DOCS_DETAILS',
  RESET_DOCS_DETAILS: 'RESET_DOCS_DETAILS',

  FIND_ALL_REPORTS_REQUEST: 'FIND_ALL_REPORTS_REQUEST',
  FIND_ALL_REPORTS_SUCCESS: 'FIND_ALL_REPORTS_SUCCESS',
  FIND_ALL_REPORTS_FAILURE: 'FIND_ALL_REPORTS_FAILURE',

  DOWNLOAD_REPORT_REQUEST: 'DOWNLOAD_REPORT_REQUEST',
  DOWNLOAD_REPORT_SUCCESS: 'DOWNLOAD_REPORT_SUCCESS',
  DOWNLOAD_REPORT_FAILURE: 'DOWNLOAD_REPORT_FAILURE',
};
