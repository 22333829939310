import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import itFlag from "assets/img/flags/IT.png";
import enFlag from "assets/img/flags/US.png";

const languages = [
	{
		id: 'it',
		title: 'Italian',
		flag: itFlag
	},
	{
		id: 'en',
		title: 'English',
		flag: enFlag
	},
];

function LanguageSwitcher(props) {

    const { t, i18n } = useTranslation();
	let currentLanguageId = i18n.language;
	console.log("LINGUA CORRENTE: ", currentLanguageId)
	currentLanguageId = currentLanguageId.split('-')[0];
	const currentLanguage = languages.find(lng => lng.id === currentLanguageId);

	const [menu, setMenu] = useState(null);

	const langMenuClick = event => {
		setMenu(event.currentTarget);
	};

	const langMenuClose = () => {
		setMenu(null);
	};

	function handleLanguageChange(lng) {
        i18n.changeLanguage(lng.id);
		langMenuClose();
	}

	return (
		<>
			<Button className="h-40 w-64" onClick={langMenuClick}>
				<img
					className="mx-4 min-w-20"
					src={currentLanguage.flag}
					alt={currentLanguage.title}
				/>

				<Typography className="mx-4 font-semibold uppercase" color="textSecondary">
					{currentLanguage.id}
				</Typography>
			</Button>

			<Popover
				open={Boolean(menu)}
				anchorEl={menu}
				onClose={langMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{languages.map(lng => (
					<MenuItem key={lng.id} onClick={() => handleLanguageChange(lng)}>
						<ListItemIcon className="min-w-40">
							<img className="min-w-20" src={lng.flag} alt={lng.title} />
						</ListItemIcon>
						<ListItemText primary={lng.title} />
					</MenuItem>
				))}
			</Popover>
		</>
	);
}

export default LanguageSwitcher;
