import { withRouter } from 'react-router-dom';
import Dashboard from 'views/Dashboard/TC_Dashboard.jsx';
import TCSignDocumenti from 'views/Tables/TCSign_Documenti.jsx';
import TCSignUtenti from 'views/Tables/TCSign_Utenti.jsx';
import TCSignUO from 'views/Tables/TCSign_UO.jsx';
import TCSignProfili from 'views/Tables/TCSign_Profili.jsx'; //CAMUSI 02072020
import TCSignRaccoglitori from 'views/Tables/TCSign_Raccoglitori.jsx';
import UserProfile from 'views/Pages/TCSign_UserProfile.jsx';
import TCSign_AS from 'views/Pages/TCSign_AS.jsx';
import TCSignAnalytics from 'views/Pages/TCSign_Analytics';
import Logout from '../views/Components/Logout';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import ContentPaste from '@material-ui/icons/FileCopy';
import Profile from '@material-ui/icons/AccountBox';
import Settings from '@material-ui/icons/Settings';
import Exit from '@material-ui/icons/PowerSettingsNew';
import Folder from '@material-ui/icons/Book';
import InsertChart from '@material-ui/icons/InsertChart';


import { ServiceMatrix } from 'Helpers/model-view.js';

var dashRoutes = [
	{
		path: '/auth/charts',
		name: 'menu.dashboard',
		icon: InsertChart,
		component: TCSignAnalytics,
		roles: ServiceMatrix.LinkScrivania,
		codMenu: 'LNKALS'
	},
	{
		icon: DashboardIcon,
		collapse: true,
		path: '/auth/grid',
		name: 'menu.yourDigitalSignature',
		state: 'openDashboard',
		roles: ServiceMatrix.LinkScrivania,
		codMenu: 'LNKSCR',
		views: [
			{
				path: '/auth/dashboard',
				name: 'menu.yourDashboard',
				mini: 'SC',
				component: Dashboard,
				roles: ServiceMatrix.LinkAttenzionati,
				codMenu: 'LNKTSC'
			},
			{
				path: '/auth/flagged',
				name: 'menu.attentiveDoc',
				mini: 'AT',
				component: withRouter(TCSignDocumenti),
				roles: ServiceMatrix.LinkAttenzionati,
				codMenu: 'LNKATZ'
			},
			{
				path: '/auth/signedchecked',
				name: 'menu.signViewDoc',
				mini: 'FV',
				component: TCSignDocumenti,
				roles: ServiceMatrix.LinkFirmatiVistati,
				codMenu: 'LNKDVF'
			},
			{
				path: '/auth/delegated',
				name: 'menu.delegatedDoc',
				mini: 'DE',
				component: TCSignDocumenti,
				roles: ServiceMatrix.LinkFirmatiVistati,
				codMenu: 'LNKDDG'
			},
			{
				path: '/auth/refused',
				name: 'menu.rejectedDoc',
				mini: 'RI',
				component: TCSignDocumenti,
				roles: ServiceMatrix.LinkRifiutati,
				codMenu: 'LNKDRF'
			},
			{
				path: '/auth/incoming',
				name: 'menu.arrivingDoc',
				mini: 'IA',
				component: TCSignDocumenti,
				roles: ServiceMatrix.LinkInArrivo,
				codMenu: 'LNKDAR'
			}
		]
	},
	{
		icon: ContentPaste,
		collapse: true,
		path: '/auth/grid',
		name: 'menu.documents',
		state: 'openComponents',
		roles: ServiceMatrix.LinkAllDocuments,
		codMenu: 'LNKDOC',
		views: [
			{
				path: '/auth/grid',
				name: 'menu.allDoc',
				mini: 'AL',
				component: TCSignDocumenti
			},
			{
				path: '/auth/mydocuments',
				name: 'menu.myDoc',
				mini: 'MY',
				component: TCSignDocumenti,
				roles: ServiceMatrix.LinkMyDocuments,
				codMenu: 'LNKMYD'
			},
			{
				path: '/auth/deleted',
				name: 'menu.delDoc',
				mini: 'CA',
				component: TCSignDocumenti,
				roles: ServiceMatrix.LinkCancellati,
				codMenu: 'LNKDDL'
			}
		]
	},
	{
		path: '/auth/folders',
		name: 'menu.signaturePaths',
		icon: Folder,
		component: TCSignRaccoglitori,
		roles: ServiceMatrix.PercorsiFirma,
		codMenu: 'LNKPFR'
	},
	{
		icon: Settings,
		collapse: true,
		path: '/auth/settings',
		name: 'menu.configuration',
		state: 'openSettings',
		roles: ServiceMatrix.GestioneUtenti,
		codMenu: 'LNKCNF',
		views: [
			{
				path: '/auth/users',
				name: 'menu.userMgt',
				mini: 'GU',
				codMenu: 'LNKGUT',
				component: TCSignUtenti
			},
			{
				path: '/auth/uo',
				name: 'menu.uoMgt',
				mini: 'UO',
				codMenu: 'LNKGUO',
				component: TCSignUO
			},
			{
				path: '/auth/profili',
				name: 'menu.profileMgt',
				mini: 'GP',
				codMenu: 'LNKGPR',
				component: TCSignProfili
			},
			{
				path: '/auth/as',
				name: 'menu.siteMgt',
				mini: 'AS',
				codMenu: 'LNKAS',
				component: TCSign_AS
			}
		]
	},
	{
		user: true,
		path: '/auth/edit-profile',
		name: 'menu.userProfile',
		icon: Profile,
		component: UserProfile
	},
	{
		user: true,
		path: '/auth/logout',
		name: 'Logout',
		icon: Exit,
		component: Logout
	},
	{ redirect: true, path: '/', pathTo: '/auth/dashboard', name: 'menu.dashboard' }
];

export default dashRoutes;
