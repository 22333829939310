import { settingsConstants } from '../Constants';
import { settingsService } from '../Services';
import { userActions } from './index';

export const settingsActions = {
    getMaintenance,
    setMaintenance,
};

function getMaintenance(isAdmin, history) {
    return (dispatch) => {
        dispatch(request());

        settingsService.getMaintenance().then(
            (data) => {
                const underMaintenance = data.Result.Data.valore === 'true' ? true : false;

                dispatch(success(underMaintenance));

                if (!isAdmin && underMaintenance) {
                    localStorage.setItem('underMaintenance', 'true');
                    history.push('/maintenance');
                    dispatch(userActions.logout());
                } else if (!isAdmin) history.push('/auth');
            },
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: settingsConstants.GET_MAINTENANCE_REQUEST };
    }
    function success(underMaintenance) {
        return {
            type: settingsConstants.GET_MAINTENANCE_SUCCESS,
            payload: underMaintenance,
        };
    }
    function failure(error) {
        return { type: settingsConstants.GET_MAINTENANCE_FAILURE, error };
    }
}

function setMaintenance(value) {
    return (dispatch) => {
        dispatch(request());
        settingsService.setMaintenance(value).then(
            () => dispatch(success(value)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: settingsConstants.SET_MAINTENANCE_REQUEST };
    }
    function success() {
        return {
            type: settingsConstants.SET_MAINTENANCE_SUCCESS,
            payload: value,
        };
    }
    function failure(error) {
        return { type: settingsConstants.SET_MAINTENANCE_FAILURE, error };
    }
}
