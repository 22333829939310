import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import User from "@material-ui/icons/AccountBalance";
import People from "@material-ui/icons/AccountBalance";
import Search from "@material-ui/icons/Search";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";

import { userActions, alertActions } from "../../Actions";

import ProfiloModalForm from "Custom/Profilo/ProfiloModalForm.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import { Authorize } from "Helpers/model-view.js";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
`;

const styles = {
    ...extendedFormsStyle,
    selectLabel: {
        fontSize: "14px",
        //textTransform: "uppercase",
        color: "#AAA !important",
        top: "8px",
    },
    tcsign_card: {
        "margin-top": "0px",
        "margin-bottom": "0px",
    },
    tcsign_cardbody: {
        "padding-top": "0",
        "padding-bottom": "0",
    },
    tcsign_action: {
        //"padding": "0px!important",
        //"margin": "0px!important",
        width: "20px",
        minWidth: "20px",
    },
    tcsign_warning: {
        top: "0",
        width: "18px",
        height: "18px",
        display: "inline-block",
        position: "relative",
        marginRight: "4px",
        verticalAlign: "middle",
    },
    tc_sign_btn_confirm: {
        "background-color": "#4caf50 !important",
    },
    tc_sign_datetime: {
        color: "#4caf50",
        top: "-2px",

        "& input.form-control": {
            backgroundImage: "linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2)!important",
        },

        "& .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle":
            {
                color: "#4caf50",
            },
    },
    tc_sign_searchSelectLabel: {
        fontSize: "10px",
        //textTransform: "uppercase",
        color: "#AAA !important",
        top: "8px",
        padding: "0px",
    },
    tc_sign_search_row: {
        paddingTop: "10px!important",
    },
};

class ProfiliTables extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            listaProfili: [],
            alert: undefined,
            show: false,
            showProfilo: false,
            showDocumento: false,
            selectedDocumento: undefined,
            multipleSelect: [],
            closeInfo: false,
        };
    }

    getData() {
        this.props.dispatch(userActions.getAllProfili());
        //this.props.dispatch(utilsActions.getRuoliUtente());
    }

    componentDidMount() {
        this.getData();
    }

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }

    handleClickEditProfilo(idProfilo) {
        var x = [];
        x["showProfilo"] = true;
        x["selectedProfilo"] = idProfilo;
        this.setState(x);
    }

    handleClickDeleteProfilo(idProfilo) {
        const { t } = this.props;
        this.setState({
            confirmPanel: (
                <SweetAlert
                    info
                    showCancel
                    confirmBtnText="Ok"
                    confirmBtnCssClass={this.props.classes.tc_sign_btn_confirm}
                    cancelBtnText={t("action.cancel")}
                    cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
                    title={t("alert.deleteProfileConfirm")}
                    onConfirm={(e) => {
                        this.props.dispatch(userActions.deleteProfiloById(idProfilo));

                        this.setState({ confirmPanel: undefined });
                    }}
                    onCancel={() => {
                        this.setState({ confirmPanel: undefined });
                    }}
                ></SweetAlert>
            ),
        });
    }

    handleClickCloseProfilo() {
        var x = [];
        x["showProfilo"] = false;
        x["selectedProfilo"] = undefined;
        this.setState(x);
    }

    render() {
        const { confirmPanel } = this.state;
        const { t, classes, users, alert, tema } = this.props;
        let cardColor = tema.theme_color;
        let alertMessage = "";
        if (alert && typeof alert.message !== "undefined") {
            const message = t(`alert.${alert.message}`);
            if (alert.type === "alert-success") {
                alertMessage = (
                    <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
                        {message}
                    </SweetAlert>
                );
            } else if (alert.type === "alert-danger") {
                alertMessage = (
                    <SweetAlert
                        danger
                        title={t("alert.warning")}
                        onConfirm={() => {
                            this.handleCloseAlert();
                            if (alert.message === "sessionExpired") {
                                this.props.dispatch(userActions.logout());
                            }
                        }}
                    >
                        {message}
                    </SweetAlert>
                );
            }
        }

        let listaProfili = [];
        if (users && users.profili) {
            for (let i = 0; i < users.profili.length; i++) {
                listaProfili.push(users.profili[i]);
            }
        }

        let loading;
        if (users && users.loading) {
            loading = (
                <div css={overlay}>
                    <div css={override}>
                        <ClipLoader sizeUnit={"px"} size={80} color={"#3a783d"} loading={users.loading} />
                    </div>
                </div>
            );
        }

        let wProfiloModalForm = null;
        if (this.state.showProfilo) {
            wProfiloModalForm = (
                <ProfiloModalForm
                    //title={(typeof (this.state.selectedProfilo) === "undefined") ? "Nuovo Profilo" : "Profilo: " + this.state.selectedProfilo}
                    title={
                        typeof users.current !== "undefined"
                            ? t("profiles.title") + users.current.desc_ruolo
                            : t("profiles.new")
                    }
                    open={this.state.showProfilo}
                    id={this.state.selectedProfilo}
                    keepMounted
                    onClose={() => this.handleClickCloseProfilo()}
                    aria-labelledby="notice-modal-slide-title"
                    aria-describedby="notice-modal-slide-description"
                />
            );
        }

        let wToolbarCreate = "";
        if (Authorize.checkAuthorizeFunc("FNCCPF")) {
            //vedere model-view.js
            wToolbarCreate = (
                <div>
                    <Button
                        color={cardColor}
                        size="sm"
                        style={{ float: "right" }}
                        onClick={() => this.handleClickEditProfilo()}
                    >
                        <User /> {t("profiles.new")}
                    </Button>
                    {wProfiloModalForm}
                </div>
            );
        }

        return (
            <GridContainer>
                {loading}
                {alertMessage}
                {confirmPanel}
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color={cardColor} icon>
                            <CardIcon color={cardColor}>
                                <People />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle}>{t("profiles.list")}</h4>
                            {/*<Can run="delete" on={this.props.todo}>*/}
                            {wToolbarCreate}
                            {/*</Can>*/}
                        </CardHeader>
                        <CardBody>
                            <ReactTable
                                data={listaProfili.map((prop, key) => {
                                    let wReturn = prop;

                                    let wButtonEditUO = (
                                        <Tooltip
                                            id="tooltip-top"
                                            title={t("profiles.update")}
                                            placement="bottom"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <Button
                                                justIcon
                                                round
                                                simple
                                                onClick={() => this.handleClickEditProfilo(wReturn.id_ruolo)}
                                                color="info"
                                                className={classes.tcsign_action}
                                            >
                                                <Search />
                                            </Button>
                                        </Tooltip>
                                    );

                                    let wButtonRemoveUO = "";
                                    if (Authorize.checkAuthorizeFunc("FNCEPF")) {
                                        wButtonRemoveUO = (
                                            <Tooltip
                                                id="tooltip-top"
                                                title={t("profiles.delete")}
                                                placement="bottom"
                                                classes={{ tooltip: classes.tooltip }}
                                            >
                                                <Button
                                                    justIcon
                                                    round
                                                    simple
                                                    onClick={() => this.handleClickDeleteProfilo(wReturn.id_ruolo)}
                                                    color="danger"
                                                    className={classes.tcsign_action}
                                                >
                                                    <Close />
                                                </Button>
                                            </Tooltip>
                                        );
                                    }
                                    wReturn.actions = (
                                        <div className="actions-center">
                                            {wButtonEditUO}
                                            {wButtonRemoveUO}
                                        </div>
                                    );
                                    return wReturn;
                                })}
                                filterable
                                columns={[
                                    {
                                        Header: "ID",
                                        accessor: "id_ruolo",
                                        maxWidth: 50,
                                    },
                                    {
                                        Header: t("profiles.name"),
                                        accessor: "desc_ruolo",
                                        // maxWidth: 150,
                                        filterMethod: (filter, row) =>
                                            row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                                    },
                                    {
                                        Header: t("profiles.creationDate"),
                                        accessor: "data_creazione",
                                        // maxWidth: 50,
                                        filterMethod: (filter, row) =>
                                            row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                                    },
                                    {
                                        Header: t("profiles.roleCode"),
                                        accessor: "cod_ruolo",
                                        // maxWidth: 150,
                                        filterMethod: (filter, row) =>
                                            row[filter.id].toUpperCase().includes(filter.value.toUpperCase()),
                                    },
                                    {
                                        Header: t("profiles.actions"),
                                        accessor: "actions",
                                        sortable: false,
                                        filterable: false,
                                        maxWidth: 200,
                                    },
                                ]}
                                defaultPageSize={10}
                                showPaginationTop={false}
                                showPaginationBottom
                                className="-striped -highlight"
                                striped
                                hover
                                condensed
                                pagination
                                insertRow
                                deleteRow
                                search
                                // Text
                                previousText={t("table.previous")}
                                nextText={t("table.next")}
                                loadingText={t("table.loading")}
                                noDataText={t("profiles.notFound")}
                                pageText={t("table.page")}
                                ofText={t("table.of")}
                                rowsText={t("table.rows")}
                            />
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, users, utils, tema } = state;
    return {
        user: authentication?.user ?? {},
        users,
        alert,
        utils,
        tema,
    };
}

const connectedProfiliTables = compose(connect(mapStateToProps), withStyles(styles), withTranslation())(ProfiliTables);
export default connectedProfiliTables;
