import { v4 as uuidv4 } from 'uuid';
import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, withStyles, TextField, Typography } from '@material-ui/core';
import DateRange from '../../../../Custom/Utilities/DateRange';

import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

import { analyticsActions } from '../../../../Actions';

import { COMMON_FILTERS, MenuProps, STYLES, getTypesOptions } from '../Constants';

const styles = () => ({ ...STYLES });

const AnalysisFilters = ({ classes }) => {
	const { t } = useTranslation();
	const { documentsType, timeframeLabel } = t('analytics', { returnObjects: true }).filtersLabels;
	const timeframesList = t('analytics', { returnObjects: true }).timeframes;
	const all = t('document.all');

	const dispatch = useDispatch();
	const options = useSelector((state) => state.analytics.overall.options);
	const timeframeOptions = useSelector((state) => state.analytics.timeframe.options);
	const counterOptions = useSelector((state) => state.analytics.counter.options);
	const documentiTypes = useSelector((state) => state.utils.tipologia_documento);
	const { timeframe, dateRange, tipo_documento } = options;

	const typesOptions = useMemo(() => getTypesOptions(documentiTypes, all), [all]);

	const onChange = ({ target: { value, name } }) => {
		const commonFilters = { ...options, [name]: value };
		dispatch(analyticsActions.setOverallOptions({ [name]: value }));

		if (value !== 'custom') {
			dispatch(analyticsActions.getOverallAnalysis(commonFilters));
			dispatch(analyticsActions.getTimeframeAnalysis({ ...commonFilters, ...timeframeOptions }));
			if (counterOptions.user)
				dispatch(analyticsActions.getCounterAnalysis({ ...commonFilters, ...counterOptions }));
		}
	};

	const analysisInputs = [
		{
			value: tipo_documento,
			name: 'tipo_documento',
			label: documentsType,
			onChange: onChange,
			options: typesOptions
		},
		{
			value: timeframe,
			name: 'timeframe',
			label: timeframeLabel,
			onChange: onChange,
			options: COMMON_FILTERS.timeframeOptions(timeframesList),
			endAdornment: timeframe === 'custom' && (
				<Tooltip title={t('analytics.customRangeInfo')}>
					<InfoOutlined style={{ color: '#4caf50' }} />
				</Tooltip>
			)
		}
	];

	return (
		<div className={classes.flexHorizontal}>
			{analysisInputs.map(({ value, name, label, options, onChange, endAdornment }) => (
				<TextField
					key={uuidv4()}
					select
					name={name}
					margin='dense'
					value={value}
					label={label}
					style={{ width: '200px', margin: 0 }}
					InputLabelProps={{ FormLabelClasses: { focused: classes.selectLabel } }}
					SelectProps={{
						style: { fontSize: '14px' },
						MenuProps,
						classes: { outlined: classes.outlined }
					}}
					classes={{ root: classes.root }}
					InputProps={{ endAdornment, classes: { notchedOutline: classes.notchedOutline } }}
					variant='outlined'
					onChange={onChange}
				>
					{options.map(({ label, value }) => (
						<MenuItem key={uuidv4()} value={value} dense classes={{ root: classes.selectMenuItem }}>
							<Typography
								noWrap
								title={label}
								style={{ fontWeight: 'lighter', fontSize: '0.8rem', lineHeight: 'normal' }}
							>
								{label}
							</Typography>
						</MenuItem>
					))}
				</TextField>
			))}
			{timeframe === 'custom' && (
				<div>
					<DateRange
						format='YYYY-MM-DD HH:mm:ss.SSS'
						timeFormat={false}
						maxRange={{ amount: 1, unit: 'month' }}
						dateRange={dateRange}
						setDateRange={(value, name) => {
							const newOption = { ...dateRange, [name]: value };
							const commonFilters = { ...options, dateRange: newOption };

							dispatch(analyticsActions.setOverallOptions({ dateRange: newOption }));

							if (newOption.from && newOption.to) {
								dispatch(analyticsActions.getOverallAnalysis(commonFilters));
								dispatch(
									analyticsActions.getTimeframeAnalysis({ ...commonFilters, ...timeframeOptions })
								);
							}
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default memo(withStyles(styles)(AnalysisFilters));
