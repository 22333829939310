import React from 'react';
import { connect } from 'react-redux';

import { withStyles, Tooltip, IconButton } from '@material-ui/core';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { Edit, Check, Delete, LibraryAdd } from '@material-ui/icons/';

import Table from 'components/Table/Table.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import FirmaEditModalForm from './FirmaEditModalForm.jsx';
import { userActions } from '../../Actions';

import { compose } from 'recompose'
import { withTranslation } from 'react-i18next';

const styles = () => ({
    ...extendedFormsStyle,
    tcSignTableCell: {
        height: '28px!important',
        opacity: '1',
        fontSize: '14px',
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: '400',
        lineHeight: '1.42857',
    },
});

class ProfiloFirma extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selected_firma: undefined,
            showModalFirma: false,
        };
    }

    handleClickRemove(idFirma) {
        this.props.dispatch(userActions.deleteSignature(idFirma));
    }

    handleClickEditFirma(idFirma) {
        const { user } = this.props;

        const firma = user.firma.filter((firma) => firma.id_digital_signature === idFirma)[0];

        this.setState({
            selected_firma: firma,
        });
        this.props.dispatch(userActions.addEditFirma(true));
    }

    handleClickAddFirma() {
        this.setState({
            selected_firma: undefined,
        });

        this.props.dispatch(userActions.addEditFirma(true));
    }

    render() {
        const { t, classes, user, tema } = this.props;
        const { selected_firma } = this.state;
        let cardColor = tema.theme_color;
        let elencoFirma = [];
        if (user.firma?.length > 0) {
            elencoFirma = user.firma.map((prop, key) => {
                let wActions = (
                    <div>
                        <Tooltip
                            id="tooltip-top"
                            title={t('action.modify')}
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton
                                aria-label="Edit"
                                className={classes.tableActionButton}
                                onClick={() => this.handleClickEditFirma(prop.id_digital_signature)}>
                                <Edit
                                    style={{ color: '#6ab56d' }}
                                    className={classes.tableActionButtonIcon + ' ' + classes.edit}
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            id="tooltip-top-start"
                            title={t('action.delete')}
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}>
                            <IconButton
                                aria-label="Close"
                                className={classes.tableActionButton}
                                onClick={() => this.handleClickRemove(prop.id_digital_signature)}>
                                <Delete
                                    style={{ color: 'red' }}
                                    className={classes.tableActionButtonIcon + ' ' + classes.close}
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                );

                return [
                    prop.id_digital_signature,
                    prop.username,
                    '**********',
                    prop.principale ? <Check style={{ color: '#6ab56d' }} /> : '',
                    wActions,
                ];
            });
        }

        return (
            <div>
                <FirmaEditModalForm firma={selected_firma} />
                <Table
                    hover
                    tableData={elencoFirma}
                    customCellClasses={[
                        classes.tcSignTableCell,
                        classes.tcSignTableCell,
                        classes.tcSignTableCell,
                        classes.tcSignTableCell,
                        classes.tcSignTableCell,
                    ]}
                    customClassesForCells={[0, 1, 2, 3, 4]}
                />
                <Button color={cardColor} style={{ float: 'right' }} onClick={() => this.handleClickAddFirma()}>
                    <LibraryAdd />
                </Button>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { utils, authentication, tema } = state;
    const { user } = authentication ?? {};

    return {
        utils,
        user,
        tema
    };
}

const connectedProfiloFirma = compose(connect(mapStateToProps), withStyles(styles), withTranslation())(ProfiloFirma);
export default connectedProfiloFirma;
