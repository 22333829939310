import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import ChartDetails from '../ReusableComponents/ChartDetails';
import { analyticsActions } from '../../../../Actions';

const Chart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const states = t('analytics.states', { returnObjects: true });
  const { ruoli, ids_uo_visibili } = useSelector(({ authentication }) => authentication.user);
  const { tipo_documento, timeframe } = useSelector(({ analytics }) => analytics.overall.options);
  const scope = useSelector(({ analytics }) => analytics.timeframe.options.scope);
  const result = useSelector((state) => state.analytics.timeframe.result);

  const [openDetails, setOpenDetails] = useState(false);

  const timeframesMapping = {
    today: 'hour',
    last_week: 'day',
    last_month: 'day',
    last_year: 'month',
    custom: 'day',
  };

  const onClick = ({ name, format, count }) => {
    const from = moment(name, format);
    const detailsTimeframe = {
      from: from.format('YYYY-MM-DD HH:mm:ss'),
      to: from.add(1, timeframesMapping[timeframe]).format('YYYY-MM-DD HH:mm:ss'),
    };

    const filters = {
      scope,
      timeframe: detailsTimeframe,
      tipo_documento,
      ids_uo_visibili,
      ruoli,
      analysisType: 'timeframe',
    };

    dispatch(analyticsActions.setDocsDetails({ total: count, scope: states[scope] }));
    dispatch(analyticsActions.setFilters(filters));
    dispatch(analyticsActions.getAnalysisDocsDetails(filters));
    setOpenDetails(true);
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={800}
          height={400}
          data={result}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#8884d8"
            dataKey="count"
            tickCount={10}
            allowDecimals={false}
          />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <Bar
            cursor="pointer"
            onClick={onClick}
            yAxisId="left"
            name={`No. ${t('menu.documents')}`}
            dataKey="count"
            fill="#8884d8"
          />
          {/* <Bar yAxisId="right" dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
      {openDetails && <ChartDetails closeDetails={() => setOpenDetails(false)} />}
    </>
  );
};

export default Chart;
