export function authHeader() {
    // return authorization header with jwt token
    let token = JSON.parse(localStorage.getItem('token'));
    let user = JSON.parse(localStorage.getItem('user'));

    if (token && token.access_token) {
        return { 'Authorization': 'Bearer ' + token.access_token.Token.token, 'X-Company': user.company.id_company, 'X-Company-Desc': user.company.denominazione, 'X-Company-Code': user.company.tenant_code };
    } else {
        return {};
    }
}