import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle";
import { isMobile } from 'react-device-detect';

function Footer({ ...props }) {
  const { classes, fluid, white } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white
  });
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });

  return (
    <footer className={isMobile ? classes.footer_mobile : classes.footer}>
      <div className={container}>
        <p className={isMobile ? classes.center_mobile : classes.center}>
          Copyright
  &copy;
          <a href="https://www.teleconsys.it" className={anchor}>
            {" TCSign - Teleconsys "}
            {" "}{1900 + new Date().getYear()}{" "}
          </a>
        </p>

        {/*<p className={classes.right}>
          
          <a href="/serve/tcsign.apk" className={anchor}>
            <img src={android} width="100" height="50" />
          </a>
         
        </p>
        <p className={classes.right}>
          
          <a href="/serve/tcsign.ipa" className={anchor}>
            <img src={apple} width="50" height="50" />
          </a>
         
        </p>*/}
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool
};

export default withStyles(footerStyle)(Footer);
