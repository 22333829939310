import moment from 'moment';
import React, { memo, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, withStyles } from '@material-ui/core';
import { COUNTER, getTypesOptions } from '../Constants';

import { analyticsActions } from '../../../../Actions';

const { COLORS_MAPPING } = COUNTER;

const styles = () => ({
  container: {
    width: '600px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '30px',
    padding: '10px',
  },
  outerRing: {
    width: '225px',
    height: '225px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerRing: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const DownloadChart = ({ classes, selectedUser }) => {
  const { t } = useTranslation();
  const all = t('document.all');
  const { document, documents, has } = t('analytics.charts', { returnObjects: true });
  const { documentsType } = t('analytics.filtersLabels', { returnObjects: true });
  const { signed, created, viewed, rejected, draft, toSign, toView } = t('analytics.states', {
    returnObjects: true,
  });

  const dispatch = useDispatch();
  const { scope, count } = useSelector((state) => state.analytics.counter.result);
  const timeframe = useSelector(({ analytics }) => analytics.counter.timeframe);
  const documentiTypes = useSelector((state) => state.utils.tipologia_documento);
  const tipo_documento = useSelector((state) => state.analytics.overall.options.tipo_documento);
  const language = useSelector((state) => state.settings.language);

  const currentColor = COLORS_MAPPING[scope];

  const descriptionMapping = {
    created,
    signed,
    viewed,
    rejected,
  };

  const stateDescriptionMapping = {
    draft,
    toSign,
    toView,
  };

  const counterRefCB = useCallback((el) => {
    if (el)
      dispatch(
        analyticsActions.setChartRef({
          chartName: 'counter',
          ref: el,
        })
      );
  }, []);

  const documentTypeLabel = useMemo(
    () =>
      getTypesOptions(documentiTypes, all).filter(({ value }) => value === tipo_documento)[0].label,
    [documentiTypes, tipo_documento, language, all]
  );

  return (
    <div ref={counterRefCB} className={classes.container}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
        }}
      >
        <div>
          <Typography>
            {t('research.from')}{' '}
            <span style={{ fontWeight: 600 }}>{moment(timeframe.from).format('DD/MM/YYYY')}</span>{' '}
            {t('research.to')}{' '}
            <span style={{ fontWeight: 600 }}>{moment(timeframe.to).format('DD/MM/YYYY')}</span>
          </Typography>
        </div>
        <div>
          {documentsType}: <span style={{ fontWeight: 600 }}>{documentTypeLabel}</span>
        </div>
      </div>
      <div className={classes.outerRing} style={{ border: `5px solid ${currentColor}` }}>
        <div className={classes.innerRing} style={{ border: `30px solid ${currentColor}` }}>
          <Typography style={{ fontSize: '40px', color: currentColor }}>{count}</Typography>
        </div>
      </div>
      <Typography style={{ fontWeight: 800 }}>
        {selectedUser?.label} {has}{' '}
        {descriptionMapping[scope]?.toLowerCase().replace('ati', 'ato') || ''} {count}{' '}
        {count === 1 ? document : documents}{' '}
        {stateDescriptionMapping[scope]?.toLowerCase().replace('ati', 'ato') || ''}
      </Typography>
    </div>
  );
};

export default withStyles(styles)(memo(DownloadChart));
