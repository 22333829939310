import { raccoglitoriConstants } from '../Constants';

export function raccoglitori(state = {}, action) {
    switch (action.type) {
        case raccoglitoriConstants.RESET_RACCOGLITORE:
            return {
                ...state,
                current: undefined,
            };
        case raccoglitoriConstants.RACCOGLITORI_GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case raccoglitoriConstants.RACCOGLITORI_GETALL_SUCCESS:
            return {
                ...state,
                items: action.raccoglitori,
                loading: false,
            };
        case raccoglitoriConstants.RACCOGLITORI_GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false
            };
        case raccoglitoriConstants.RACCOGLITORI_GET_REQUEST:
            return {
                ...state,
                loading: true
            };
        case raccoglitoriConstants.RACCOGLITORI_GET_SUCCESS:
            return {
                ...state,
                current: action.raccoglitore,
                loading: false,
            };
        case raccoglitoriConstants.RACCOGLITORI_GET_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
            };
        case raccoglitoriConstants.RACCOGLITORI_GET_FIRMATARIO_REQUEST:
            return {
                ...state,
                loading: true
            };
        case raccoglitoriConstants.RACCOGLITORI_GET_FIRMATARIO_SUCCESS:
            let wCurrent = state.current;
            wCurrent.percorsoFirma = action.percorsoFirma;
            return {
                ...state,
                current: wCurrent,
                loading: false,
            };
        case raccoglitoriConstants.RACCOGLITORI_GET_FIRMATARIO_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case raccoglitoriConstants.RACCOGLITORI_SAVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case raccoglitoriConstants.RACCOGLITORI_SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case raccoglitoriConstants.RACCOGLITORI_SAVE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case raccoglitoriConstants.RACCOGLITORI_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case raccoglitoriConstants.RACCOGLITORI_DELETE_SUCCESS:
            return {
                ...state,
                current: action.raccoglitore,
                loading: false,
            };
        case raccoglitoriConstants.RACCOGLITORI_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case raccoglitoriConstants.RECUPERA_DOCUMENTO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case raccoglitoriConstants.RECUPERA_DOCUMENTO_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case raccoglitoriConstants.RECUPERA_DOCUMENTO_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state
    }
}