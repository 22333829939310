import React from 'react';
import { TextField, CircularProgress, withStyles } from '@material-ui/core';
import { IconContext } from 'react-icons';
import { FaCheckCircle } from 'react-icons/fa';
import { documentiActions } from '../../../../Actions';
import { connect } from 'react-redux';
import Footer from '../reusable/Footer';
import { withSnackbar } from 'notistack';

const styles = () => ({
    center: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

class OTPVerification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            otp: '',
            fetching: false,
            error: false,
        };
    }

    verifyIdentity = () => {
        this.setState({ fetching: true, error: false });
        this.props.dispatch(documentiActions.verificaOtp(this.state.otp));
    };

    componentDidUpdate = (prevProps) => {
        const { otp_verificato } = this.props.documenti;
        const { firmato } = this.props.documenti.firmatario_esterno;

        if (prevProps.documenti.otp_verificato !== otp_verificato && otp_verificato && !firmato) {
            this.props.applySignature();
        }
    };

    resendOTP = () => {
        const { documenti } = this.props;
        this.setState({
            otp: '',
        });
        this.props.enqueueSnackbar(
            `${2 - documenti.otp.richieste_otp} tentativi di reinvio rimasti`,
            {
                variant: 'warning',
            }
        );
        const hash_code = window.location.search.replace('?hashcode=', '');
        this.props.dispatch(documentiActions.inviaOTP(hash_code));
    };

    onChange = (event) => {
        const { value } = event.target;
        this.setState({
            otp: value,
        });
    };
    render() {
        const { t, setPhase, classes, documenti } = this.props;
        const { otp } = this.state;

        return (
            <>
                {!documenti?.loading && !documenti.otp_verificato ? (
                    <>
                        <div className={classes.center}>
                            <p>A breve riceverai un codice di verifica sul tuo cellulare</p>
                            <TextField
                                margin="dense"
                                variant="outlined"
                                placeholder="Inserire codice di verifica"
                                name="otp"
                                value={otp}
                                onChange={this.onChange}
                            />
                            {documenti.otp_verificato === false && otp !== '' ? (
                                <p style={{ color: 'red' }}>Il codice inserito non è corretto</p>
                            ) : null}
                        </div>
                    </>
                ) : (
                    <div className={classes.center}>
                        {documenti?.loading ? (
                            <CircularProgress />
                        ) : documenti.otp_verificato ? (
                            <>
                                <IconContext.Provider value={{ color: 'green', size: '4em' }}>
                                    <div>
                                        <FaCheckCircle />
                                    </div>
                                </IconContext.Provider>
                                <div style={{ marginTop: '1em' }}>
                                    Identità verificata correttamente
                                </div>
                            </>
                        ) : null}
                    </div>
                )}
                <Footer
                    step={2}
                    buttonTitle="Verifica"
                    buttonDisabledProp={otp}
                    onClickButton={this.verifyIdentity}
                    resendOTP={this.resendOTP}
                    setPhase={setPhase}
                    verified={documenti.otp_verificato}
                    fetching={documenti.loading}
                    resendOTPDisabled={documenti?.otp?.richieste_otp === 3 ? true : false}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    const { documenti, alert } = state;
    return {
        documenti,
        alert,
    };
}

const connectedOTPVerification = connect(mapStateToProps)(
    withSnackbar(withStyles(styles)(OTPVerification))
);
export default connectedOTPVerification;
