import { raccoglitoriConstants } from 'Constants';
import { raccoglitoriService } from 'Services';
import { alertActions } from 'Actions';
import { documentiActions } from './documenti.actions';

export const raccoglitoriActions = {
    getAll,
    getRaccoglitoreById,
    resetRaccoglitore,
    save,
    getFirmatario,
    deleteById,
    recuperaDocumento
};

function resetRaccoglitore() {
    return { type: raccoglitoriConstants.RESET_RACCOGLITORE }
}

function getAll() {
    return dispatch => {

        dispatch(request());

        raccoglitoriService.getAll()
            .then(
                raccoglitori => dispatch(success(raccoglitori)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: raccoglitoriConstants.RACCOGLITORI_GETALL_REQUEST } }
    function success(raccoglitori) { return { type: raccoglitoriConstants.RACCOGLITORI_GETALL_SUCCESS, raccoglitori } }
    function failure(error) { return { type: raccoglitoriConstants.RACCOGLITORI_GETALL_FAILURE, error } }
}

function getRaccoglitoreById(idRaccoglitore) {
    return dispatch => {

        dispatch(request());

        raccoglitoriService.getRaccoglitoreById(idRaccoglitore)
            .then(
                raccoglitore => dispatch(success(raccoglitore)),
                error => {
                    dispatch(failure(error.toString()))
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: raccoglitoriConstants.RACCOGLITORI_GET_REQUEST } }
    function success(raccoglitore) { return { type: raccoglitoriConstants.RACCOGLITORI_GET_SUCCESS, raccoglitore } }
    function failure(error) { return { type: raccoglitoriConstants.RACCOGLITORI_GET_FAILURE, error } }
}

function getFirmatario(idRaccoglitore) {
    return dispatch => {

        dispatch(request());

        raccoglitoriService.getFirmatario(idRaccoglitore)
            .then(
                percorsoFirma => dispatch(success(percorsoFirma)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: raccoglitoriConstants.RACCOGLITORI_GET_FIRMATARIO_REQUEST } }
    function success(percorsoFirma) { return { type: raccoglitoriConstants.RACCOGLITORI_GET_FIRMATARIO_SUCCESS, percorsoFirma } }
    function failure(error) { return { type: raccoglitoriConstants.RACCOGLITORI_GET_FIRMATARIO_FAILURE, error } }
}

function save(raccoglitore) {
    return dispatch => {
        dispatch(request());
        raccoglitoriService.save(raccoglitore)
            .then(
                raccoglitore => {
                    dispatch(success(raccoglitore));
                    dispatch(raccoglitoriActions.getAll());
                    dispatch(raccoglitoriActions.getRaccoglitoreById(raccoglitore.id_raccoglitore));
                    dispatch(alertActions.success("Operazione eseguita con successo"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: raccoglitoriConstants.RACCOGLITORI_SAVE_REQUEST } }
    function success(documento) { return { type: raccoglitoriConstants.RACCOGLITORI_SAVE_SUCCESS, documento } }
    function failure(error) { return { type: raccoglitoriConstants.RACCOGLITORI_SAVE_FAILURE, error } }
}

function deleteById(idRaccoglitore) {
    return dispatch => {

        dispatch(request());

        raccoglitoriService.deleteById(idRaccoglitore)
            .then(
                raccoglitore => {
                    dispatch(success(raccoglitore));
                    dispatch(raccoglitoriActions.getAll());
                    dispatch(alertActions.success("Operazione eseguita con successo"));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: raccoglitoriConstants.RACCOGLITORI_DELETE_REQUEST } }
    function success(raccoglitore) { return { type: raccoglitoriConstants.RACCOGLITORI_DELETE_SUCCESS, raccoglitore } }
    function failure(error) { return { type: raccoglitoriConstants.RACCOGLITORI_DELETE_FAILURE, error } }
}

function recuperaDocumento(hash_Code) {
    return dispatch => {
        dispatch(request());
        raccoglitoriService.recuperaDocumento(hash_Code)
            .then(
                value => {
                    dispatch(documentiActions.getDocForExternal(value));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request() { return { type: raccoglitoriConstants.RECUPERA_DOCUMENTO_REQUEST } }
    function failure(error) { return { type: raccoglitoriConstants.RECUPERA_DOCUMENTO_FAILURE, error } }
}

//function savePercorsoFirma(raccoglitore) {
//    return dispatch => {

//        dispatch(request());

//        raccoglitoriService.savePercorsoFirma(raccoglitore)
//            .then(
//                raccoglitore => {
//                    dispatch(success(raccoglitore));
//                },
//                error => dispatch(failure(error.toString()))
//            );
//    };

//    function request() { return { type: raccoglitoriConstants.RACCOGLITORI_SAVE_PERCORSO_FIRMA_REQUEST } }
//    function success(documento) { return { type: raccoglitoriConstants.RACCOGLITORI_SAVE_PERCORSO_FIRMA_SUCCESS, documento } }
//    function failure(error) { return { type: raccoglitoriConstants.RACCOGLITORI_SAVE_PERCORSO_FIRMA_FAILURE, error } }
//}
