export const utils = {
    isJson,
    base64MimeType,
};

function isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

function base64MimeType(encoded) {
    var result = null;

    if (typeof encoded !== 'string') {
        return result;
    }

    var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
        result = mime[1];
    }

    return result;
}

export function generateOTP() {
    let otp = '';
    for (let i = 0; i < 6; i++) {
        otp += parseInt(Math.random() * 10);
    }
    return otp;
}


export function handleResponse(response) {
    return response.text().then((text) => {
      var data;
      if (text && isJson(text)) data = JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          return Promise.reject('unauthorizedOperation');
        } else {
          return Promise.reject('Sistema momentaneamente non disponibile');
        }
      } else {
        if (data.Result.Code !== 'E100') {
          const error = data?.Result?.Message;
          return Promise.reject(error);
        } else if (typeof data.Data.errors !== 'undefined' && data.Data.errors.length !== 0) {
          let error = '';
          for (var i = 0; i < data.Data.errors.length; i++) {
            error = data.Data.errors[i].error + '(' + data.Data.errors[i].id_documento + ')\n';
          }
          return Promise.reject(error);
        }
      }
  
      return data.Data;
    });
  }
  
  export async function handleDownload(response, filename) {
    console.log('filename :>> ', filename);
    return response.blob().then(async (blob) => {
      if (blob.type === 'application/json') {
        let err = JSON.parse(await blob.text());
        return Promise.reject(err.Result.Message);
      } else {
        const data = blob;
        saveAs(new Blob([data], { type: blob.type }), filename);
      }
    });
  }