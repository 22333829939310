import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../Actions';

class Logout extends React.Component {
  constructor(props) {
    props.dispatch(userActions.logout());
  }
  render() {
    return null;
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  return {
    authentication,
  };
}

const connectedLogout = connect(mapStateToProps)(Logout);

export default connectedLogout;
