import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { OVERALL } from '../Constants';

import ChartDetails from '../ReusableComponents/ChartDetails';
import { analyticsActions } from '../../../../Actions';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '../../../../Helpers';

const { COLORS_MAPPING } = OVERALL;

const Chart = ({ data }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { document, documents } = t('analytics', { returnObjects: true }).charts;
  const { ruoli, ids_uo_visibili } = useSelector(({ authentication }) => authentication.user);
  const timeframe = useSelector(({ analytics }) => analytics.overall.timeframe);
  const tipo_documento = useSelector(({ analytics }) => analytics.overall.options.tipo_documento);

  const [activeIndex, setActiveIndex] = useState(0);
  const [openDetails, setOpenDetails] = useState(false);

  const onPieEnter = (_, index) => setActiveIndex(index);

  useEffect(() => {
    if (activeIndex + 1 > data.length) setActiveIndex(0);
  }, [data]);

  const fetchDocsDetails = (id_stato_documento, scope, total) => {
    const filters = {
      id_stato_documento,
      timeframe,
      tipo_documento,
      ids_uo_visibili,
      ruoli,
      analysisType: 'overall',
    };
    dispatch(analyticsActions.setDocsDetails({ total, scope }));
    dispatch(analyticsActions.setFilters(filters));
    dispatch(analyticsActions.getAnalysisDocsDetails(filters));
    setOpenDetails(true);
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, ...more } = props;
    const { fill, payload, percent, value } = more;

    const { label, id_stato_documento } = payload;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    const commonSectorProps = {
      cx,
      cy,
      fill,
      startAngle,
      endAngle,
      innerRadius,
      outerRadius,
      cursor: 'pointer',
      onClick: () => fetchDocsDetails(id_stato_documento, label, value),
    };

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontWeight="400">
          {label}
        </text>
        <Sector {...commonSectorProps} />
        <Sector
          {...commonSectorProps}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`${value} ${value > 1 ? documents : document}`}</text>
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  return (
    <>
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={100}
            dataKey="value"
            onMouseEnter={onPieEnter}
          >
            {data.map(({ id_stato_documento }, index) => (
              <Cell key={`cell-${index}`} fill={COLORS_MAPPING[id_stato_documento]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {openDetails && <ChartDetails closeDetails={() => setOpenDetails(false)} />}
    </>
  );
};

export default memo(Chart);
