import React from "react";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import Table from "components/Table/Table.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { withStyles, Slide, Dialog, DialogContent, DialogActions, FormControl } from "@material-ui/core";

// @material-ui/icons
import { Save, Close, InfoOutlined, Folder } from "@material-ui/icons";
import Up from "@material-ui/icons/ArrowUpward";
import Down from "@material-ui/icons/ArrowDownward";

import FirmatarioModalForm from "./FirmatarioModalForm.jsx";
import RaccoglitoreConfig from "./RaccoglitoreConfig.jsx";
import ContattoEsternoModalForm from "./ContattoEsternoModalForm.jsx";
import SelectSearch from "Custom/SelectSearch.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import FormValidationHelper from "../../Helpers/form-validation.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import { raccoglitoriActions, alertActions, userActions, utilsActions } from "../../Actions";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

import { ModelViewHelper, Authorize, Constants } from "Helpers/model-view.js";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const extSignDisabled = process.env.REACT_APP_EXT_SIGN_DISABLED === "true" ? true : false;

const styles = {
    ...extendedFormsStyle,
    selectLabel: {
        fontSize: "14px",
        color: "#AAA !important",
        top: "8px",
    },
    tcSignSelectControl: {
        margin: "7px 1px 0px 0px !important",
    },
    tc_sign_required_div: {
        opacity: "1",
        "font-size": "14px",
        "font-family": '"Roboto", "Helvetica", "Arial", sans-serif',
        color: "#9a9898",
        "font-weight": "400",
        "line-height": "1.42857",
    },
    spaceBetweenCenter: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
};

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
`;

const initialState = {
    direzione: "1",
    closeAlert: false,
    showConfig: true,
    id: undefined,
    priorita: undefined,
    tipo_percorso: "int",
    tipoFirma: "fd",
    nome: undefined,
    nomeState: undefined,
    descrizione: undefined,
    descrizioneState: undefined,
    submitted: false,
    showModalFirmatario: false,
    percorsi: undefined,
    id_titolario_classificazione: undefined,
    showModalFirmatarioEsterno: false,
};

class RaccoglitoreModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);
        this.state = initialState;
    }

    componentDidMount() {
        const { id, utils } = this.props;

        this.props.dispatch(userActions.getAll());

        if (id) {
            this.props.dispatch(raccoglitoriActions.getRaccoglitoreById(id));
        } else {
            if (utils?.tipologia_raccoglitore?.length) {
                let titolario_default;

                for (var y = 0; y < utils.tipologia_raccoglitore.length; y++) {
                    if (utils.tipologia_raccoglitore[y].default_value === true) {
                        titolario_default = utils.tipologia_raccoglitore[y].id_titolario_classificazione;
                    }
                }

                this.setState({ id_titolario_classificazione: titolario_default });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { current } = this.props.raccoglitori;

        if (!prevProps.raccoglitori.current && current)
            this.setState({
                tipo_percorso: current.tipo_percorso,
            });
    }

    handleSelect = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleOpenModalFirmatario() {
        this.setState({
            showModalFirmatario: true,
        });
    }

    handleOpenModalFirmatarioEsterno() {
        const { user } = this.props;
        this.setState({
            showModalFirmatarioEsterno: true,
        });
        if (user.unita_organizzative && user.unita_organizzative.length === 1) {
            this.props.dispatch(utilsActions.getContattiEsterniByUo(user.unita_organizzative[0].id_uo_group));
            this.props.dispatch(utilsActions.selectUo(user.unita_organizzative[0]));
        }
    }

    handleSubmitModalFirmatario(value) {
        const { raccoglitori } = this.props;
        const { current } = raccoglitori;
        const { tipoFirma } = this.state;
        let wPercorsoFirma = this.getFormFieldValue("percorsi");
        let tipoFirmaCorrente = current && current.id_raccoglitore ? current.tipo_firma : tipoFirma;
        const tipo_firma =
            value.firma_visto === "V"
                ? { enable_feq: false, enable_fea: false, enable_upload: false }
                : tipoFirmaCorrente === "fd"
                ? { enable_feq: true, enable_fea: false, enable_upload: false }
                : { enable_feq: false, enable_fea: true, enable_upload: false };
        let wFirmatario = {
            id_utente_owner: value.id_utente,
            id_uo_owner: value.id_uo,
            flag_firma_vista: Constants.percorso.label_firma[value.firma_visto === "V" ? "Visto" : "Firma"],
            ordinamento: wPercorsoFirma.length + 1,
            utente_owner: value.nome_utente,
            uo_owner: value.nome_uo,
            utente_ult_modif: "",
            tipo_firma,
        };
        //CAMUSI controllo per evitare di aggiungere più di una volta stessa coppia utente/uo nel percorso di firma
        for (var i = 0; i < wPercorsoFirma.length; i++) {
            if (
                wPercorsoFirma[i].id_utente_owner === wFirmatario.id_utente_owner &&
                wPercorsoFirma[i].id_uo_owner === wFirmatario.id_uo_owner
            ) {
                this.props.dispatch(alertActions.error("Firmatario esistente nel percorso"));
                return;
            }
        }

        wPercorsoFirma.push(wFirmatario);

        this.setState({
            showModalFirmatario: false,
            percorsi: wPercorsoFirma,
        });
    }

    handleSubmitModalFirmatarioEsterno(value) {
        const { tipo_firma, id_contatto, firma_visto, nome_contatto, codice_accesso } = value;
        const { t, user } = this.props;
        let wPercorsoFirma = this.getFormFieldValue("percorsi");

        let wFirmatario = {
            id_contatto_owner: id_contatto,
            flag_firma_vista: Constants.percorso.label_firma[value.firma_visto === "V" ? "Visto" : "Firma"],
            ordinamento: wPercorsoFirma.length + 1,
            contatto_owner: nome_contatto,
            utente_ult_modif: user.nome + " " + user.cognome,
            codice_accesso,
            tipo_firma,
        };

        //CAMUSI controllo per evitare di aggiungere più di una volta stessa coppia utente/uo nel percorso di firma
        for (var i = 0; i < wPercorsoFirma.length; i++) {
            if (wPercorsoFirma[i].id_contatto_owner === wFirmatario.id_contatto_owner) {
                this.props.dispatch(alertActions.error("Contatto esterno già presente nel Percorso Firma"));
                return;
            }
        }

        wPercorsoFirma.push(wFirmatario);

        this.setState({
            showModalFirmatarioEsterno: false,
            percorsi: wPercorsoFirma,
        });
    }

    handleCloseModalFirmatario() {
        this.setState({
            showModalFirmatario: false,
        });
    }

    handleCloseModalFirmatarioEsterno() {
        this.setState({
            showModalFirmatarioEsterno: false,
        });
        this.props.dispatch(utilsActions.unselectUo());
        this.props.dispatch(utilsActions.unselectContacts());
    }

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }

    handleClose() {
        this.setState(initialState);
        this.props.dispatch(raccoglitoriActions.resetRaccoglitore());
        this.props.onClose();
    }

    handleAction(key, row) {
        let wCurrentPercorsoFirma = this.getFormFieldValue("percorsi");

        for (var i = 0; i < wCurrentPercorsoFirma.length; i++) {
            if (wCurrentPercorsoFirma[i].ordinamento === row.idRow) {
                if (key.key === 0 && i !== 0) {
                    let temp = wCurrentPercorsoFirma[i - 1];
                    wCurrentPercorsoFirma[i - 1] = wCurrentPercorsoFirma[i];
                    wCurrentPercorsoFirma[i] = temp;
                } else if (key.key === 1 && i !== wCurrentPercorsoFirma.length - 1) {
                    let temp = wCurrentPercorsoFirma[i + 1];
                    wCurrentPercorsoFirma[i + 1] = wCurrentPercorsoFirma[i];
                    wCurrentPercorsoFirma[i] = temp;
                    i++;
                } else if (key.key === 2) {
                    wCurrentPercorsoFirma.splice(i, 1);
                }
            }
        }

        for (var j = 0; j < wCurrentPercorsoFirma.length; j++) {
            wCurrentPercorsoFirma[j].ordinamento = j + 1;
        }

        this.setState({ percorsi: wCurrentPercorsoFirma });
    }

    handleSubmit() {
        const { t, authentication, raccoglitori } = this.props;
        let tipo_firma = undefined;
        if (raccoglitori.current) tipo_firma = raccoglitori.current.tipo_firma;
        const { tipo_percorso, tipoFirma } = this.state;

        let nomeState = FormValidationHelper.validateField("required", this.getFormFieldValue("nome"), 1);
        let descrizioneState = FormValidationHelper.validateField("required", this.getFormFieldValue("descrizione"), 1);

        if (nomeState === "error" || descrizioneState === "error") {
            this.setState({ nomeState, descrizioneState });
            this.props.dispatch(
                alertActions.error("É necessario compilare tutti i campi obbligatori della scheda Generale.")
            );
            return;
        }

        this.setState({
            closeAlert: false,
            submitted: true,
        });

        let idRaccoglitore = this.getFormFieldValue("id");
        let nome = this.getFormFieldValue("nome");
        let descrizione = this.getFormFieldValue("descrizione");
        let percorso_firma = this.getFormFieldValue("percorsi");
        let id_titolario_classificazione = this.getFormFieldValue("id_titolario_classificazione");

        let raccoglitore = {
            id_raccoglitore: idRaccoglitore === "" ? undefined : idRaccoglitore,
            nome: nome === "" ? undefined : nome,
            descrizione: descrizione === "" ? undefined : descrizione,
            tipo_percorso,
            id_titolario_classificazione: id_titolario_classificazione === "" ? null : id_titolario_classificazione,
            utente_ult_modif: authentication.user.id_utente,
            tcs_percorso_firma: percorso_firma,
            tipo_firma: tipo_firma ? tipo_firma : tipoFirma,
        };

        const error = this.controlliLogiciPercorso(percorso_firma);

        if (!error) {
            this.props.dispatch(raccoglitoriActions.save(raccoglitore));
        } else {
            this.props.dispatch(alertActions.error(error));
        }
    }

    controlliLogiciPercorso(elencoPercorsi) {
        const { tipo_percorso } = this.state;
        const percorsoMisto = this.percorsoMisto(elencoPercorsi);
        const { percorsiEsterni, percorsiInterni } = percorsoMisto;

        if (tipo_percorso !== "mix" && elencoPercorsi.length === 0) {
            return "Il percorso di firma deve contenere almeno un firmatario";
        }

        // se il percorso è misto devono essere presenti almeno un firmatario interno ed uno esterno
        if (tipo_percorso === "mix" && (percorsiEsterni.length === 0 || percorsiInterni.length === 0)) {
            return "I percorsi misti devono contenere almeno un firmatario interno ed uno esterno";
        }

        // se il percorso è misto o ext e per gli esterni si è selezionata la fea si potrà aggiungere un solo firmatario esterno
        //if (["mix", "ext"].includes(tipo_percorso) && tipoFirma === "fea" && percorsiEsterni.length > 1) {
        //    return "Per questo tipo di percorso è possibile aggiungere un solo firmatario esterno";
        //}

        // poiché i percorsi sono o solo feq o solo fea non è più necessario questo controllo
        /*if (tipo_percorso === "mix") {
            const firstInternal = elencoPercorsi.findIndex((p) => p.id_utente_owner);
            const wrongOrder = elencoPercorsi.filter((p, index) => p.id_contatto_owner && index > firstInternal);

            if (wrongOrder.length > 0) {
                return "Per questo tipo di percorso non è possibile impostare firmatari interni prima quelli esterni";
            }
        }*/

        return false;
    }

    percorsoMisto(elencoPercorsi) {
        let percorsiInterni = [];
        let percorsiEsterni = [];
        for (let i = 0; i < elencoPercorsi.length; i++) {
            if (elencoPercorsi[i].id_contatto_owner) {
                percorsiEsterni.push(elencoPercorsi[i]);
            } else if (elencoPercorsi[i].id_uo_owner || elencoPercorsi[i].id_utente_owner) {
                percorsiInterni.push(elencoPercorsi[i]);
            }
        }
        return { percorsiInterni, percorsiEsterni };
    }

    buildActionButton(obj, idRow) {
        const { classes } = this.props;

        return [
            { row: idRow, color: "info", icon: Up },
            { row: idRow, color: "success", icon: Down },
            { row: idRow, color: "danger", icon: Close },
        ].map((prop, key) => {
            return (
                <Button
                    round
                    color={prop.color}
                    className={classes.actionButton + " " + classes.actionButtonRound}
                    key={key}
                    size="sm"
                    onClick={() => obj.handleAction({ key }, { idRow })}
                >
                    <prop.icon className={classes.icon} />
                </Button>
            );
        });
    }

    getFormFieldValue(pFieldName) {
        const { raccoglitori } = this.props;
        if (this.state[pFieldName]) {
            return this.state[pFieldName];
        } else if (raccoglitori.current) {
            let wReturn = ModelViewHelper.raccoglitori_ModelToView(raccoglitori.current);
            if (pFieldName === "tipo_percorso") {
                return wReturn["id_tipo_percorso"] === "D" ? true : false;
            }

            return wReturn[pFieldName];
        }

        if (pFieldName === "percorsi") {
            return [];
        }

        return "";
    }

    change(event, stateName, type) {
        const { value, checked } = event.target;

        if (stateName === "tipo_percorso") {
            this.setState({ [stateName]: checked });
        } else {
            this.setState({ [stateName]: value });
        }

        switch (type) {
            case "required":
                this.setState({
                    [stateName + "State"]: FormValidationHelper.validateField("required", value, 1),
                });
                break;
            default:
                break;
        }
    }

    next = () => {
        this.setState({
            showConfig: false,
        });
    };

    setTipoPercorso = (tipo_percorso) => {
        this.setState({
            tipo_percorso,
        });
    };

    setTipoFirma = (tipoFirma) => {
        this.setState({ tipoFirma });
    };

    render() {
        const { t, classes, raccoglitori, utils, tema } = this.props;
        const { tipo_percorso, tipoFirma, showConfig, nomeState, descrizioneState } = this.state;
        const { showModalFirmatario, showModalFirmatarioEsterno, submitted } = this.state;

        let cardColor = tema.theme_color;

        let alertMessage = "";
        if (alert && typeof alert.message !== "undefined") {
            const message = t(`alert.${alert.message}`);
            if (alert.type === "alert-success") {
                alertMessage = (
                    <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
                        {message}
                    </SweetAlert>
                );
            } else if (alert.type === "alert-danger") {
                alertMessage = (
                    <SweetAlert
                        danger
                        title={t("misc.warning")}
                        onConfirm={() => {
                            this.handleCloseAlert();
                            if (alert.message === "sessionExpired") {
                                this.props.dispatch(userActions.logout());
                            }
                        }}
                    >
                        {message}
                    </SweetAlert>
                );
            }
        }

        let loading;
        if (raccoglitori?.loading) {
            loading = (
                <div css={overlay}>
                    <div css={override}>
                        <ClipLoader sizeUnit={"px"} size={80} color={"#3a783d"} loading={raccoglitori.loading} />
                    </div>
                </div>
            );
        }

        let percorso = this.getFormFieldValue("percorsi");

        let firmatari = percorso
            .sort(function (a, b) {
                return a.ordinamento - b.ordinamento;
            })
            .map((prop) => {
                return [
                    prop.ordinamento,
                    prop.flag_firma_vista === "V" ? t("misc.viewCapitalize") : t("misc.signCapitalize"),
                    prop.utente_owner ? prop.utente_owner : prop.uo_owner ? prop.uo_owner : prop.contatto_owner,
                    prop.contatto_owner ? t("misc.ext") : t("misc.int"),
                    prop.codice_accesso ?? "N/A",
                    Authorize.checkAuthorizeFunc("FNCCMP") ? this.buildActionButton(this, prop.ordinamento) : "",
                ];
            });

        let optionClassificazione = [];
        if (typeof utils.tipologia_raccoglitore !== "undefined") {
            optionClassificazione = utils.tipologia_raccoglitore.map((prop) => ({
                value: prop.id_titolario_classificazione,
                label: prop.desc_titolario_classificazione,
            }));
        }

        let wButtonSalva = "";
        let wButtonAggiungi = "";
        let btnAggEsterno = "";
        if (Authorize.checkAuthorizeFunc("FNCCMP")) {
            wButtonSalva = (
                <Button color={cardColor} onClick={() => this.handleSubmit()}>
                    <Save style={{ margin: "0px" }} /> {t("action.save")}
                </Button>
            );

            if (["int", "mix"].includes(tipo_percorso))
                wButtonAggiungi = (
                    <Button
                        color={cardColor}
                        onClick={this.handleOpenModalFirmatario.bind(this)}
                        style={{ float: "right" }}
                    >
                        {t("flow.addIntContact")}
                    </Button>
                );

            if (!extSignDisabled && ["ext", "mix"].includes(tipo_percorso))
                btnAggEsterno = (
                    <Button
                        color={cardColor}
                        onClick={this.handleOpenModalFirmatarioEsterno.bind(this)}
                        style={{ float: "right" }}
                    >
                        {t("flow.addExtContact")}
                    </Button>
                );
        }

        let wTitle = this.props.title;
        if (typeof raccoglitori.current !== "undefined") {
            wTitle = t("flow.flows") + this.getFormFieldValue("id");
        }

        const tableHead = ["#", "Tipo", "Nome", "Tipo Utente", "Azione"];
        if (["mix", "ext"].includes(tipo_percorso)) tableHead.splice(4, 0, "Codice Accesso");

        const configProps = {
            close: this.props.onClose,
            next: this.next,
            tipo_percorso,
            tipoFirma,
            setTipoPercorso: this.setTipoPercorso,
            setTipoFirma: this.setTipoFirma,
        };

        if (raccoglitori.loading && !submitted) return null;

        if (!raccoglitori.loading && !raccoglitori.current && showConfig)
            return <RaccoglitoreConfig {...configProps} />;

        return (
            <Dialog
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                scroll="body"
                fullScreen
                onClose={() => this.handleClose()}
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description"
            >
                {loading}
                {alertMessage}
                <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
                    <Card style={{ marginBottom: "0px" }}>
                        <CardHeader color={cardColor} icon>
                            <CardIcon color={cardColor}>
                                <Folder />
                            </CardIcon>
                            <h4 className={classes.cardTitle} style={{ color: "#777" }}>
                                {wTitle}
                            </h4>
                        </CardHeader>
                        <CardBody>
                            <NavPills
                                color={cardColor}
                                tabs={[
                                    {
                                        tabButton: t("flow.general"),
                                        tabContent: (
                                            <GridContainer style={{ width: "100%", margin: "0px" }}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CustomInput
                                                        labelText="Id"
                                                        id="idRaccoglitore"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        inputProps={{
                                                            disabled: true,
                                                            onChange: (event) => this.change(event, "id", "id"),
                                                            type: "id",
                                                            value: this.getFormFieldValue("id"),
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CustomInput
                                                        success={this.state.nomeState === "success"}
                                                        error={this.state.nomeState === "error"}
                                                        labelText={t("flow.name")}
                                                        id="nomeRaccoglitore"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            required: true,
                                                        }}
                                                        inputProps={{
                                                            onChange: (event) => this.change(event, "nome", "required"),
                                                            type: "nome",
                                                            value: this.getFormFieldValue("nome") || "",
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CustomInput
                                                        success={this.state.descrizioneState === "success"}
                                                        error={this.state.descrizioneState === "error"}
                                                        labelText={t("flow.description")}
                                                        id="descrizioneRaccoglitore"
                                                        formControlProps={{
                                                            fullWidth: true,
                                                            required: true,
                                                        }}
                                                        inputProps={{
                                                            onChange: (event) =>
                                                                this.change(event, "descrizione", "required"),
                                                            type: "descrizione",
                                                            value: this.getFormFieldValue("descrizione") || "",
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <FormControl
                                                        fullWidth
                                                        className={
                                                            classes.selectFormControl +
                                                            " " +
                                                            classes.tcSignSelectControl
                                                        }
                                                    >
                                                        <SelectSearch
                                                            label={t("flow.classification")}
                                                            placeholder={t("flow.selectClassification")}
                                                            options={optionClassificazione}
                                                            isClearable={true}
                                                            id={"id_titolario_classificazione"}
                                                            isSearchable={true}
                                                            value={this.getFormFieldValue(
                                                                "id_titolario_classificazione"
                                                            )}
                                                            onChange={this.handleSelect}
                                                        />
                                                    </FormControl>
                                                </GridItem>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    style={{ height: "34vh", marginTop: "10px" }}
                                                >
                                                    <span className={classes.tc_sign_required_div}>
                                                        <i>{t("document.labelFieldMandatory")}</i>
                                                    </span>
                                                </GridItem>
                                            </GridContainer>
                                        ),
                                    },
                                    {
                                        tabButton: t("flow.title"),
                                        tabContent: (
                                            <GridContainer style={{ width: "100%" }} direction="column">
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <GridContainer
                                                        direction="row"
                                                        justify="flex-end"
                                                        alignItems="baseline"
                                                    >
                                                        <GridItem className={classes.spaceBetweenCenter} xs={12}>
                                                            <div>
                                                                {btnAggEsterno}
                                                                {wButtonAggiungi}
                                                            </div>
                                                            <FirmatarioModalForm
                                                                selectedIdUnitaOrganizzativa={undefined}
                                                                selectedUtente={undefined} //CAMUSI non ci stava
                                                                open={showModalFirmatario}
                                                                onClose={() => this.handleCloseModalFirmatario()}
                                                                onSubmit={(value) =>
                                                                    this.handleSubmitModalFirmatario(value)
                                                                }
                                                            />
                                                            <ContattoEsternoModalForm
                                                                open={showModalFirmatarioEsterno}
                                                                tipo_percorso={tipo_percorso}
                                                                tipoFirma={tipoFirma}
                                                                onClose={() => this.handleCloseModalFirmatarioEsterno()}
                                                                onSubmit={(value) =>
                                                                    this.handleSubmitModalFirmatarioEsterno(value)
                                                                }
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <Table
                                                        hover
                                                        tableHeaderColor="warning"
                                                        tableHead={[
                                                            "#",
                                                            t("flow.type"),
                                                            t("flow.name"),
                                                            t("flow.userType"),
                                                            t("flow.accessCode"),
                                                        ]}
                                                        tableData={firmatari}
                                                        customCellClasses={[
                                                            classes.center,
                                                            classes.center,
                                                            classes.left,
                                                            classes.left,
                                                            classes.right,
                                                        ]}
                                                        customClassesForCells={[0, 1, 2, 3, 4]}
                                                        customHeadCellClasses={[
                                                            classes.center,
                                                            classes.center,
                                                            classes.center,
                                                            classes.center,
                                                            classes.center,
                                                        ]}
                                                        customHeadClassesForCells={[0, 1, 2, 3, 4]}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                        ),
                                    },
                                ]}
                            />
                        </CardBody>
                    </Card>
                </DialogContent>
                <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                    {wButtonSalva}
                    <Button color="info" onClick={() => this.handleClose()}>
                        <Close style={{ margin: "0px" }} /> {t("action.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, raccoglitori, utils, tema } = state;
    const { user } = authentication ?? {};
    return {
        authentication,
        alert,
        raccoglitori,
        utils,
        user,
        tema,
    };
}

const connectedRaccoglitoriModalForm = compose(
    connect(mapStateToProps),
    withStyles(styles),
    withTranslation()
)(RaccoglitoreModalForm);
export default connectedRaccoglitoriModalForm;
