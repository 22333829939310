import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import moment from 'moment';

// @material-ui/core components
import { withStyles } from '@material-ui/core';
import InsertChart from '@material-ui/icons/InsertChart';
import Refresh from '@material-ui/icons/Refresh';
import Download from '@material-ui/icons/CloudDownload';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import { analyticsActions, utilsActions } from '../../Actions';

import Loading from '../Widgets/Analysis/ReusableComponents/Loading';
import OverallAnalysis from '../Widgets/Analysis/OverallAnalysis/index';
import CounterAnalysis from '../Widgets/Analysis/CounterAnalysis/index';
import TimeframeAnalysis from '../Widgets/Analysis/TimeframeAnalysis/index';
import CommonFilters from '../Widgets/Analysis/CommonFilters/index';
import { saveAs } from 'file-saver';

const styles = () => ({
  ...dashboardStyle,
  ...regularFormsStyle,
  header: {
    paddingTop: '6px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const TCSign_Analytics = ({ classes }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const analytics = useSelector((state) => state.analytics);
  const cardColor = useSelector((state) => state.tema.theme_color);
  const documentTypes = useSelector((state) => state.utils.tipologia_documento);
  const { chartsRef, overall, timeframe, counter } = analytics;

  const refresh = () => {
    dispatch(analyticsActions.getOverallAnalysis(overall.options));
    dispatch(analyticsActions.getTimeframeAnalysis({ ...timeframe.options, ...overall.options }));
    if (counter.options.user)
      dispatch(analyticsActions.getCounterAnalysis({ ...counter.options, ...overall.options }));
  };

  const exportCharts = async () => {
    try {
      Object.entries(chartsRef).forEach(async ([chart, ref]) => {
        if (ref) {
          const canvas = await html2canvas(ref);
          const img = canvas.toDataURL('image/png');
          saveAs(img, `${chart}_${moment().format('DD-MM-YYYY')}.png`);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    dispatch(utilsActions.getTipologiaDocumento());
    refresh();
  }, []);

  const headerProps = {
    cardColor,
    refresh,
    exportCharts,
    title: t('yourDashboard.analytics'),
    headerStyle: classes.header,
    titleStyle: classes.cardTitle,
  };

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color={cardColor} icon>
            <CardIcon color={cardColor}>
              <InsertChart fontSize="large" />
            </CardIcon>
            <Header {...headerProps} />
          </CardHeader>
          <CardBody style={{ paddingTop: '0px', height: '100%' }}>
            {documentTypes ? (
              <GridContainer>
                <GridItem xs={12}>
                  <CommonFilters />
                </GridItem>
                <GridItem xs={6}>
                  <OverallAnalysis />
                </GridItem>
                <GridItem xs={6}>
                  <CounterAnalysis />
                </GridItem>
                <GridItem xs={12}>
                  <TimeframeAnalysis />
                </GridItem>
              </GridContainer>
            ) : (
              <div style={{ height: '100vh' }}>
                <Loading />
              </div>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const Header = ({ title, headerStyle, titleStyle, cardColor, refresh, exportCharts }) => {
  const { t } = useTranslation();
  const { exportLabel, update } = t('action', { returnObjects: true });

  return (
    <div className={headerStyle}>
      <h3 style={{ display: 'inline' }} className={titleStyle}>
        {title}
      </h3>
      <div style={{ display: 'flex', gap: '24px' }}>
        <Button color={cardColor} size="sm" onClick={exportCharts}>
          {exportLabel} <Download style={{ margin: 'auto', marginLeft: '8px' }} />
        </Button>
        <Button color={cardColor} size="sm" onClick={refresh}>
          {update} <Refresh style={{ margin: 'auto', marginLeft: '8px' }} />
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(TCSign_Analytics);
