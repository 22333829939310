import React from 'react';
import { connect } from 'react-redux';
import Datetime from 'react-datetime';
import Select, { components } from 'react-select';
import moment from 'moment';
import { withSnackbar } from 'notistack';
import { withTranslation } from 'react-i18next';

// material core
import { withStyles, FormControl } from '@material-ui/core';

//core components
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import DateRange from '../../Utilities/DateRange';

import { searchActions } from '../../../Actions';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const styles = {
	...extendedFormsStyle,
	datetime: {
		color: '#4caf50',
		'& input.form-control': {
			backgroundImage: 'linear-gradient(#4caf50, #4caf50), linear-gradient(#D2D2D2, #D2D2D2)!important'
		},
		'& .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle':
			{
				color: '#4caf50'
			}
	},
	dateLabel: { fontSize: '10px' },
	fromControlRoot: {
		verticalAlign: 'middle'
	},
	noPad: { padding: '0 !important' }
};

const selectStyles = {
	control: (styles, state) => ({
		...styles,
		padding: '0px',
		margin: '0px',
		backgroundColor: 'transparent',
		border: 'none',
		fontSize: '14px',
		color: '#AAA !important',
		borderBottom: `1px solid ${state.isFocused ? '#4caf50' : 'rgb(204, 204, 204)'}`,
		boxShadow: state.isFocused ? `0 0 0 1px "#4caf50"` : '0px',
		borderRadius: '0px',
		'&:hover': {
			borderColor: state.isFocused ? '#4caf50' : 'rgb(204, 204, 204)'
		}
	}),
	multiValue: (styles) => {
		return {
			...styles,
			borderRadius: '5px',
			backgroundColor: '#71c174'
		};
	},
	multiValueLabel: (styles) => ({
		...styles,
		fontSize: '12px',
		color: 'white'
	}),
	multiValueRemove: (styles) => ({
		...styles,
		color: '#71c174',
		':hover': {
			borderRadius: '0 5px 5px 0',
			backgroundColor: '#4caf50',
			color: 'white'
		}
	})
};

class RicercaAvanzata extends React.PureComponent {
	setFilters = (name, value) => {
		value = value && value !== '' ? value : undefined;

		this.props.dispatch(searchActions.setFilter(name, value));
	};

	onChange = (event) => {
		const { name, value } = event.target;
		this.setFilters(name, value);
	};

	handleSelect = (event, action) => {
		const value = event?.map((ev) => ev.value) ?? [];
		this.setFilters(action.name, value);
	};

	handleKeyPress = (event) => {
		const { nome, id_documento, fulltext, data_creazione, firm_vist } = this.props.filters;
		const disableSearch =
			nome || id_documento || fulltext || data_creazione?.gte || data_creazione?.lte || firm_vist?.length > 0
				? false
				: true;

		if (event.key === 'Enter' && !disableSearch) this.props.search();
	};

	setDateRange = (value, name) => {
		const { data_creazione } = this.props.filters;

		if (value)
			this.setFilters('data_creazione', {
				...data_creazione,
				[name]: value
			});
		else {
			delete data_creazione[name];
			this.setFilters('data_creazione', { ...data_creazione });
		}
	};

	render() {
		const { t, classes, filters, ambito, users } = this.props;
		const { from, to, documentID, subject, creationDate, selectUsers, signatoryViewer } = t('research', {
			returnObjects: true
		});

		const dateRangeProps = {
			timeFormat: false,
			format: 'YYYY-MM-DD',
			labelFrom: from,
			labelTo: to,
			placeholderFrom: `${creationDate} ${from.toLowerCase()}`,
			placeholderTo: `${creationDate} ${to.toLowerCase()}`,
			nameFrom: 'gte',
			nameTo: 'lte',
			dateRange: {
				from: filters.data_creazione?.gte,
				to: filters.data_creazione?.lte
			},
			setDateRange: (value, name) => this.setDateRange(value, name)
		};

		const customInputs = [
			{ md: 3, lg: 2, label: documentID, id: 'id_documento', type: 'number' },
			{ md: 6, lg: 5, label: subject, id: 'nome', type: 'text' }
		];

		const dateInputs = [
			{ label: from, name: 'gte' },
			{ label: to, name: 'lte' }
		];

		const usersOptions = users.items?.map((user) => ({
			value: user.id_utente,
			label: user.utente
		}));

		const usersValue = usersOptions?.filter((user) => filters.firm_vist?.includes(user.value));

		return (
			<GridContainer style={{ width: '100%', margin: 'auto', display: 'flex', justifyContent: 'space-between' }}>
				{customInputs.map((input) => {
					const { md, lg, label, id, type } = input;
					return (
						<GridItem xs={12} sm={12} md={md} lg={lg} key={label} className={classes.noPad}>
							<CustomInput
								labelText={label}
								id={id}
								formControlProps={{ fullWidth: true }}
								inputProps={{
									onChange: this.onChange,
									type,
									value: filters[id] ?? '',
									onKeyPress: this.handleKeyPress
								}}
							/>
						</GridItem>
					);
				})}
				<GridItem
					md={4}
					lg={4}
					className={classes.noPad}
					style={{ marginRight: '5px', display: 'flex', alignItems: 'flex-end' }}
				>
					<DateRange {...dateRangeProps} />
				</GridItem>

				{ambito.name !== 'Signed' && (
					<GridItem xs={12} sm={12} md={4} lg={4} className={classes.noPad} style={{ marginTop: '0.5em' }}>
						<FormControl fullWidth>
							<Select
								id='f_v'
								name='firm_vist'
								styles={selectStyles}
								isMulti
								isSearchable
								label={signatoryViewer}
								placeholder={selectUsers}
								className='basic-multi-select'
								components={{ Control: ControlComponent }}
								onChange={this.handleSelect}
								onKeyDown={this.handleKeyPress}
								value={usersValue ?? []}
								options={usersOptions}
							/>
						</FormControl>
					</GridItem>
				)}
			</GridContainer>
		);
	}
}

const ControlComponent = (props) => {
	const { label } = props.selectProps;

	const labelStyle = {
		fontSize: '12px',
		color: '#AAA !important',
		top: '8px',
		padding: '0px'
	};

	return (
		<div style={{ marginTop: '1em' }}>
			{label && <label style={labelStyle}>{label}</label>}
			<components.Control {...props} />
		</div>
	);
};

const mapStateToProps = (state) => {
	const { utils, search, users } = state;
	return {
		utils,
		users,
		filters: search.filters,
		ambito: search.ambito
	};
};

const RicercaAvanzataConnected = connect(mapStateToProps)(
	withTranslation()(withSnackbar(withStyles(styles)(RicercaAvanzata)))
);
export default RicercaAvanzataConnected;
