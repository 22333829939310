//import config from 'config';
import { authHeader } from '../Helpers';

var wMount;
if (process.env.NODE_ENV === 'production') {
    wMount = process.env.REACT_APP_SERVICES_URL;
} else {
    wMount = process.env.REACT_APP_USR_URL;
}


export const profiloService = {
    getAll,
    deleteProfiloById,
    getProfiloById,
    saveProfilo

};


function getAll(filter) {

    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    let url = wMount + "/api/v1/profilo";

    return fetch(url, requestOptions)
        .then(handleResponse)
        .catch(function (err) {
            const error = err;
            return Promise.reject(error);
        });
}

function deleteProfiloById(idProfilo) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        data: { id: idProfilo }
    };

    let url = wMount + "/api/v1/profilo/" + idProfilo;

    return fetch(url, requestOptions).then(handleResponse);
}

function getProfiloById(idProfilo) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let url = wMount + "/api/v1/profilo/" + idProfilo;

    return fetch(url, requestOptions).then(handleResponse);

}

function saveProfilo(profilo) {

    let wMethod = "POST";
    let url = wMount + "/api/v1/profilo/";
    if (typeof (profilo.id_ruolo) !== "undefined") {
        wMethod = "PUT";
        url += profilo.id_ruolo;
    }

    profilo.id_ruolo = undefined;

    const requestOptions = {
        method: wMethod,
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(profilo)
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        else {
            if (data.Result.Code !== "E100") {
                //if (data.Result.Code === "E110") {
                //    history.push('/login');
                //}
                const error = (data && data.Result && data.Result.Message);
                return Promise.reject(error);
            }
            else if (typeof (data.Data) !== "undefined" && data.Data !== null && typeof (data.Data.errors) !== "undefined" && data.Data.errors.length !== 0) {
                let error = "";
                for (var i = 0; i < data.Data.errors.length; i++) {
                    error = data.Data.errors[i].error + "(" + data.Data.errors[i].id_documento + ")\n";
                }
                return Promise.reject(error);
            }
        }

        return data.Data;
    });
}


