import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Dashboard from "@material-ui/icons/Dashboard";
import Help from "@material-ui/icons/Help";

import { compose } from 'recompose'
import { withTranslation } from 'react-i18next';

// core components
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";
import LanguageSwitcher from "../../Custom/Utilities/LanguageSwitcher";

class HeaderLinks extends React.Component {
    state = {
        open: false
    };
    handleClick = () => {
        this.setState({ open: !this.state.open });
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    render() {
        const { t, classes, rtlActive } = this.props;
        
        const wrapper = classNames({
            [classes.wrapperRTL]: rtlActive
        });
        
        return (
            <div className={wrapper}>
                
                <NavLink to="/auth/dashboard" style={{ color: "#555555" }}>
                    <Tooltip title="Dashboard">
                        <Button
                            color="transparent"
                            simple
                            aria-label="Dashboard"
                            justIcon
                            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                            muiClasses={{
                                label: rtlActive ? classes.labelRTL : ""
                            }}
                        >
                            <Dashboard
                                className={
                                    classes.headerLinksSvg +
                                    " " +
                                    (rtlActive
                                        ? classes.links + " " + classes.linksRTL
                                        : classes.links)
                                }
                            />
                            <Hidden mdUp>
                                <span className={classes.linkText}>
                                    {rtlActive ? "لوحة القيادة" : "Dashboard"}
                                </span>
                            </Hidden>
                        </Button>
                    </Tooltip>   
                </NavLink>
                <NavLink to="/auth/edit-profile" style={{ color: "#555555" }}>
                    <Tooltip title={t('menu.userProfile')}>
                        <Button
                            color="transparent"
                            aria-label="Person"
                            justIcon
                            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
                            muiClasses={{
                                label: rtlActive ? classes.labelRTL : ""
                            }}
                        >
                            <Person
                                className={
                                    classes.headerLinksSvg +
                                    " " +
                                    (rtlActive
                                        ? classes.links + " " + classes.linksRTL
                                        : classes.links)
                                }
                            />
                            <Hidden mdUp>
                                <span className={classes.linkText}>
                                    {rtlActive ? "الملف الشخصي" : "Profile"}
                                </span>
                            </Hidden>
                        </Button>
                        </Tooltip>
                </NavLink>
                <Tooltip title={t('menu.usrManual')}>
                    <Button href="/serve/mu_tcsign.pdf"
                        color="transparent"
                        aria-label="Help"
                        justIcon
                        className={`${classes.title} ${rtlActive ? classes.buttonLinkRTL : classes.buttonLink}`}
                        muiClasses={{
                            label: rtlActive ? classes.labelRTL : ""
                        }}

                    >
                        <Help
                            className={
                                classes.headerLinksSvg +
                                " " +
                                (rtlActive
                                    ? classes.links + " " + classes.linksRTL
                                    : classes.links)
                            }
                        />
                        <Hidden mdUp>
                            <span className={classes.linkText}>
                                {rtlActive ? "Ø§Ù„Ù…Ù„Ù Ø§Ù„Ø´Ø®ØµÙŠ" : "Help"}
                            </span>
                        </Hidden>
                    </Button>
                </Tooltip>
                <LanguageSwitcher />
            </div>
        );
    }
}

HeaderLinks.propTypes = {
    classes: PropTypes.object.isRequired,
    rtlActive: PropTypes.bool
};

export default compose(withStyles(headerLinksStyle), withTranslation())(HeaderLinks);
