import { documentiConstants } from "Constants";
import { alertActions } from "Actions";
import { documentiService, workflowService } from "Services";
import { raccoglitoriActions } from "./raccoglitori.actions";
import { posFirmaActions } from "./posFirma.actions";

export const documentiActions = {
    getAll,
    getDocumentoById,
    getDocForExternal,
    getTaskList,
    resetDocument,
    save,
    addNota,
    deleteNota,
    deleteById,
    eseguiAzione,
    upload,
    download,
    downloadExt,
    downloadFromQRCode,
    impostaAttenzionato,
    getTaskCount,
    addStep,
    deleteStepById,
    deleteFile,
    search,
    rifiutaDocumento,
    updateMetadata,
    sendSollecito,
    copiaDocumento,
    updateSendProtocollo,
    addDelegante,
    impostaVerificato,
    resetDelegante,
    impostaDocToView,
    getSessionId,
    getMeetings,
    getMeetingDetails,
    getTopicDetails,
    clearAssociatedMeeting,
    emptyItems,
    inviaOTP,
    verificaOtp,
    eseguiAzioneExt,
    rifiutaDocumentoExt,
    uploadFirmaDigitale,
    updateInsertCoord,
    otpRequest,
    bloccaProcesso,
    multipleDocsInspection,
};

function multipleDocsInspection(docsList) {
    return (dispatch) => {
        dispatch(request());
        documentiService.multipleDocsInspection(docsList).then(
            () => {
                dispatch(documentiActions.getTaskList());
                dispatch(success());
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.MULITPLE_DOC_INSPECTION_REQUEST };
    }
    function success() {
        return { type: documentiConstants.MULITPLE_DOC_INSPECTION_SUCCESS };
    }
    function failure(error) {
        return { type: documentiConstants.MULITPLE_DOC_INSPECTION_FAILURE, error };
    }
}

function downloadFromQRCode(idDocumento, username, password) {
    return (dispatch) => {
        dispatch(request());
        documentiService.downloadFromQRCode(idDocumento, username, password).then(
            () => dispatch(success()),
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DOWNLOAD_DOC_QRC_REQUEST };
    }
    function success(file) {
        return { type: documentiConstants.DOWNLOAD_DOC_QRC_SUCCESS, file };
    }
    function failure(error) {
        return { type: documentiConstants.DOWNLOAD_DOC_QRC_FAILURE, error };
    }
}

function updateInsertCoord(coord, docID) {
    return (dispatch) => {
        dispatch(request());

        documentiService.updateInsertCoord(coord).then(
            (result) => {
                dispatch(success(result));
                dispatch(documentiActions.getDocumentoById(docID));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.UPDATE_INSERT_COORD };
    }
    function success(result) {
        return { type: documentiConstants.UPDATE_INSERT_COORD_SUCCESS, result };
    }
    function failure(error) {
        return { type: documentiConstants.UPDATE_INSERT_COORD_FAILURE, error };
    }
}

function uploadFirmaDigitale(documento, hash_code) {
    return (dispatch) => {
        dispatch(request());
        documentiService.uploadFirmaDigitale(documento, hash_code).then(
            () => {
                dispatch(success());
                dispatch(raccoglitoriActions.recuperaDocumento(hash_code));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.UPLOAD_FIRMA_DIGITALE };
    }
    function success() {
        return { type: documentiConstants.UPLOAD_FIRMA_DIGITALE_SUCCESS };
    }
    function failure(error) {
        return { type: documentiConstants.UPLOAD_FIRMA_DIGITALE_FAILURE, error };
    }
}

function inviaOTP(hash_code) {
    return (dispatch) => {
        dispatch(request());
        documentiService.inviaOTP(hash_code).then(
            (result) => {
                dispatch(success(result));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.GET_OTP_REQUEST };
    }
    function success(result) {
        return { type: documentiConstants.GET_OTP_SUCCESS, result };
    }
    function failure(error) {
        return { type: documentiConstants.GET_OTP_FAILURE, error };
    }
}

function verificaOtp(otp) {
    return (dispatch) => {
        dispatch(request());
        documentiService.verificaOtp(otp).then(
            (result) => {
                dispatch(success(result));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.GET_VERIFY_OTP_REQUEST };
    }
    function success(result) {
        return { type: documentiConstants.GET_VERIFY_OTP_SUCCESS, result };
    }
    function failure(error) {
        return { type: documentiConstants.GET_VERIFY_OTP_FAILURE, error };
    }
}

function resetDocument() {
    return { type: documentiConstants.RESET_DOCUMENT };
}

function resetDelegante() {
    return { type: documentiConstants.RESET_DELEGANTE };
}

function rifiutaDocumento(json, ruoli) {
    return (dispatch) => {
        dispatch(request());

        let wNota = {
            id_documento: json.id_documento[0],
            descrizione: json.nota_rifiuto,
        };

        documentiService.addNota(wNota).then(
            (nota) => {
                documentiService.eseguiAzione(json).then(
                    (documento) => {
                        dispatch(documentiActions.getAll(undefined, ruoli));
                        dispatch(documentiActions.getTaskList());
                        dispatch(success(documento));
                        dispatch(alertActions.success("Operazione eseguita con successo"));
                    },
                    (error) => {
                        dispatch(failure(error.toString()));
                        dispatch(alertActions.error(error.toString()));
                    }
                );
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.WORKFLOW_ACTION_REQUEST };
    }
    function success(file) {
        return { type: documentiConstants.WORKFLOW_ACTION_SUCCESS, file };
    }
    function failure(error) {
        return { type: documentiConstants.WORKFLOW_ACTION_FAILURE, error };
    }
}

function download(idDocumento, documento) {
    return (dispatch) => {
        dispatch(request());

        documentiService.download(idDocumento, documento).then(
            () => dispatch(success()),
            (error) => {
                console.log("error", error);
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DOWNLOAD_DOCUMENT_REQUEST };
    }
    function success(file) {
        return { type: documentiConstants.DOWNLOAD_DOCUMENT_SUCCESS, file };
    }
    function failure(error) {
        return { type: documentiConstants.DOWNLOAD_DOCUMENT_FAILURE, error };
    }
}

function downloadExt(hash_code, uuid) {
    return (dispatch) => {
        dispatch(request());

        documentiService.downloadExt(hash_code, uuid).then(
            (file) => {
                dispatch(success(file));
                //dispatch(documentiActions.getAll());
                //dispatch(documentiActions.getTaskList());
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DOWNLOAD_DOCUMENT_REQUEST };
    }
    function success(file) {
        return { type: documentiConstants.DOWNLOAD_DOCUMENT_SUCCESS, file };
    }
    function failure(error) {
        return { type: documentiConstants.DOWNLOAD_DOCUMENT_FAILURE, error };
    }
}

function deleteFile(idDocumento, uuid) {
    return (dispatch) => {
        dispatch(request());

        documentiService.deleteFile(uuid, idDocumento).then(
            (result) => {
                dispatch(success(result));
                dispatch(documentiActions.getDocumentoById(idDocumento, true));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DELETE_FILE_REQUEST };
    }
    function success(idDocumento) {
        return { type: documentiConstants.DELETE_FILE_SUCCESS, idDocumento };
    }
    function failure(error) {
        return { type: documentiConstants.DELETE_FILE_FAILURE, error };
    }
}

function updateSendProtocollo(idDocumento, uuid, send_protocollo) {
    return (dispatch) => {
        dispatch(request());

        documentiService.updateSendProtocollo(uuid, send_protocollo).then(
            (result) => {
                dispatch(success());
                dispatch(documentiActions.getDocumentoById(idDocumento));
                //dispatch(documentiActions.getAll());
                //dispatch(documentiActions.getTaskList());
                //dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.SEND_PROTOCOLLO_FILE_REQUEST };
    }
    function success() {
        return { type: documentiConstants.SEND_PROTOCOLLO_FILE_SUCCESS };
    }
    function failure(error) {
        return { type: documentiConstants.SEND_PROTOCOLLO_FILE_FAILURE, error };
    }
}

function upload(documento, ruoli) {
    return (dispatch) => {
        dispatch(request());

        const wIdDocumento = documento.id;

        documentiService.upload(documento).then(
            (documento) => {
                dispatch(success(documento));
                dispatch(documentiActions.getAll(undefined, ruoli));
                dispatch(documentiActions.getDocumentoById(wIdDocumento, true));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.UPLOAD_DOCUMENT_REQUEST };
    }
    function success(documento) {
        return { type: documentiConstants.UPLOAD_DOCUMENT_SUCCESS, documento };
    }
    function failure(error) {
        return { type: documentiConstants.UPLOAD_DOCUMENT_FAILURE, error };
    }
}

function getAll(filter, ruoli) {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(request());

        documentiService.getAll(filter, state.documenti.deleganteSelected.id_delegante, ruoli).then(
            (documents) => {
                dispatch(success(documents));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.GETALL_REQUEST };
    }
    function success(documents) {
        return { type: documentiConstants.GETALL_SUCCESS, documents };
    }
    function failure(error) {
        return { type: documentiConstants.GETALL_FAILURE, error };
    }
}

function search(filters, ruoli) {
    return (dispatch, getState) => {
        const state = getState();

        dispatch(request());
        documentiService.search(filters, state.documenti.deleganteSelected.id_delegante, ruoli).then(
            (documents) => dispatch(success(documents)),
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.GETALL_REQUEST };
    }
    function success(documents) {
        return { type: documentiConstants.GETALL_SUCCESS, documents };
    }
    function failure(error) {
        return { type: documentiConstants.GETALL_FAILURE, error };
    }
}

function getTaskCount(idStatoFlusso) {
    return (dispatch) => {
        dispatch(request());

        documentiService.getTaskCount(idStatoFlusso).then(
            (taskCount) => dispatch(success({ idStato: idStatoFlusso, count: taskCount.count })),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: documentiConstants.GETTASK_COUNT_REQUEST };
    }
    function success(taskCount) {
        return { type: documentiConstants.GETTASK_COUNT_SUCCESS, taskCount };
    }
    function failure(error) {
        return { type: documentiConstants.GETTASK_COUNT_FAILURE, error };
    }
}

function addDelegante(idDelegante, nomeDelegante, tipoDelega) {
    var delegante = {
        id_delegante: idDelegante,
        nome_delegante: nomeDelegante,
        tipo_delega: tipoDelega,
    };
    return { type: documentiConstants.ADD_DELEGANTE, delegante };
}

function getTaskList(idStato, isVerificati, cleanCurrent) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(request());

        documentiService.getTaskList(idStato, state.documenti.deleganteSelected, isVerificati).then(
            (tasks) => {
                if (cleanCurrent) successNoClean(success(tasks));
                else dispatch(success(tasks));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.GETTASKLIST_REQUEST };
    }
    function success(tasks) {
        return { type: documentiConstants.GETTASKLIST_SUCCESS, tasks };
    }
    function successNoClean(tasks) {
        return { type: documentiConstants.GETTASKLIST_SUCCESS_NO_CLEAN, tasks };
    }
    function failure(error) {
        return { type: documentiConstants.GETTASKLIST_FAILURE, error };
    }
}

function getDocumentoById(idDocumento, isUpload) {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(request());

        documentiService.getDocumentoById(idDocumento, state.documenti.deleganteSelected.id_delegante).then(
            (documento) => {
                if (documento.id_raccoglitore && !isUpload)
                    dispatch(raccoglitoriActions.getRaccoglitoreById(documento.id_raccoglitore));
                dispatch(success(documento));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.GETDOCUMENT_REQUEST };
    }
    function success(documento) {
        return { type: documentiConstants.GETDOCUMENT_SUCCESS, documento };
    }
    function failure(error) {
        return { type: documentiConstants.GETDOCUMENT_FAILURE, error };
    }
}

function getDocForExternal(inputParams) {
    return (dispatch) => {
        dispatch(request());
        documentiService.getDocForExternal(inputParams).then(
            (result) => dispatch(success(result)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: documentiConstants.GET_DOC_FOR_EXTERNAL_REQUEST };
    }
    function success(result) {
        return { type: documentiConstants.GET_DOC_FOR_EXTERNAL_SUCCESS, result };
    }
    function failure(error) {
        return { type: documentiConstants.GET_DOC_FOR_EXTERNAL_FAILURE, error };
    }
}

function deleteById(idDocumento, ruoli) {
    return (dispatch) => {
        dispatch(request());

        documentiService.deleteById(idDocumento).then(
            (documento) => {
                dispatch(success(documento));
                //dispatch(documentiActions.getDocumentoById(documento));
                dispatch(documentiActions.getAll(undefined, ruoli));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DELETE_DOCUMENT_REQUEST };
    }
    function success(documento) {
        return { type: documentiConstants.DELETE_DOCUMENT_SUCCESS, documento };
    }
    function failure(error) {
        return { type: documentiConstants.DELETE_DOCUMENT_FAILURE, error };
    }
}

function addNota(nota) {
    return (dispatch) => {
        dispatch(request());

        documentiService.addNota(nota).then(
            (nota) => {
                dispatch(success(nota));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.SAVE_NOTA_REQUEST };
    }
    function success(nota) {
        return { type: documentiConstants.SAVE_NOTA_SUCCESS, nota };
    }
    function failure(error) {
        return { type: documentiConstants.SAVE_NOTA_FAILURE, error };
    }
}

function deleteNota(nota) {
    return (dispatch) => {
        dispatch(request());

        documentiService.deleteNota(nota).then(
            (documento) => {
                dispatch(success(documento));
                dispatch(documentiActions.getDocumentoById(nota.id_documento));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DELETE_NOTA_REQUEST };
    }
    function success(nota) {
        return { type: documentiConstants.DELETE_NOTA_SUCCESS, nota };
    }
    function failure(error) {
        return { type: documentiConstants.DELETE_NOTA_FAILURE, error };
    }
}

function addStep(stepFlusso) {
    return (dispatch) => {
        dispatch(request());

        workflowService.addStep(stepFlusso).then(
            (flusso) => {
                dispatch(success());
                dispatch(documentiActions.getDocumentoById(stepFlusso.id_documento));
            },
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: documentiConstants.SAVE_FLUSSO_REQUEST };
    }
    function success() {
        return { type: documentiConstants.SAVE_FLUSSO_SUCCESS };
    }
    function failure(error) {
        return { type: documentiConstants.SAVE_FLUSSO_FAILURE, error };
    }
}

function deleteStepById(idDocumento, idFlusso) {
    return (dispatch) => {
        dispatch(request());

        workflowService.deleteStepById(idFlusso).then(
            (flusso) => {
                dispatch(posFirmaActions.setPosizionamentoFirmaTagsFetched(false));
                dispatch(documentiActions.getDocumentoById(idDocumento));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DELETE_FLUSSO_REQUEST };
    }
    function success(flusso) {
        return { type: documentiConstants.DELETE_FLUSSO_SUCCESS, flusso };
    }
    function failure(error) {
        return { type: documentiConstants.DELETE_FLUSSO_FAILURE, error };
    }
}

function save(documento, coordinate, ruoli) {
    return (dispatch) => {
        dispatch(request());

        documentiService.save(documento, coordinate).then(
            (docIDObj) => {
                dispatch(success(documento));
                dispatch(documentiActions.getAll(undefined, ruoli));
                dispatch(documentiActions.getDocumentoById(docIDObj.id_documento));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.SAVEDOCUMENT_REQUEST };
    }
    function success(documento) {
        return { type: documentiConstants.SAVEDOCUMENT_SUCCESS, documento };
    }
    function failure(error) {
        return { type: documentiConstants.SAVEDOCUMENT_FAILURE, error };
    }
}

function copiaDocumento(idDoc) {
    return (dispatch) => {
        dispatch(request());

        documentiService.copiaDocumento(idDoc).then(
            (documento) => {
                dispatch(success(documento));
                //dispatch(documentiActions.getAll());
                //dispatch(documentiActions.getDocumentoById(documento.id_documento));
                //dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.COPYDOCUMENT_REQUEST };
    }
    function success(documento) {
        return { type: documentiConstants.COPYDOCUMENT_SUCCESS, documento };
    }
    function failure(error) {
        return { type: documentiConstants.COPYDOCUMENT_FAILURE, error };
    }
}

function updateMetadata(idDocumento, metadata, fromDashboard) {
    return (dispatch) => {
        dispatch(request());

        documentiService.updateMetadata(idDocumento, metadata).then(
            (esito) => {
                dispatch(success(esito));
                if (fromDashboard) dispatch(documentiActions.getTaskList());
                else dispatch(documentiActions.getDocumentoById(idDocumento));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.UPDATEMETADATA_REQUEST };
    }
    function success(esito) {
        return { type: documentiConstants.UPDATEMETADATA_SUCCESS, esito };
    }
    function failure(error) {
        return { type: documentiConstants.UPDATEMETADATA_FAILURE, error };
    }
}

function sendSollecito(id_documento, id_utente, id_uo, messaggio, id_contatto, lang) {
    return (dispatch) => {
        dispatch(request());

        documentiService.sendSollecito(id_documento, id_utente, id_uo, messaggio, id_contatto, lang).then(
            (esito) => {
                dispatch(success(esito));
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.SOLLECITO_REQUEST };
    }
    function success(esito) {
        return { type: documentiConstants.SOLLECITO_SUCCESS, esito };
    }
    function failure(error) {
        return { type: documentiConstants.SOLLECITO_FAILURE, error };
    }
}

function eseguiAzione(azione, idStato, isVerificati, ruoli, notClean) {
    return (dispatch) => {
        dispatch(request());

        documentiService.eseguiAzione(azione).then(
            (documento) => {
                dispatch(documentiActions.getAll(undefined, ruoli));
                dispatch(documentiActions.getTaskList(idStato, isVerificati, notClean));
                if (notClean) dispatch(documentiActions.getDocumentoById(azione.id_documento));
                dispatch(success(documento));
                if (documento && documento.errorList && documento.errorList.length > 0)
                    dispatch(
                        alertActions.warning(
                            "Il provider di firma non è riuscito a firmare tutti i documenti selezionati, riprovare più tardi"
                        )
                    );
                else dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                if (error.toString().startsWith("$$"))
                    dispatch(alertActions.success("Operazione eseguita con successo"));
                else dispatch(alertActions.error(error.toString()));

                dispatch(failure(error.toString()));
                dispatch(documentiActions.getTaskList());
            }
        );
    };

    function request() {
        return { type: documentiConstants.WORKFLOW_ACTION_REQUEST };
    }
    function success(documento) {
        return { type: documentiConstants.WORKFLOW_ACTION_SUCCESS, documento };
    }
    function failure(error) {
        return { type: documentiConstants.WORKFLOW_ACTION_FAILURE, error };
    }
}

function eseguiAzioneExt(file, hash_code) {
    return (dispatch) => {
        dispatch(request());
        documentiService.eseguiAzioneExt(file, hash_code).then(
            () => {
                localStorage.removeItem("signature");
                dispatch(raccoglitoriActions.recuperaDocumento(hash_code));
                dispatch(success());
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.WORKFLOW_EXT_ACTION_REQUEST };
    }
    function success() {
        return { type: documentiConstants.WORKFLOW_EXT_ACTION_SUCCESS };
    }
    function failure(error) {
        return { type: documentiConstants.WORKFLOW_EXT_ACTION_FAILURE, error };
    }
}

function bloccaProcesso(azione, idStato, isVerificati, ruoli) {
    return eseguiAzione(azione, idStato, isVerificati, ruoli, true);
}

function rifiutaDocumentoExt(value, hash_code) {
    return (dispatch) => {
        dispatch(request());

        let wNota = {
            id_documento: value.id_documento[0],
            descrizione: value.nota_rifiuto,
            id_contatto: value.id_contatto,
            utente_ult_modif: value.nome_contatto,
        };
        documentiService.addNotaExt(wNota).then(
            () => {
                dispatch(documentiActions.eseguiAzioneExt(value, hash_code));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.WORKFLOW_ACTION_REQUEST };
    }
    function failure(error) {
        return { type: documentiConstants.WORKFLOW_ACTION_FAILURE, error };
    }
}

function impostaAttenzionato(valore) {
    return (dispatch) => {
        dispatch(request());

        documentiService.impostaAttenzionato(valore).then(
            (documento) => {
                dispatch(success(documento));
                dispatch(documentiActions.getTaskList());
                //dispatch(documentiActions.getTaskCount(0));
            },
            (error) => {
                dispatch(alertActions.error(error.toString()));
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DOCUMENT_FAVORITE_REQUEST };
    }
    function success(documento) {
        return { type: documentiConstants.DOCUMENT_FAVORITE_SUCCESS, documento };
    }
    function failure(error) {
        return { type: documentiConstants.DOCUMENT_FAVORITE_FAILURE, error };
    }
}

function impostaVerificato(valore) {
    return (dispatch) => {
        dispatch(request());

        documentiService.impostaVerificato(valore).then(
            (documento) => {
                dispatch(success(documento));
                dispatch(documentiActions.getTaskList());
                //dispatch(documentiActions.getTaskCount(0));
            },
            (error) => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.DOCUMENT_VERIFY_REQUEST };
    }
    function success(documento) {
        return { type: documentiConstants.DOCUMENT_VERIFY_SUCCESS, documento };
    }
    function failure(error) {
        return { type: documentiConstants.DOCUMENT_VERIFY_FAILURE, error };
    }
}

function impostaDocToView(documento) {
    return { type: documentiConstants.CHANGE_DOCUMENT_SUCCESS, documento };
}

function getSessionId() {
    return (dispatch) => {
        dispatch(request());
        documentiService.getSessionId().then(
            (sessionId) => {
                dispatch(success(sessionId));
                dispatch(documentiActions.getMeetings(sessionId.session.session_id));
            },
            (error) => {
                dispatch(alertActions.error("Il sistema Conclium non è al momento raggiungibile"));
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.SESSION_ID_REQUEST };
    }
    function success(sessionId) {
        return { type: documentiConstants.SESSION_ID_SUCCESS, sessionId };
    }
    function failure(error) {
        return { type: documentiConstants.SESSION_ID_FAILURE, error };
    }
}

function getMeetings(sessionId) {
    return (dispatch) => {
        dispatch(request());
        documentiService.getMeetings(sessionId).then(
            (riunioni) => {
                dispatch(success(riunioni));
            },
            (error) => {
                dispatch(alertActions.error("Il sistema Conclium non è al momento raggiungibile"));
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.MEETING_REQUEST };
    }
    function success(riunioni) {
        return { type: documentiConstants.MEETING_SUCCESS, riunioni };
    }
    function failure(error) {
        return { type: documentiConstants.MEETING_FAILURE, error };
    }
}

function getMeetingDetails(sessionId, idRiunione, idArgomento) {
    return (dispatch) => {
        dispatch(request());
        documentiService.getMeetingDetails(sessionId, idRiunione).then(
            (dettaglio) => {
                dispatch(success(dettaglio));
                if (idArgomento) dispatch(documentiActions.getTopicDetails(idArgomento, dettaglio.topics));
            },
            (error) => {
                dispatch(alertActions.error("Il sistema Conclium non è al momento raggiungibile"));
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.MEETING_DETAILS_REQUEST };
    }
    function success(dettaglio) {
        return { type: documentiConstants.MEETING_DETAILS_SUCCESS, dettaglio };
    }
    function failure(error) {
        return { type: documentiConstants.MEETING_DETAILS_FAILURE, error };
    }
}

function getTopicDetails(idArgomento, elencoArgomenti) {
    return (dispatch) => {
        let dettaglio = getArgomento(idArgomento, elencoArgomenti);
        dispatch(success(dettaglio));
    };
    function success(dettaglio) {
        return { type: documentiConstants.TOPIC_DETAILS_SUCCESS, dettaglio };
    }
}

function clearAssociatedMeeting() {
    return { type: documentiConstants.CLEAR_MEETING_DETAILS };
}
function emptyItems() {
    return { type: documentiConstants.EMPTY_ITEMS_SUCCESS };
}

function getArgomento(idArgomento, elencoArgomenti) {
    let dettaglio;
    if (elencoArgomenti) {
        elencoArgomenti.forEach((arg) => {
            if (arg.id === idArgomento) {
                dettaglio = arg;
            }
        });
    }
    return dettaglio;
}

function otpRequest(credentials) {
    return (dispatch) => {
        dispatch(request());
        documentiService.otpRequest(credentials).then(
            () => {
                dispatch(success());
                dispatch(alertActions.success("Operazione eseguita con successo"));
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: documentiConstants.OTP_REQUEST };
    }
    function success() {
        return { type: documentiConstants.OTP_SUCCESS };
    }
    function failure(error) {
        return { type: documentiConstants.OTP_FAILURE, error };
    }
}
