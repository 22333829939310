import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { userActions, temaActions } from '../../Actions';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import { alertActions } from '../../Actions';
import CardIcon from 'components/Card/CardIcon.jsx';
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next';

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx';

import FormValidationHelper from '../../Helpers/form-validation.js';

import SweetAlert from 'react-bootstrap-sweetalert';

/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { ClipLoader } from 'react-spinners';
import { ThemeUtils } from 'Helpers/themeUtils.js';

const loginPageCustomStyle = {
    ...loginPageStyle,
};

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
`;

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // login form
            loginUsername: '',
            loginUsernameState: '',
            loginPassword: '',
            loginPasswordState: '',
            // type validation
            submitted: false,

            isLoading: false,

            alertMessage: null,
            show: false,
            tenantName: localStorage.getItem('theme_tenant'),
        };
        this.loginClick = this.loginClick.bind(this);
        this.basicAlert = this.basicAlert.bind(this);
    }

    componentDidMount() {
        // we add a hidden class to the card and after 700 ms we delete it and the transition appears
        const { tenantName } = this.state;
        this.props.dispatch(userActions.getTenantByName(tenantName));
        this.props.dispatch(temaActions.getTemaTenant(tenantName));

        setTimeout(
            function () {
                this.setState({ cardAnimaton: '' });
            }.bind(this),
            700
        );
    }

    basicAlert(pMessage) {
        const { t } = this.props;
        this.setState({
            alertMessage: (
                <SweetAlert danger title={t('alert.warning')} onConfirm={() => this.hideAlert()}>
                    {pMessage}
                </SweetAlert>
            ),
            submitted: false,
        });
    }

    hideAlert() {
        this.props.dispatch(alertActions.clear());
        this.setState({
            alertMessage: null,
        });
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        const { id, value } = event.target;
        this.setState({ [id]: value });

        switch (type) {
            case 'username':
                this.setState({
                    [stateName + 'State']: FormValidationHelper.validateField('required', event.target.value, 1),
                });
                break;
            case 'password':
                this.setState({
                    [stateName + 'State']: FormValidationHelper.validateField('required', event.target.value, 1),
                });
                break;
            default:
                break;
        }
    }

    loginClick() {
        const { loginUsername, loginPassword } = this.state;
        const { dispatch, users } = this.props;

        var wLoginUsernameState = FormValidationHelper.validateField('required', loginUsername, 1);
        this.setState({
            loginUsernameState: wLoginUsernameState,
        });

        var wLoginPasswordState = FormValidationHelper.validateField('required', loginPassword, 1);
        this.setState({
            loginPasswordState: wLoginPasswordState,
        });

        let wLoginTenantState = 'success';
        let tenant = users.company ? users.company : undefined;
        if (!tenant) {
            wLoginTenantState = 'error';
        }

        if (wLoginUsernameState === 'error' || wLoginPasswordState === 'error' || wLoginTenantState === 'error') {
            return;
        }

        this.setState({ submitted: true });
        let userNameCompany = loginUsername + '@' + tenant.id_company;
        dispatch(userActions.login(userNameCompany, loginPassword, tenant, this.props.history));
    }

    getIdCompany(tenantTheme) {
        let result;
        if (tenantTheme)
            tenantTheme.forEach((el) => {
                if (el.id_company) result = el.id_company;
            });
        return result;
    }

    render() {
        const { t, alert, authentication, users} = this.props;
        const { submitted, alertMessage } = this.state;
        const { classes } = this.props;

        let tenantTheme = users.tenant_theme;
        let logo = undefined;
        let background = undefined;
        let cardColor = undefined;
        let pageBody = '';

        if (tenantTheme) {
            logo = ThemeUtils.getPngSrc(ThemeUtils.getThemeImage(tenantTheme, 'logo_png_2', 'img'));
            background = ThemeUtils.getJpegSrc(ThemeUtils.getThemeImage(tenantTheme, 'background_login_jpg', 'img'));
            background = "url('" + background.replace(/(\r\n|\n|\r)/gm, '') + "')";
            cardColor = ThemeUtils.getThemeColor(tenantTheme, 'tcsign_color', 'card_color');

            let styles = {
                background: {
                    backgroundImage: background,
                    backgroundSize: 'cover',
                    borderRadius: '6px',
                    boxShadow: 'rgb(33, 33, 33) 0px 1px 4px 0px',
                },
            };
            pageBody = (
                <GridContainer justify="center" style={styles.background}>
                    <GridItem xs={12} sm={6} md={4}>
                        <Card
                            style={{
                                backgroundColor: '#eeeeee',
                            }}>
                            <CardHeader color={cardColor} icon>
                                <CardIcon className="backgroundIconLogin" color={cardColor}>
                                    <img
                                        alt="Teleconsys"
                                        src={logo}
                                        style={{ width: '90px' }}
                                    />
                                </CardIcon>
                                <h4 style={{ color: '#AAAAAA' }} className={classes.cardIconTitle}>
                                    {t('login.title')}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <form>
                                    <CustomInput
                                        success={this.state.loginUsernameState === 'success'}
                                        error={this.state.loginUsernameState === 'error'}
                                        labelText={t('login.username')}
                                        id="loginUsername"
                                        autocomplete="off"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => this.change(event, 'loginUsername', 'username'),
                                            type: 'username',
                                        }}
                                    />
                                    <CustomInput
                                        success={this.state.loginPasswordState === 'success'}
                                        error={this.state.loginPasswordState === 'error'}
                                        labelText={t('login.password')}
                                        id="loginPassword"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            onChange: (event) => this.change(event, 'loginPassword', 'password'),
                                            type: 'password',
                                        }}
                                    />
                                </form>
                            </CardBody>
                            <CardFooter className={classes.justifyContentCenter}>
                                <div className={classes.center}>
                                    <Button color={cardColor} onClick={this.loginClick}>
                                        Login
                                    </Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            );
        }

        if (alert && alert.message && submitted && authentication && !authentication.loggingIn) {
            this.basicAlert(alert.message);
        }

        let loading;
        if (authentication && authentication.loggingIn) {
            loading = (
                <div css={overlay}>
                    <div css={override}>
                        <ClipLoader
                            sizeUnit={'px'}
                            size={80}
                            color={'#3a783d'}
                            loading={authentication.loggingIn}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className={classes.content}>
                <div className={classes.container}>
                    {loading}
                    {alertMessage}
                    {pageBody}
                </div>
            </div>
        );
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const { authentication, alert, users } = state;

    return {
        authentication,
        alert,
        users,
    };
}

const connectedLoginPage = compose(connect(mapStateToProps), withStyles(loginPageCustomStyle), withTranslation())(LoginPage);

export default connectedLoginPage;
