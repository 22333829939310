let wMount;
if (process.env.NODE_ENV === 'production') {
    wMount = process.env.REACT_APP_SERVICES_URL;
} else {
    wMount = process.env.REACT_APP_USR_URL;
}

export const temaService = {
    getTemaTenant,
    getTemaByIdDoc,
};

function getTemaByIdDoc(idDoc) {
    let wMount;

    process.env.NODE_ENV === 'production'
        ? (wMount = process.env.REACT_APP_SERVICES_URL)
        : (wMount = process.env.REACT_APP_DOC_URL);

    let url = wMount + '/api/v1/qrcode/getThemeByIdDoc?idDocumento=' + idDoc;
    const requestOptions = {
        method: 'GET',
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function getTemaTenant(code) {
    let wMethod = 'GET';
    let url = wMount + '/api/v1/utente/tema/' + code;

    const requestOptions = {
        method: wMethod,
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then((text) => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                this.props.history.push('/login');
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        } else {
            if (data.Result.Code !== 'E100') {
                if (data.Result.Code === 'E401') {
                    this.props.history.push('/login');
                }
                const error = data && data.Result && data.Result.Message;
                return Promise.reject(error);
            }
        }

        return data.Data;
    });
}
