import React, { useCallback, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { OVERALL, getTypesOptions } from '../Constants';

import { analyticsActions } from '../../../../Actions';

const { COLORS_MAPPING, StatoDocumento } = OVERALL;

const Chart = ({ data, timeframe }) => {
  const { t } = useTranslation();
  const { document, documents } = t('analytics', { returnObjects: true }).charts;
  const { documentsType } = t('analytics', { returnObjects: true }).filtersLabels;
  const states = t('analytics.states');
  const all = t('document.all');

  const dispatch = useDispatch();
  const documentiTypes = useSelector((state) => state.utils.tipologia_documento);
  const tipo_documento = useSelector((state) => state.analytics.overall.options.tipo_documento);
  const language = useSelector((state) => state.settings.language);

  const overallRefCB = useCallback((el) => {
    if (el)
      dispatch(
        analyticsActions.setChartRef({
          chartName: 'overall',
          ref: el.current,
        })
      );
  }, []);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={10} y={0} dy={25} tfill="#999">
          {t('research.from')}{' '}
          <tspan fontWeight={600}>{moment(timeframe.from).format('DD/MM/YYYY')}</tspan>{' '}
          {t('research.to')}{' '}
          <tspan fontWeight={600}>{moment(timeframe.to).format('DD/MM/YYYY')}</tspan>
        </text>
        <text x={10} y={30} dy={20} tfill="#999">
          {documentsType}: <tspan fontWeight={600}>{documentTypeLabel}</tspan>
        </text>

        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          fontWeight={500}
        >{`${payload.label}`}</text>

        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor}>{`${value} ${
          value > 1 ? documents : document
        }`}</text>

        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={38} textAnchor={textAnchor} fill="#999">
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const documentTypeLabel = useMemo(
    () =>
      getTypesOptions(documentiTypes, all).filter(({ value }) => value === tipo_documento)[0].label,
    [documentiTypes, tipo_documento, language, all]
  );

  return (
    <div style={{ width: 600, height: 450 }}>
      <ResponsiveContainer ref={overallRefCB} width={'100%'} height={'100%'}>
        <PieChart>
          <Pie
            activeIndex={Object.keys(StatoDocumento(states)).map((_, index) => index)}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={100}
            dataKey="value"
          >
            {data.map(({ id_stato_documento }, index) => (
              <Cell key={`cell-${index}`} fill={COLORS_MAPPING[id_stato_documento]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default memo(Chart);
