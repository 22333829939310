import { Tooltip, withStyles } from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const styles = {
  ...extendedFormsStyle,
  tcsign_action: {
    width: '20px',
    minWidth: '20px',
    paddingLeft: 0,
    paddingRight: 0,
  },
};

const ActionBtn = ({ title, show, btnColor, btnDisabled, onClick, icon, classes }) => {
  return (
    show && (
      <Tooltip
        id="tooltip-top"
        title={title}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button
          justIcon
          round
          simple
          disabled={btnDisabled}
          onClick={onClick}
          color={btnColor}
          className={classes.tcsign_action}
        >
          {icon}
        </Button>
      </Tooltip>
    )
  );
};

export default withStyles(styles)(ActionBtn);
