import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ThemeUtils } from '../../../../Helpers/themeUtils';

import { withStyles, TextField } from '@material-ui/core';
import InsertChart from '@material-ui/icons/InsertChart';

import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { STYLES, getTypesOptions } from '../Constants';

const styles = {
  ...extendedFormsStyle,
  ...STYLES,
  tcsign_card: {
    'margin-top': '0px',
    'margin-bottom': '1em',
  },
  tcsign_cardbody: { display: 'flex', gap: '20px', padding: '20px 15px' },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

const AnalysisFilters = ({ classes }) => {
  const { t } = useTranslation();
  const { documentsType, documentsState } = t('analytics.filtersLabels', { returnObjects: true });
  const all = t('document.all');

  const tenantTheme = useSelector((state) => state.tema.tenant_theme);
  const temaColor = ThemeUtils.getThemeColor(tenantTheme, 'tcsign_color', 'card_color');
  const documentiTypes = useSelector((state) => state.utils.tipologia_documento);
  const { timeframe, user, tipo_documento } = useSelector(
    ({ analytics }) => analytics.docsDetails.filters
  );
  const scope = useSelector(({ analytics }) => analytics.docsDetails.scope);
  const filteredUsers = useSelector(({ analytics }) => analytics.filteredUsers);

  const typesOptions = useMemo(() => getTypesOptions(documentiTypes, all), [all]);

  const filters = [
    {
      show: true,
      label: documentsState,
      value: scope,
    },
    {
      show: true,
      label: t('research.from'),
      value: moment(timeframe.from).format('DD/MM/YYYY'),
    },
    {
      show: true,
      label: t('research.to'),
      value: moment(timeframe.to).format('DD/MM/YYYY'),
    },
    {
      show: true,
      label: documentsType,
      value: typesOptions.filter(({ value }) => value === tipo_documento)[0].label,
    },
    {
      show: user,
      label: t('document.labelUser'),
      value: filteredUsers.filter(({ value }) => value === user)[0]?.label,
    },
  ];

  return (
    <GridItem xs={12}>
      <Card className={classes.tcsign_card}>
        <CardHeader color={temaColor} icon>
          <CardIcon color={temaColor}>
            <InsertChart />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>{t('analytics.analysisFilters')}</h4>
        </CardHeader>
        <CardBody className={classes.tcsign_cardbody}>
          {filters.map(
            ({ show, label, value }) =>
              show && (
                <TextField
                  key={label}
                  margin="dense"
                  value={value}
                  label={label}
                  style={{ width: '200px', margin: 0 }}
                  InputLabelProps={{ FormLabelClasses: { focused: classes.selectLabel } }}
                  InputProps={{ readOnly: true, classes: { inputMarginDense: classes.outlined } }}
                  variant="outlined"
                />
              )
          )}
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default withStyles(styles)(AnalysisFilters);
