import { documentiConstants } from "../Constants";

export function documenti(
    state = {
        taskCount: {},
        deleganteSelected: {
            id_delegante: undefined,
            nome_delegante: undefined,
        },
    },
    action
) {
    switch (action.type) {
        case documentiConstants.RESET_DOCUMENT:
            return {
                ...state,
                current: undefined,
                nota: undefined,
                file: undefined,
            };
        case documentiConstants.RESET_DELEGANTE:
            return {
                ...state,
                deleganteSelected: {
                    id_delegante: undefined,
                    nome_delegante: undefined,
                    tipo_delega: undefined,
                },
            };
        case documentiConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.documents,
                loading: false,
            };
        case documentiConstants.GETALL_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.GETTASKLIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.GETTASKLIST_SUCCESS:
            return {
                ...state,
                current: undefined,
                tasks: action.tasks,
                loading: false,
            };
        case documentiConstants.GETTASKLIST_SUCCESS_NO_CLEAN:
            return {
                ...state,
                tasks: action.tasks,
                loading: false,
            };

        case documentiConstants.GETTASKLIST_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.GETTASK_COUNT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.GETTASK_COUNT_SUCCESS:
            state.taskCount[action.taskCount.idStato] = action.taskCount.count;

            return {
                ...state,
                loading: false,
            };
        case documentiConstants.GETTASK_COUNT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DOCUMENT_FAVORITE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.DOCUMENT_FAVORITE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DOCUMENT_FAVORITE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.GETDOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.GETDOCUMENT_SUCCESS:
            return {
                ...state,
                current: action.documento,
                loading: false,
            };
        case documentiConstants.GETDOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DELETE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.DELETE_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DELETE_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SEND_PROTOCOLLO_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.SEND_PROTOCOLLO_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SEND_PROTOCOLLO_FILE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DELETE_NOTA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.DELETE_NOTA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DELETE_NOTA_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DELETE_FILE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.DELETE_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DELETE_FILE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DELETE_FLUSSO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.DELETE_FLUSSO_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DELETE_FLUSSO_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SAVEDOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.SAVEDOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SAVEDOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.UPDATEMETADATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.UPDATEMETADATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.UPDATEMETADATA_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SOLLECITO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.SOLLECITO_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SOLLECITO_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.UPLOAD_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.UPLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.UPLOAD_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DOWNLOAD_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.DOWNLOAD_DOCUMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DOWNLOAD_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DOWNLOAD_DOC_QRC_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.DOWNLOAD_DOC_QRC_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DOWNLOAD_DOC_QRC_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.WORKFLOW_ACTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.WORKFLOW_ACTION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.WORKFLOW_ACTION_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SAVE_NOTA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.SAVE_NOTA_SUCCESS:
            let wCurrent = state.current;
            wCurrent.note.push(action.nota);
            return {
                ...state,
                current: wCurrent,
                loading: false,
            };
        case documentiConstants.SAVE_NOTA_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SAVE_FLUSSO_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.SAVE_FLUSSO_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SAVE_FLUSSO_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.COPYDOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.COPYDOCUMENT_SUCCESS:
            return {
                ...state,
                copyDoc: action.documento.copia_documento,
                loading: false,
            };
        case documentiConstants.COPYDOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.ADD_DELEGANTE:
            return {
                ...state,
                deleganteSelected: action.delegante,
            };
        case documentiConstants.DOCUMENT_VERIFY_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.DOCUMENT_VERIFY_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.DOCUMENT_VERIFY_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.CHANGE_DOCUMENT_SUCCESS:
            return {
                ...state,
                current: action.documento,
                loading: false,
            };
        case documentiConstants.CHANGE_DOCUMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.CHANGE_DOCUMENT_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.EMPTY_ITEMS_SUCCESS:
            return {
                ...state,
                items: undefined,
                loading: false,
            };
        case documentiConstants.MEETING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.MEETING_SUCCESS:
            return {
                ...state,
                listaRiunioni: action.riunioni,
                loading: false,
            };
        case documentiConstants.MEETING_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.SESSION_ID_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.SESSION_ID_SUCCESS:
            return {
                ...state,
                sessionId: action.sessionId.session.session_id,
                loading: false,
            };
        case documentiConstants.SESSION_ID_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.MEETING_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.MEETING_DETAILS_SUCCESS:
            return {
                ...state,
                dettaglioRiunione: action.dettaglio,
                loading: false,
            };
        case documentiConstants.MEETING_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.TOPIC_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.TOPIC_SUCCESS:
            return {
                ...state,
                listaArgomenti: action.argomenti,
                loading: false,
            };
        case documentiConstants.TOPIC_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.TOPIC_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.TOPIC_DETAILS_SUCCESS:
            return {
                ...state,
                dettaglioArgomento: action.dettaglio,
                loading: false,
            };
        case documentiConstants.TOPIC_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case documentiConstants.CLEAR_MEETING_DETAILS:
            return {
                ...state,
                dettaglioRiunione: undefined,
                dettaglioArgomento: undefined,
                loading: false,
            };

        case documentiConstants.GET_DOC_FOR_EXTERNAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.GET_DOC_FOR_EXTERNAL_SUCCESS:
            return {
                ...state,
                loading: false,
                firmatario_esterno: action.result,
                otp: { richieste_otp: action.result.richieste_otp },
            };
        case documentiConstants.GET_DOC_FOR_EXTERNAL_FAILURE:
            return {
                ...state,
                items: undefined,
                loading: false,
            };
        case documentiConstants.GET_OTP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.GET_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                otp: action.result,
            };
        case documentiConstants.GET_OTP_FAILURE:
            return {
                ...state,
                items: undefined,
                loading: false,
                error: action.error,
            };
        case documentiConstants.GET_VERIFY_OTP_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.GET_VERIFY_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                otp_verificato: action.result,
            };
        case documentiConstants.GET_VERIFY_OTP_FAILURE:
            return {
                ...state,
                items: undefined,
                loading: false,
            };
        case documentiConstants.WORKFLOW_EXT_ACTION_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case documentiConstants.WORKFLOW_EXT_ACTION_SUCCESS:
            return { ...state, loading: false };
        case documentiConstants.WORKFLOW_EXT_ACTION_FAILURE:
            return { ...state, items: undefined, loading: false };
        case documentiConstants.UPLOAD_FIRMA_DIGITALE:
            return { ...state, loading: true };
        case documentiConstants.UPLOAD_FIRMA_DIGITALE_SUCCESS:
            return { ...state, loading: false };
        case documentiConstants.UPLOAD_FIRMA_DIGITALE_FAILURE:
            return { ...state, loading: false };
        case documentiConstants.UPDATE_INSERT_COORD:
            return { ...state };
        case documentiConstants.UPDATE_INSERT_COORD_SUCCESS:
            return { ...state };
        case documentiConstants.UPDATE_INSERT_COORD_FAILURE:
            return { ...state };
        case documentiConstants.OTP_REQUEST:
            return { ...state, loading: true };
        case documentiConstants.OTP_SUCCESS:
            return { ...state, loading: false };
        case documentiConstants.OTP_FAILURE:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
}
