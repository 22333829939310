import React from "react";
import { userService } from 'Services/user.oauth2.service.js';

import withUnderMaintenance from '../../Helpers/withUnderMaintenance';


class TC_TokenPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        let index = this.props.location.search.indexOf("?code=");
        if (index !== -1) {
            let wCode = this.props.location.search.substring(index + 6, this.props.location.search.lenght);
            userService.token(wCode, this.props.history);
        }

    }

    render() {
        return (
            <div>
            </div>
        );
    }
}



const connectedTC_TokenPage = (TC_TokenPage);
export default withUnderMaintenance(connectedTC_TokenPage);