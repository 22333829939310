export default (headers) => {
  const { subject, priority, actions, status, signingPath, creationDate, lastEdit, type } = headers;

  return [
    {
      Header: '',
      accessor: 'note_icon',
      maxWidth: 30,
      sortable: false,
      filterable: false,
    },
    {
      Header: 'ID',
      accessor: 'id',
      maxWidth: 50,
      filterable: false,
    },
    {
      Header: subject,
      accessor: 'descrizione',
      filterable: false,
    },
    {
      Header: signingPath,
      accessor: 'nome_raccoglitore',
      filterable: false,
    },
    {
      Header: type,
      accessor: 'desc_tipo_documento',
      maxWidth: 130,
      filterable: false,
    },
    {
      Header: creationDate,
      id: 'data_creazione',
      accessor: (d) => d.data_creazione_str.split(' ')[0],
      maxWidth: 160,
      filterable: false,
    },
    {
      Header: lastEdit,
      accessor: 'data_ult_modif',
      maxWidth: 160,
      filterable: false,
    },
    {
      Header: priority,
      accessor: 'priorita',
      maxWidth: 100,
      filterable: false,
    },
    {
      Header: status,
      accessor: 'desc_stato_documento',
      maxWidth: 130,
      filterable: false,
      Cell: ({ value }) => (
        <span
          style={{
            color: value === 'Rifiutato' ? '#ff0000' : 'rgba(0, 0, 0, 0.87)',
            fontWeight: value === 'Rifiutato' ? 'bold' : '300',
            transition: 'all .3s ease',
          }}
        >
          {value}
        </span>
      ),
    },
    {
      Header: actions,
      accessor: 'actions',
      sortable: false,
      filterable: false,
      maxWidth: 100,
      Cell: ({ value }) => {
        const { children } = value.props;

        return <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>{children}</div>;
      },
    },
  ];
};
