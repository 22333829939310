import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Checkbox, FormControlLabel } from '@material-ui/core';

import { searchActions } from '../../../Actions';

const CercaInAmbito = ({ ambito, dispatch }) => {
    const { t } = useTranslation();
    const { searchIn, scope } = t('research', { returnObjects: true });
    const { created, checkedOut, rejected, signed, delegated, incoming, deleted } = scope;

    const onChange = (event) => {
        const { checked } = event.target;
        dispatch(searchActions.setAmbito(ambito.name, checked));
    };

    const translateAmbito = () => {
        const { name } = ambito;
        if (name === 'Created') return created;
        else if (name === 'Attentioned') return checkedOut;
        else if (name === 'Rejected') return rejected;
        else if (name === 'Signed') return signed;
        else if (name === 'Delegati') return delegated;
        else if (name === 'Incoming') return incoming;
        else if (name === 'Deleted') return deleted;

        return '';
    };

    if (ambito.name !== '')
        return (
            <FormControlLabel
                label={`${searchIn} "${translateAmbito()}"`}
                labelPlacement="start"
                style={{ width: '50%', flexDirection: 'row', marginLeft: '3px' }}
                control={
                    <Checkbox
                        color="primary"
                        style={{ padding: '0 5px 0 0' }}
                        checked={ambito.value}
                        onChange={onChange}
                    />
                }
            />
        );
    else return null;
};

const mapStateToProps = (state) => {
    const { ambito } = state.search;
    return { ambito };
};

export default connect(mapStateToProps)(CercaInAmbito);
