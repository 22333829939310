import React, { memo, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography, withStyles } from '@material-ui/core';
import { COUNTER } from '../Constants';

import ChartDetails from '../ReusableComponents/ChartDetails';
import { analyticsActions } from '../../../../Actions';

const { COLORS_MAPPING } = COUNTER;

const commonRingStyle = {
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
};

const styles = () => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '30px',
  },
  outerRing: {
    ...commonRingStyle,
    width: '225px',
    height: '225px',
  },
  innerRing: {
    ...commonRingStyle,
    width: '200px',
    height: '200px',
  },
});

const Chart = ({ classes, selectedUser }) => {
  const { container, outerRing, innerRing } = classes;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const states = t('analytics.states', { returnObjects: true });
  const { document, documents, has } = t('analytics', { returnObjects: true }).charts;
  const { signed, created, viewed, rejected, draft, toSign, toView } = t('analytics', {
    returnObjects: true,
  }).states;

  const { ruoli, ids_uo_visibili } = useSelector(({ authentication }) => authentication.user);
  const { tipo_documento } = useSelector(({ analytics }) => analytics.overall.options);
  const { user, scope } = useSelector(({ analytics }) => analytics.counter.options);
  const timeframe = useSelector((state) => state.analytics.counter.timeframe);
  const { count } = useSelector((state) => state.analytics.counter.result);

  const [openDetails, setOpenDetails] = useState(false);

  const currentColor = useMemo(() => COLORS_MAPPING[scope], [scope]);

  const descriptionMapping = {
    created,
    signed,
    viewed,
    rejected,
  };

  const stateDescriptionMapping = {
    draft,
    toSign,
    toView,
  };

  const border = `solid ${currentColor}`;

  const onClick = () => {
    const filters = {
      user,
      scope,
      timeframe,
      tipo_documento,
      ids_uo_visibili,
      ruoli,
      analysisType: 'counter',
    };

    dispatch(analyticsActions.setDocsDetails({ total: count, scope: states[scope] }));
    dispatch(analyticsActions.setFilters(filters));
    dispatch(analyticsActions.getAnalysisDocsDetails(filters));
    setOpenDetails(true);
  };

  return (
    <>
      <div className={container}>
        <div className={outerRing} style={{ border: `5px ${border}` }} onClick={onClick}>
          <div className={innerRing} style={{ border: `30px ${border}` }} onClick={onClick}>
            <Typography style={{ fontSize: '40px', color: currentColor }}>{count}</Typography>
          </div>
        </div>
        <Typography style={{ fontWeight: 800 }}>
          {selectedUser?.label} {has}{' '}
          {descriptionMapping[scope]?.toLowerCase().replace('ati', 'ato') || ''} {count}{' '}
          {count === 1 ? document : documents}{' '}
          {stateDescriptionMapping[scope]?.toLowerCase().replace('ati', 'ato') || ''}
        </Typography>
      </div>
      {openDetails && <ChartDetails closeDetails={() => setOpenDetails(false)} />}
    </>
  );
};

export default withStyles(styles)(memo(Chart));
