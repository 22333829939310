import React from 'react';
import { connect } from 'react-redux';

// @material-ui/core components
import { Dialog, withStyles } from '@material-ui/core/';

import PosizionamentoFirmaModal from '../Utilities/PosizionamentoFirmaModal';

import { capitalizeFirstLetter, toPDFPixels } from '../../Helpers';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

const styles = {
    //...extendedFormsStyle,
    ...regularFormsStyle,
};

const initialState = {
    saveDisabled: true,
};

class PosizionamentoFirmaWrapper extends Dialog {
    constructor(props, context) {
        super(props, context);
        this.state = initialState;
    }

    componentDidUpdate = (prevProps) => {
        const { posizionamentoFirma } = this.props;
        const prevPosFirma = prevProps.posizionamentoFirma;
        if (posizionamentoFirma?.tags?.length > prevPosFirma?.tags?.length) {
            this.setState({
                saveDisabled: false,
            });
        } else if (posizionamentoFirma?.tags?.length < prevPosFirma?.tags?.length)
            this.setState({
                saveDisabled: true
            });
    };

    handleSubmit = (firmatario) => {
        const { documenti, authentication, posizionamentoFirma } = this.props;
        const { CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT } = posizionamentoFirma?.dimensions ?? {};
        const tags = posizionamentoFirma?.tags ?? [];

        const currentCoord = firmatario.id_utente
            ? tags.filter((tag) => tag.id_utente === firmatario.id_utente)[0]
            : tags.filter((tag) => !tag.id_utente && tag.id_uo === firmatario.id_uo)[0];

        const pdfCoord = toPDFPixels(CANVAS_WIDTH, CANVAS_HEIGHT, PDF_WIDTH, PDF_HEIGHT, {
            x: currentCoord.left,
            y: currentCoord.top,
        });

        currentCoord.id_documento = documenti.current.id_documento;
        currentCoord.utente_ult_modif = authentication.user.nome + ' ' + authentication.user.cognome;
        currentCoord.coordinata_x = pdfCoord.x;
        currentCoord.coordinata_y = pdfCoord.y;
        currentCoord.larghezza_rettangolo = 145;
        currentCoord.altezza_rettangolo = 38;

        delete currentCoord.tipo;
        delete currentCoord.firmatario;
        delete currentCoord.id_coordinata;
        delete currentCoord.id;
        delete currentCoord.left;
        delete currentCoord.top;

        this.props.onSubmit(firmatario, currentCoord);
        this.handleClose();
    };

    handleClose = () => {
        this.props.onClose();
    };

    getUUiD(files) {
        let uuid;
        files.forEach((f) => {
            if (f.tipo_file === 'P') uuid = f.uuid;
        });
        return uuid;
    }

    render() {
        const { t, returnData, documenti } = this.props;
        const { saveDisabled } = this.state;
        let uuid;
        let listaFirmatariInterni;
        console.log(saveDisabled);

        if (documenti?.current && returnData) {
            uuid = this.getUUiD(documenti.current.files);

            listaFirmatariInterni = [
                {
                    value: capitalizeFirstLetter(returnData.nome_utente ?? returnData.nome_uo),
                    label: capitalizeFirstLetter(returnData.nome_utente ?? returnData.nome_uo),
                    id: returnData.id_utente ?? returnData.id_uo,
                    tipo_firmatario: returnData.id_utente ? 'Interno' : 'InternoUO',
                },
            ];
        }

        if (documenti?.current)
            return (
                <PosizionamentoFirmaModal
                    docID={documenti.current.id_documento}
                    uuid={uuid}
                    currentTab="Percorsi di firma"
                    listaFirmatariInterni={listaFirmatariInterni}
                    saveDisabled={saveDisabled}
                    handleClose={this.handleClose}
                    handleSubmit={() => this.handleSubmit(returnData)}
                />
            );
        else return null;
    }
}

function mapStateToProps(state) {
    const { alert, authentication, documenti, posizionamentoFirma } = state;

    return {
        authentication,
        alert,
        documenti,
        posizionamentoFirma,
    };
}

const connectedPosizionamentoFirmaWrapper = connect(mapStateToProps)(withStyles(styles)(PosizionamentoFirmaWrapper));
export default connectedPosizionamentoFirmaWrapper;
