import { settingsConstants } from '../Constants';

const underMaintenance = localStorage.getItem('underMaintenance');
const initialState = underMaintenance ? { underMaintenance: true } : { loading: true };

export function settings(state = initialState, action) {
    switch (action.type) {
        case settingsConstants.GET_MAINTENANCE_REQUEST:
            return {
                ...state,
                loading: true,
                error: undefined,
            };
        case settingsConstants.GET_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                underMaintenance: action.payload,
            };
        case settingsConstants.GET_MAINTENANCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case settingsConstants.SET_MAINTENANCE_REQUEST:
            return { ...state, loading: true };
        case settingsConstants.SET_MAINTENANCE_SUCCESS:
            return {
                ...state,
                loading: false,
                underMaintenance: action.payload,
            };
        case settingsConstants.SET_MAINTENANCE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
