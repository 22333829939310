import React from 'react';
import { connect } from 'react-redux';
import Link from '@material-ui/core/Link';
import moment from 'moment';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import regularFormsStyle from 'assets/jss/material-dashboard-pro-react/views/regularFormsStyle';

import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = {
    //...extendedFormsStyle,
    ...regularFormsStyle,
    tcsign_action: {
        height: '21px',
        padding: '0px',
    },
    tc_sign_datetime: {
        color: '#00a99f',
        top: '-2px',

        '& input.form-control': {
            backgroundImage: 'linear-gradient(#00a99f, #00a99f), linear-gradient(#D2D2D2, #D2D2D2)!important',
        },

        '& .rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle': {
            color: '#00a99f',
        },
        '& .form-control::-webkit-input-placeholder': {
            color: '#555',
        },
    },
};

const initialState = {};

class ArgomentoModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);
        this.state = initialState;
    }

    handleCloseModal() {
        this.setState(initialState);
        this.props.onClose();
    }

    render() {
        const { t, classes, documenti } = this.props;

        return (
            <Dialog
                open={this.props.open}
                PaperProps={{
                    style: { maxWidth: '1200px', width: '1200px' },
                }}
                scroll="body"
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="notice-modal-slide-title"
                aria-describedby="notice-modal-slide-description">
                <DialogContent>
                    <Card style={{ marginBottom: '0px' }}>
                        <CardHeader color="success" icon>
                            <CardIcon color="success">
                                <FeaturedPlayListIcon />
                            </CardIcon>
                            <h4 className={classes.cardTitle} style={{ color: '#777' }}>
                                Dettaglio argomento
                            </h4>
                        </CardHeader>

                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={2} lg={6}>
                                    <CustomInput
                                        labelText="Riunione"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value:
                                                documenti.dettaglioRiunione && documenti.dettaglioRiunione.title
                                                    ? documenti.dettaglioRiunione.title
                                                    : '',
                                        }}
                                        classes={{ input: classes.tcSignInputStyle }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} lg={3}>
                                    <CustomInput
                                        labelText="Autore"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value:
                                                documenti.dettaglioRiunione && documenti.dettaglioRiunione.author
                                                    ? documenti.dettaglioRiunione.author
                                                    : '',
                                        }}
                                        classes={{ input: classes.tcSignInputStyle }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={2} lg={3}>
                                    <CustomInput
                                        labelText="Data"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value:
                                                documenti.dettaglioRiunione && documenti.dettaglioRiunione.date
                                                    ? moment(
                                                          documenti.dettaglioRiunione.date,
                                                          'YYYY-MM-DD HH:mm:ss'
                                                      ).format('DD/MM/YYYY HH:mm:ss')
                                                    : '',
                                        }}
                                        classes={{ input: classes.tcSignInputStyle }}
                                    />
                                </GridItem>
                            </GridContainer>
                            <GridContainer style={{ marginBottom: '40px' }}>
                                <GridItem xs={12} sm={12} md={2} lg={6}>
                                    <CustomInput
                                        labelText="Argomento"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        inputProps={{
                                            disabled: true,
                                            value:
                                                documenti.dettaglioArgomento && documenti.dettaglioArgomento.title
                                                    ? documenti.dettaglioArgomento.title
                                                    : '',
                                        }}
                                        classes={{ input: classes.tcSignInputStyle }}
                                    />
                                </GridItem>
                            </GridContainer>
                            {documenti.dettaglioArgomento && documenti.dettaglioArgomento.media ? (
                                <GridContainer style={{ marginBottom: '40px' }}>
                                    <GridItem xs={12} sm={12} md={2} lg={12}>
                                        <Link target="_blank" rel="noopener" href={documenti.dettaglioArgomento.media}>
                                            Download Documento
                                        </Link>
                                    </GridItem>
                                </GridContainer>
                            ) : (
                                ''
                            )}
                        </CardBody>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleCloseModal()}>{t('action.close')}</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, documenti } = state;
    const { user } = authentication ?? {};
    return {
        authentication,
        alert,
        documenti,
        user,
    };
}

const connectedArgomentoModalForm = connect(mapStateToProps)(withStyles(styles)(ArgomentoModalForm));
export default connectedArgomentoModalForm;
