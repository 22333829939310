import React from 'react';
import { withStyles, Button } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { VscChromeClose } from 'react-icons/vsc';
import { SIGNATURE_BOX_WIDTH, SIGNATURE_BOX_HEIGHT, formatString } from '../../Helpers';

import { useTranslation } from 'react-i18next';

const style = () => ({
    tagFirmatario: {
        width: SIGNATURE_BOX_WIDTH,
        height: SIGNATURE_BOX_HEIGHT,
        padding: '7px',
        backgroundColor: 'rgba(255, 128, 101, .6)',
        color: 'black',
        position: 'absolute',
        borderRadius: '3px',
        border: '1px solid black',
        cursor: 'move', //move //grab
    },
    tagNotDraggable: {
        '&:hover': {
            cursor: 'default',
        },
    },
    customDragLayer: {
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        opacity: 0.7,
    },
    rowWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        fontSize: '12px',
    },
    buttonStyle: {
        padding: 0,
        margin: 0,
        minHeight: 0,
        minWidth: 0,
    },
});

const TagFirmatario = React.memo((props) => {
    const { id, classes, firmatario, isEditable, removeTag } = props;
    const { coords, onMouseOver, onMouseLeave } = props;
    const { t } = useTranslation();
    const [, drag] = useDrag({
        type: 'TAG',
        item: () => ({id}),
        canDrag: isEditable ? true : false,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            className={`${classes.tagFirmatario} ${!isEditable ? classes.tagNotDraggable : null}`}
            style={{
                top: coords.top,
                left: coords.left,
            }}
            onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
            }}
        >
            <div className={classes.rowWrapper}>
                <p>{t('misc.signatory')}</p>
                {isEditable ? (
                    <Button className={classes.buttonStyle} onClick={(ev) => removeTag(ev)}>
                        <VscChromeClose />
                    </Button>
                ) : null}
            </div>
            <p title={firmatario.length > 20 ? firmatario : ''} style={{ fontFamily: 'Caveat', fontSize: '24px' }}>
                {formatString(firmatario, 20) ?? null}
            </p>
            <p style={{ textAlign: 'left' }}>
                <span style={{ display: 'block' }}>{t('misc.name_lastName')}</span>
                <span>{t('misc.signDate')}</span>
            </p>
        </div>
    );
});

export default withStyles(style)(TagFirmatario);
