import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { connect } from 'react-redux';

import DropzoneComponent from 'react-dropzone-component';
import { Typography, withStyles, DialogActions, Button, CircularProgress } from '@material-ui/core';
import { SIGNATURE_BOX_WIDTH, SIGNATURE_BOX_HEIGHT, verifyPDFSignature, utils } from '../../../Helpers';
import CustomButton from 'components/CustomButtons/Button.jsx';
import { withSnackbar } from 'notistack';

//icons
import { VscChromeClose } from 'react-icons/vsc';
import { IconContext } from 'react-icons';
import { FaCheckCircle } from 'react-icons/fa';

import { documentiActions } from '../../../Actions';


const styles = () => ({
    style: {
        height: '380px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    signatureWrapper: {
        border: '1px dashed black',
        borderRadius: '5px',
        width: SIGNATURE_BOX_WIDTH * 2,
        height: SIGNATURE_BOX_HEIGHT * 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dropzone: {
        margin: 0,
        padding: 0,
        cursor: 'pointer',
        borderRadius: '5px',
    },
    dragover: {
        border: '1px solid blue',
    },
    disableDropzone: {
        opacity: 0.5,
        pointerEvents: 'none',
    },
    closeButton: {
        minWidth: 0,
        padding: 0,
        height: 0,
        marginLeft: '5px',
    },
    filePreview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        fontWeight: 'bold',
        color: 'red',
    },
    center: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const initialState = {
    dragover: false,
    fetching: false,
    firmaDigitaleVerificata: false,
    file: null,
    fileName: null,
    error: false,
};

class FirmaDigitale extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.djsConfig = {
            maxFiles: 1,
            dictDefaultMessage: 'Clicca o trascina il documento con la tua firma',
            disablePreview: true,
            addRemoveLinks: false,
            acceptedFiles:
                'application/pdf',
            autoProcessQueue: false,
            previewTemplate: ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview dz-file-preview">
                    <div className="dz-details"></div>
                    <div className="dz-progress">
                        <span className="dz-upload" data-dz-uploadprogress="true"></span>
                    </div>
                </div>
            ),
        };

        this.componentConfig = {
            postUrl: '/no-url',
        };

        this.handleDropzone = {
            dragover: () => {
                this.setState({
                    dragover: true,
                });
            },

            dragleave: () => {
                this.setState({
                    dragover: false,
                });
            },
            drop: () => {
                this.setState({
                    dragover: false,
                });
            },
            addedfile: (file) => {
                this.setState({
                    error: false,
                });
                const dataURLReader = new FileReader();
                dataURLReader.readAsDataURL(file);

                dataURLReader.onload = () => {
                    const document = dataURLReader.result;

                     /*const regex = /(?<=:)(.*)(?=;)/;
                    const fileType = document.match(regex)[0];*/
                    const fileType = utils.base64MimeType(document);

                    if (fileType === 'application/pdf') {
                        const bufferReader = new FileReader();
                        bufferReader.readAsArrayBuffer(file);
                        bufferReader.onload = () => {
                            const isVerified = verifyPDFSignature(bufferReader.result);
                            if (isVerified)
                                this.setState({
                                    file,
                                    fileName: file.name,
                                });
                            else
                                this.setState({
                                    error: true,
                                });
                        };
                    } else
                        this.props.enqueueSnackbar('Il file deve essere di tipo .pdf', {
                            variant: 'warning',
                        });
                };
            },
        };
    }

    uploadFirmaDigitale = async () => {
        this.setState({
            fetching: true,
        });
        this.setState({
            firmaDigitaleVerificata: true,
            fetching: false,
        });

        const { file } = this.state;
        const { firmatario_esterno } = this.props.documenti;
        const { firmatario } = firmatario_esterno;

        const metadati = firmatario_esterno.files[0];
        const documento = {
            documento: {
                id_azione: 8,
                id_contatto: firmatario.id_contatto,
                nome_contatto: firmatario.nome + ' ' + firmatario.cognome,
                metadati
            },
            file
        };
        let hash_code = window.location.search.replace('?hashcode=', '');
        this.props.dispatch(documentiActions.uploadFirmaDigitale(documento, hash_code));
    };

    removeFile = () => {
        this.setState({
            file: null,
            fileName: null,
        });
    };

    render() {
        const { t, classes, showTitle } = this.props;
        const { dragover, file, fileName, firmaDigitaleVerificata, fetching, error } = this.state;
        const componentConfig = this.componentConfig;
        const djsConfig = this.djsConfig;

        return (
            <div className={classes.style}>
                {showTitle ? (
                    <Typography style={{ fontSize: '36px', textAlign: 'center' }}>
                        Firma digitale
                    </Typography>
                ) : null}
                {!firmaDigitaleVerificata && !fetching ? (
                    <>
                        <DropzoneComponent
                            className={`${classes.signatureWrapper} ${classes.dropzone} ${
                                dragover ? classes.dragover : null
                                } ${file ? classes.disableDropzone : null}`}
                            djsConfig={djsConfig}
                            config={componentConfig}
                            eventHandlers={this.handleDropzone}
                        />
                        <div className={classes.filePreview}>
                            {error && (
                                <p style={{ color: 'red', fontWeight: 'normal' }}>
                                    Firma non presente o non valida
                                </p>
                            )}
                            {fileName && (
                                <>
                                    {fileName}
                                    <Button
                                        className={classes.closeButton}
                                        onClick={this.removeFile}
                                    >
                                        <VscChromeClose />
                                    </Button>
                                </>
                            )}
                        </div>

                        <DialogActions style={{ alignSelf: 'flex-end' }}>
                            <CustomButton
                                size="sm"
                                color="primary"
                                disabled={file ? false : true}
                                onClick={this.uploadFirmaDigitale}
                            >
                                Procedi
                            </CustomButton>
                        </DialogActions>
                    </>
                ) : (
                        <div className={classes.center}>
                            {fetching ? (
                                <CircularProgress />
                            ) : (
                                    <>
                                        <IconContext.Provider value={{ color: 'green', size: '4em' }}>
                                            <div>
                                                <FaCheckCircle />
                                            </div>
                                        </IconContext.Provider>
                                        <div style={{ marginTop: '1em' }}>Firma verificata e caricata</div>
                                    </>
                                )}
                        </div>
                    )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { documenti, alert } = state;
    return {
        documenti,
        alert,
    };
}

const connectedFirmaDigitale = connect(mapStateToProps)(
    withSnackbar(withStyles(styles)(FirmaDigitale))
);
export default connectedFirmaDigitale;
