import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactTable from "react-table";

import ArgomentoModalForm from "./ArgomentoModalForm.jsx";

import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { documentiActions, alertActions } from '../../Actions';
import { ModelViewHelper, Authorize } from "Helpers/model-view.js";


const styles = theme => ({
    ...extendedFormsStyle,
    tcSignTableButton: {
        margin: "0px",
        padding: "0px",
        height: "auto!important"
    },
    tcSignTableRow: {
        height: "28px!important",

    },
    tcSignTableHeadRow: {
        height: "28px"
    },
    tcSignTableCell: {
        height: "28px!important",
        opacity: "1",
        fontSize: "14px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        fontWeight: "400",
        lineHeight: "1.42857"
    },
    tcSignTableHead: {
        color: "#AAA !important",
        padding: "14px 0px 0px 0px!important",
        fontSize: "11px!important",
        lineHeight: "1rem!important",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "400"
    },
    tcSignInputStyle: {
        fontSize: "14px",
    },
    selectLabel: {
        fontSize: "14px",
        //textTransform: "uppercase",
        color: "#AAA !important",
        top: "8px",
    },
    tcSignSelectControl: {
        margin: "7px 1px 0px 0px !important",
    },
    tcsign_card: {
        "margin-top": "0px",
        "margin-bottom": "0px"
    },
    tcsign_cardbody: {
        "padding-top": "0",
        "padding-bottom": "0"
    },
    tcsign_switchIconChecked: {
        "border-color": "#AAA"
    },
    tcsign_dialog: {
        "max-width": "700px"
    },
    tcsign_action: {
        height: "21px",
        padding: "0px"
    },
    tcsign_proposta: {
        "cursor": "default",
        "border-radius": "20px"
    }
});

class GestioneSeduteModalForm extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            showTopics: false,
            titoloProposta: undefined,
            selectedMeeting: undefined,
            selectedTopic: undefined,
            dissociated: false,
            showDetails: false,
            closeInfo: false,
            alert: "",
        };
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    selectMeeting(riunione) {
        // Popola elenco argomenti per riunione
        const { documenti } = this.props;
        this.props.dispatch(documentiActions.getMeetingDetails(documenti.sessionId, riunione.id_riunione));
        this.setState({ showTopics: true, selectedMeeting: riunione, selectedTopic: undefined });
        this.props.onChange(true, false);
    }

    selectTopic(argomento) {
        this.setState({ selectedTopic: argomento, dissociated: false });
        this.props.onChange(true, false);
    }

    associaRiunioneArgomento() {
        let titoloPropostaState = this.state.titoloProposta ? "success" : "error";
        if (titoloPropostaState === "error") {
            this.setState({ titoloPropostaState: "error" });
            this.props.dispatch(alertActions.error("Il campo titolo è obbligatorio"));
            return;
        }
        let associazione = {
            id_riunione: this.state.selectedMeeting.id_riunione,
            data_riunione: moment(this.state.selectedMeeting.data_riunione, 'DD/MM/YYYY HH:mm').format(),
            id_argomento: this.state.selectedTopic.id_argomento,
            titolo_riunione: this.state.selectedMeeting.titolo_riunione,
            titolo_argomento: this.state.selectedTopic.titolo_argomento,
            titolo_proposta: this.state.titoloProposta
        };
        this.props.onSubmit(associazione, true, true);
        this.setState({ dissociated: false, showTopics: false });
    }

    annullaAssociazione() {
        this.props.onSubmit(undefined, true, true);
        this.resetSelection(true);
    }

    annulla() {
        const { argomentoAssociato } = this.props;
        if (argomentoAssociato) {
            let associazione = {
                id_riunione: argomentoAssociato.id_riunione,
                data_riunione: moment(argomentoAssociato.data_riunione).format(),
                id_argomento: argomentoAssociato.id_argomento,
                titolo_riunione: argomentoAssociato.titolo_riunione,
                titolo_argomento: argomentoAssociato.titolo_argomento
            };
            this.props.onSubmit(associazione, false, false);
        } else {
            this.props.onSubmit(undefined, false, false);
        }
        this.setState({ selectedMeeting: undefined, selectedTopic: undefined, showTopics: false, dissociated: false, titoloProposta: undefined, titoloPropostaState: undefined });
    }

    visualizzaDettaglio() {
        const { argomentoAssociato, documenti } = this.props;
        this.setState({ showDetails: true });
        this.props.dispatch(documentiActions.getMeetingDetails(documenti.sessionId, argomentoAssociato.id_riunione, argomentoAssociato.id_argomento));
        //this.props.dispatch(documentiActions.getTopicDetails(argomentoAssociato.id_riunione, argomentoAssociato.id_argomento))
    }

    nascondiDettaglio() {
        this.setState({ showDetails: false });
    }

    resetSelection(dissociated) {
        this.setState({ showTopics: false, selectedMeeting: undefined, selectedTopic: undefined, dissociated: dissociated, titoloProposta: undefined });
    }

    simpleReset() {
        this.setState({ selectedMeeting: undefined, selectedTopic: undefined, titoloProposta: undefined });
    }

    change(event, stateName) {
        let stateObj = {};
        stateObj[stateName] = event.target.value;
        stateObj[stateName + "State"] = undefined;
        this.setState(stateObj);
    }

    handleTest() {
        const { argomentoAssociato, documenti } = this.props;
        this.props.dispatch(documentiActions.createProposal(documenti.sessionId, argomentoAssociato.id_riunione, argomentoAssociato.id_argomento, "test"));
    }

    render() {
        const { t, classes, documenti, argomentoAssociato, abilitaAnnulla } = this.props;
        const { selectedMeeting, selectedTopic } = this.state;
        let abilitaAssociazione = documenti.current && documenti.current.id_stato_documento
            && (documenti.current.id_stato_documento === 4 || documenti.current.id_stato_documento === 5
                || documenti.current.id_stato_documento === 7 || documenti.current.id_stato_documento === 9) ? false : true;
        let listaRiunioni = [];
        let listaArgomenti = [];
        if (documenti && documenti.listaRiunioni) {
            listaRiunioni = documenti.listaRiunioni;
        }

        if (this.state.showTopics) {
            if (documenti && documenti.dettaglioRiunione && documenti.dettaglioRiunione.topics) {
                listaArgomenti = documenti.dettaglioRiunione.topics;
            }
            //topicsTable = ();
        }

        let btnDissocia = '';
        let btnAssocia = '';
        let btnAnnulla = '';
        let btnDettaglio = '';
        if (argomentoAssociato && argomentoAssociato !== "" && !this.state.dissociated && abilitaAssociazione && Authorize.checkAuthorizeFunc('FNCESD')) {
            btnDissocia = (
                <Button
                    style={{ marginTop: "25px", float: "right" }}
                    onClick={() => this.annullaAssociazione()}
                    color="danger"
                >
                    Dissocia
            </Button>);
        }
        if (this.state.showTopics && this.state.selectedTopic && Authorize.checkAuthorizeFunc('FNCAAR')) {
            btnAssocia = (
                <Button
                    style={{ marginTop: "25px", float: "right" }}
                    onClick={() => this.associaRiunioneArgomento()}
                    color="info"
                >
                    Associa
                </Button>);
        }

        if (abilitaAnnulla && Authorize.checkAuthorizeFunc('FNCAAR')) {
            btnAnnulla = (
                <Button
                    style={{ marginTop: "25px", float: "right" }}
                    onClick={() => this.annulla()}
                >
                    Annulla
                </Button>);
        }

        if (argomentoAssociato && argomentoAssociato !== "" && Authorize.checkAuthorizeFunc('FNCVSD')) {
            btnDettaglio = (
                <div>
                    <Button
                        style={{ marginTop: "25px", float: "right" }}
                        onClick={() => this.visualizzaDettaglio()}
                        color="success"
                    >
                        Dettaglio
                    </Button>
                    <ArgomentoModalForm
                        open={this.state.showDetails}
                        onClose={() => this.nascondiDettaglio()}
                        aria-labelledby="notice-modal-slide-title"
                        aria-describedby="notice-modal-slide-description"
                    />
                </div>
            );
        }

        let nessuaAss = (<h6 style={{ marginTop: "38px", color: "#f09102" }}>Nessuna associazione presente</h6>);
        let associaDoc = (
            <GridContainer style={{ textAlign: "center", marginTop: "35px", marginBottom: "35px" }}>
                {!argomentoAssociato ?
                    <GridContainer><GridItem xs={12} sm={12} md={12}>{nessuaAss}</GridItem></GridContainer> : ''}
            </GridContainer>);
        if (this.state.selectedTopic || (argomentoAssociato && argomentoAssociato !== "" && !this.state.dissociated)) {
            let colorStatus = documenti.current && documenti.current.status && documenti.current.status === 'inviata' ? 'success' : 'warning';
            let statusMsg = documenti.current && documenti.current.status ? documenti.current.status : '';
            associaDoc = (
                <div>
                    {documenti.current && documenti.current.status ? <GridContainer style={{ marginTop: "35px" }}><GridItem xs={12} sm={12} md={3}>
                        <Button
                            color={colorStatus}
                            className={classes.tcsign_proposta}>
                            Stato della proposta: {statusMsg}
                        </Button>
                    </GridItem></GridContainer> : ''}

                    <GridContainer style={{ marginTop: "35px" }}>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                                labelText="Titolo"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: true,
                                    value: argomentoAssociato && argomentoAssociato.titolo_proposta ? argomentoAssociato.titolo_proposta : '',
                                }}
                                classes={{ input: classes.tcSignInputStyle }}
                            />
                        </GridItem>

                    </GridContainer>
                    <GridContainer style={{ marginBottom: "35px" }}>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                                labelText="Riunione"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: true,
                                    value: argomentoAssociato && argomentoAssociato.titolo_riunione ? argomentoAssociato.titolo_riunione : '',
                                }}
                                classes={{ input: classes.tcSignInputStyle }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                                labelText="Argomento"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: true,
                                    value: argomentoAssociato && argomentoAssociato.titolo_argomento ? argomentoAssociato.titolo_argomento : '',
                                }}
                                classes={{ input: classes.tcSignInputStyle }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                            <CustomInput
                                labelText="Data"
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                inputProps={{
                                    disabled: true,
                                    value: argomentoAssociato && argomentoAssociato.data_riunione ? moment(argomentoAssociato.data_riunione, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm') : '',
                                }}
                                classes={{ input: classes.tcSignInputStyle }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            {btnDissocia}{btnDettaglio}
                        </GridItem>
                    </GridContainer>
                </div>
            );
        }

        return (
            <div>
                {Authorize.checkAuthorizeFunc('FNCVSD') ?
                    <Card style={{ "marginBottom": "20px" }}>
                        <CardHeader color="success" icon>
                            <h4 className={classes.cardTitle} style={{ color: "#777" }}>
                                Proposta
                        </h4>
                        </CardHeader>
                        <CardBody>
                            {associaDoc}
                        </CardBody>
                    </Card>
                    : ""}
                {(abilitaAssociazione && Authorize.checkAuthorizeFunc('FNCAAR')) ?
                    <div>
                        <Card style={{ "marginBottom": "20px" }}>
                            <CardHeader color="success" icon>
                                <h4 className={classes.cardTitle} style={{ color: "#777" }}>
                                    Proposta da associare
                        </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer style={{ marginTop: "20px", marginBottom: "20px" }}>
                                    <GridItem xs={3}>
                                        <CustomInput
                                            success={this.state.titoloPropostaState === "success"}
                                            error={this.state.titoloPropostaState === "error"}
                                            labelText="Titolo"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: event =>
                                                    this.change(event, "titoloProposta"),
                                                value: this.state.titoloProposta || '',
                                            }}
                                            classes={{ input: classes.tcSignInputStyle }}
                                        />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <CustomInput
                                            labelText="Riunione"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.selectedMeeting ? this.state.selectedMeeting.titolo_riunione || '' : '',
                                            }}
                                            classes={{ input: classes.tcSignInputStyle }}
                                        />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <CustomInput
                                            labelText="Argomento"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.selectedTopic && this.state.selectedTopic.titolo_argomento ? this.state.selectedTopic.titolo_argomento || '' : '',
                                            }}
                                            classes={{ input: classes.tcSignInputStyle }}
                                        />
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <CustomInput
                                            labelText="Data Riunione"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.selectedMeeting && this.state.selectedMeeting.data_riunione ? this.state.selectedMeeting.data_riunione : '',
                                            }}
                                            classes={{ input: classes.tcSignInputStyle }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        {btnAnnulla}
                                        {btnAssocia}
                                    </GridItem>
                                </GridContainer>

                                <GridContainer style={{ marginTop: "20px" }}>
                                    <GridItem xs={6}>
                                        <ReactTable
                                            data={
                                                listaRiunioni.map((prop, key) => {
                                                    let wReturn = ModelViewHelper.meeting_ModelToView(prop);
                                                    let colorCheck = "gray";
                                                    if (selectedMeeting && selectedMeeting.id_riunione && abilitaAnnulla
                                                        && selectedMeeting.id_riunione === wReturn.id_riunione && this.state.showTopics) {
                                                        colorCheck = "primary";
                                                    }
                                                    let btnSelectMeeting = '';
                                                    if (Authorize.checkAuthorizeFunc('FNCAAR'))
                                                        btnSelectMeeting = (<Tooltip
                                                            id="tooltip-top"
                                                            title="Seleziona riunione"
                                                            placement="bottom"
                                                            classes={{ tooltip: classes.tooltip }}
                                                        >
                                                            <Button
                                                                justIcon
                                                                round
                                                                simple
                                                                onClick={() => this.selectMeeting(wReturn)}
                                                                color={colorCheck}
                                                                className={classes.tcsign_action}
                                                            >
                                                                <CheckCircleIcon />
                                                            </Button>
                                                        </Tooltip>);
                                                    wReturn.actions = (
                                                        <div>
                                                            {btnSelectMeeting}
                                                        </div>
                                                    );
                                                    return wReturn;
                                                })
                                            }
                                            filterable
                                            columns={[
                                                {
                                                    Header: "ID",
                                                    accessor: "id_riunione",
                                                    maxWidth: 100
                                                },
                                                {
                                                    Header: "Riunione",
                                                    accessor: "titolo_riunione",
                                                },
                                                {
                                                    Header: "Data",
                                                    accessor: "data_riunione",
                                                    maxWidth: 200
                                                },
                                                {
                                                    Header: () => (
                                                        <div style={{
                                                            textAlign: "center"
                                                        }}>
                                                            Azioni
                                          </div>
                                                    ),
                                                    accessor: "actions",
                                                    sortable: false,
                                                    filterable: false,
                                                    maxWidth: 100,
                                                    className: "centerColumn"

                                                }
                                            ]}

                                            defaultPageSize={5}
                                            showPaginationTop={false}
                                            showPaginationBottom
                                            className="-striped -highlight"
                                            striped
                                            hover
                                            condensed
                                            pagination
                                            insertRow
                                            deleteRow
                                            search
                                            previousText='Precedente'
                                            nextText='Successivo'
                                            loadingText='In caricamento...'
                                            noDataText='Nessuna riunione trovata'
                                            pageText='Pagina'
                                            ofText='di'
                                            rowsText='righe'
                                        />
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <ReactTable
                                            data={
                                                listaArgomenti.map((prop, key) => {
                                                    let wReturn = ModelViewHelper.topic_ModelToView(prop);
                                                    let colorCheck = "gray";
                                                    if (selectedTopic && selectedTopic.id_argomento
                                                        && selectedTopic.id_argomento === wReturn.id_argomento) {
                                                        colorCheck = "primary";
                                                    }
                                                    let btnSelectTopic = "";
                                                    if (Authorize.checkAuthorizeFunc('FNCAAR'))
                                                        btnSelectTopic = (<Tooltip
                                                            id="tooltip-top"
                                                            title="Seleziona argomento"
                                                            placement="bottom"
                                                            classes={{ tooltip: classes.tooltip }}
                                                        >
                                                            <Button
                                                                justIcon
                                                                round
                                                                simple
                                                                onClick={() => this.selectTopic(wReturn)}
                                                                color={colorCheck}
                                                                className={classes.tcsign_action}
                                                            >
                                                                <CheckCircleIcon />
                                                            </Button>
                                                        </Tooltip>);
                                                    wReturn.actions = (
                                                        <div>
                                                            {btnSelectTopic}
                                                        </div>
                                                    );
                                                    return wReturn;
                                                })
                                            }
                                            filterable
                                            columns={[

                                                {
                                                    Header: "ID",
                                                    accessor: "id_argomento",
                                                    maxWidth: 100
                                                },
                                                {
                                                    Header: "Argomento",
                                                    accessor: "titolo_argomento",
                                                },
                                                {
                                                    Header: () => (
                                                        <div style={{
                                                            textAlign: "center"
                                                        }}>
                                                            Azioni
                                          </div>
                                                    ),
                                                    accessor: "actions",
                                                    sortable: false,
                                                    filterable: false,
                                                    maxWidth: 100,
                                                    className: "centerColumn"
                                                }
                                            ]}

                                            defaultPageSize={5}
                                            showPaginationTop={false}
                                            showPaginationBottom
                                            className="-striped -highlight"
                                            striped
                                            hover
                                            condensed
                                            pagination
                                            insertRow
                                            deleteRow
                                            search
                                            previousText='Precedente'
                                            nextText='Successivo'
                                            loadingText='In caricamento...'
                                            noDataText='Nessuna agomento trovata'
                                            pageText='Pagina'
                                            ofText='di'
                                            rowsText='righe'
                                        />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </div> : ""}
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { utils, authentication, documenti } = state;
    const { user } = authentication ?? {};

    return {
        documenti,
        utils,
        user
    };
}

const connectedGestioneSeduteModalForm = connect(mapStateToProps)(withStyles(styles)(withRouter(GestioneSeduteModalForm)));
export default connectedGestioneSeduteModalForm;