import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import ReactTable from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import swal from '@sweetalert/with-react';
import moment from 'moment';

// @material-ui/icons
import { InfoOutlined } from '@material-ui/icons';
import Document from '@material-ui/icons/InsertDriveFile';
import File from '@material-ui/icons/ImportContacts';
import Download from '@material-ui/icons/CloudDownload';

import getColumns from './columns';

// core components
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import { ModelViewHelper, Authorize } from 'Helpers/model-view.js';
import { documentiActions, analyticsActions } from '../../../../Actions';

import { useTranslation } from 'react-i18next';

import ActionBtn from './ActionBtn';
import FileModalForm from '../../../../Custom/Documento/FileModalForm.jsx';

const FilteredDocsList = ({ tableRef }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { downloadDoc, viewDoc } = t('action', { returnObjects: true });
	const { previous, next, loadingTxt, page, of, rows, noDoc, headers } = t('table', {
		returnObjects: true
	});

	const tema = useSelector(({ tema }) => tema);
	const { pagination, result, loading, total } = useSelector(({ analytics }) => analytics.docsDetails);
	const { pageSize, pageIndex } = pagination;

	const [selected, setSelected] = useState(null);
	const [showFile, setShowFile] = useState({});

	const cardColor = tema.theme_color;

	const handleClickOpenFileNote = (note_singolo_doc) => {
		const { author, dateHour, text } = t('misc', { returnObjects: true });

		let elencoNote = '',
			singolaNota = '';
		var lung = note_singolo_doc.length;

		for (var i = 0; i < lung; i++) {
			var dataCreazione = note_singolo_doc[i].data_creazione;
			var dataCreazioneFormattata = moment(dataCreazione).format('DD/MM/YYYY HH:mm');
			singolaNota = `${author}${note_singolo_doc[i].utente} \n ${dateHour}${dataCreazioneFormattata} \n${text}${note_singolo_doc[i].descrizione}\n \n`;
			elencoNote = elencoNote + singolaNota;
		}

		swal({
			animation: 'slide-from-top',
			title: t('note.docNote'),
			overflow: 'hidden',
			text: elencoNote,
			input: 'text',
			type: 'textarea',
			style: { display: 'block' },
			height: '100px',
			inputType: 'textarea',
			confirmButtonText: 'Cool',
			showConfirmButton: false,
			showCancelButton: true
		});
	};

	const handleClickOpenFile = (documento) => {
		setShowFile({ uuid: documento.uuid, documento });
	};

	const handleFileDownload = (id, documento) => dispatch(documentiActions.download(id, documento));

	const handlePageChange = (pageIndex) => dispatch(analyticsActions.setPagination(pageIndex + 1));

	const handlePageSizeChange = (pageSize, pageIndex) =>
		dispatch(analyticsActions.setPagination(pageIndex + 1, pageSize));

	const handleSortChange = (sorting) => dispatch(analyticsActions.setSorting(sorting));

	return (
		<GridItem xs={12}>
			<Card>
				<CardHeader color={cardColor} icon>
					<CardIcon color={cardColor}>
						<Document />
					</CardIcon>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<h4 style={{ color: '#3C4858', fontWeight: 300 }}>{t('menu.documents')}</h4>
						<div
							style={{
								display: 'flex',
								gap: '20px',
								color: '#3C4858',
								margin: 0
							}}
						>
							<p>
								{t('analytics.totDocuments')}: {total}
							</p>
						</div>
					</div>
				</CardHeader>
				<CardBody>
					<ReactTable
						ref={tableRef}
						getTrProps={(_, rowInfo) => {
							const { row, index } = rowInfo ?? {};

							const background = index === selected ? '#e8ffdf' : null;
							const border = index === selected ? '1px solid #59ff59' : null;

							return row
								? {
										onClick: () => setSelected(index),
										style: { overflow: 'hidden', position: 'relative', background, border }
								  }
								: {};
						}}
						data={result.map((doc) => {
							let wReturn = ModelViewHelper.document_ModelToView(doc, t);
							const { file_name, uuid, note } = wReturn;

							const otherPropsBtn = [
								{
									prop: 'note_icon',
									show: note?.length > 0,
									title: t('note.docNote'),
									btnColor: 'success',
									btnDisabled: false,
									onClick: () => handleClickOpenFileNote(note),
									icon: <InfoOutlined />
								}
							];

							const actionsBtnList = [
								{
									show: Authorize.checkAuthorizeFunc('FNCVWD'),
									title: viewDoc,
									btnColor: 'success',
									btnDisabled: !file_name,
									onClick: () => handleClickOpenFile(wReturn),
									icon: <File />
								},
								{
									show: Authorize.checkAuthorizeFunc('FNCDWL'),
									title: downloadDoc,
									btnColor: 'rose',
									btnDisabled: !file_name,
									onClick: () => handleFileDownload(uuid, wReturn),
									icon: <Download />
								}
							];

							wReturn.actions = (
								<div className='actions-center'>
									{actionsBtnList.map((btn) => (
										<ActionBtn key={uuidv4()} {...btn} />
									))}
								</div>
							);

							otherPropsBtn.forEach(
								({ prop, ...btn }) =>
									(wReturn[prop] = btn.show ? <ActionBtn key={uuidv4()} {...btn} /> : '')
							);
							return wReturn;
						})}
						filterable
						columns={getColumns(headers)}
						striped
						hover
						manual
						condensed
						insertRow
						deleteRow
						search
						defaultPageSize={pageSize}
						pages={Math.ceil(total / pageSize)}
						page={pageIndex - 1}
						showPaginationTop={false}
						showPaginationBottom
						loading={loading}
						onPageChange={handlePageChange}
						onPageSizeChange={handlePageSizeChange}
						onSortedChange={(sorting) => handleSortChange(sorting)}
						className='-striped -highlight'
						previousText={previous}
						nextText={next}
						loadingText={loadingTxt}
						noDataText={noDoc}
						pageText={page}
						ofText={of}
						rowsText={rows}
					/>
				</CardBody>
			</Card>
			{!isEmpty(showFile) && <FileModalForm {...showFile} onClose={() => setShowFile({})} />}
		</GridItem>
	);
};

export default FilteredDocsList;
