import React from 'react';
import logo from 'assets/img/maintenance.png';

const backgroundStyle = {
    backgroundColor: 'rgb(238 238 238)',
    textAlign: 'center',
    marginTop: '10%',
};

const imageStyle = {
    width: '40%',
    filter: 'drop-shadow(2px 2px 8px grey)',
    borderRadius: '20px',
};

class MaintenancePage extends React.Component {
    render() {
        return (
            <div style={backgroundStyle}>
                <img alt="maintenance pic" src={logo} style={imageStyle} />
            </div>
        );
    }
}

export default MaintenancePage;
