export const ThemeUtils = {
    getThemeCss,
    getThemeImage,
    getThemeColor,
    getPngSrc,
    getJpegSrc
}

function getThemeCss(themes, key, type) {
    let result;
    if (themes && themes.length > 0) {
        themes.forEach(el => {
            if (el.chiave === key && el.type_file === type) {
                result = el.css;
            }
        });
    }
    return result;
}

function getThemeImage(themes, key, type) {
    let result;
    if (themes && themes.length > 0) {
        themes.forEach(el => {
            if (el.chiave === key && el.type_file === type) {
                result = el.file_content;
            }
        });
    }
    return result;
}

function getThemeColor(themes, key, type) {
    let result;
    if (themes && themes.length > 0) {
        themes.forEach(el => {
            if (el.chiave === key && el.type_file === type) {
                result = el.valore;
            }
        });
    }
    return result;
}

function getPngSrc(imgBase64) {
    let result;
    result = "data:image/png;base64," + imgBase64;

    return result;
}

function getJpegSrc(imgBase64) {
    let result;
    result = "data:image/jpeg;base64," + imgBase64;

    return result;
}