import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { userActions } from '../Actions';

const withUnderMaintenance = (Component) => {
    class UnderMaintenance extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }
        componentDidMount() {
            if (!localStorage.getItem('user')) {
                this.setState({
                    loading: false,
                });
                this.props.history.push('/login');
            } else {
                const isAdmin = this.props.user?.ruoli.filter((ruolo) => ruolo.id_ruolo === 1).length === 1;

                this.setState({
                    loading: false,
                });

                if (!isAdmin && this.props.settings.underMaintenance) {
                    try {
                        this.props.history.push('/maintenance');
                        this.props.dispatch(userActions.logout());
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
        }

        render() {
            const { loading } = this.state;
            if (loading)
                return (
                    <div
                        style={{
                            backgroundColor: 'rgb(238, 238, 238)',
                            widht: '100vw',
                            height: '100vh',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        <CircularProgress />
                    </div>
                );
            else return <Component {...this.props} />;
        }
    }

    function mapStateToProp(state) {
        const { authentication, settings, tema } = state;

        if (authentication?.user) {
            const { user } = authentication;

            return {
                user,
                settings,
                tema,
            };
        } else return {};
    }

    return connect(mapStateToProp)(UnderMaintenance);
};

export default withUnderMaintenance;
