import React from "react";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import Button from "components/CustomButtons/Button.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import User from "@material-ui/icons/Person";
import Save from "@material-ui/icons/Save";
import Close from "@material-ui/icons/Close";

import SelectSearch from "Custom/SelectSearch.jsx";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import FormValidationHelper from "../../Helpers/form-validation.js";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import { userActions, alertActions, utilsActions } from "../../Actions";

/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";

import { Constants, Authorize } from "Helpers/model-view.js";

function Transition(props) {
    return <Slide direction="down" {...props} />;
}

const styles = {
    ...extendedFormsStyle,
    selectLabel: {
        fontSize: "16px",
        //textTransform: "uppercase",
        color: "rgba(0, 0, 0, 0.87)",
        top: "8px",
    },
    tcSignSelectControl: {
        margin: "7px 1px 0px 0px !important",
    },
    tcSign_tree_item: {
        color: "black",
    },
};

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
`;

const initialState = {
    direzione: "1",
    closeAlert: false,
    checked: undefined,
    expanded: undefined,
    unita_organizzative: undefined,
    ids_uo_visibili: undefined,
    ids_uo_visibili_expanded: undefined,
    id: undefined,
    priorita: undefined,
    tipo_percorso: undefined,
    username: undefined,
    usernameState: undefined,
    nome: undefined,
    nomeState: undefined,
    cognome: undefined,
    cognomeState: undefined,
    email: undefined,
    emailState: undefined,
    telefono: undefined,
    telefonoState: undefined,
    codice_fiscale: undefined,
    codice_fiscaleState: undefined,
    responsabile_uo: false,
    notifica_priorita_standard: undefined,
    notifica_priorita_urgente: undefined,
    notifica_riepilogo_settimana: undefined,
    notifica_riepilogo_giorno: undefined,
    showModalFirmatario: false,
    cod_integrazione: undefined,
};

class UOModalForm extends Dialog {
    constructor(props, context) {
        super(props, context);

        this.state = initialState;

        if (typeof props.id !== "undefined") {
            this.getData(this.props.id);
        }

        if (
            (typeof this.props.utils.loading === "undefined" || !this.props.utils.loading) &&
            typeof this.props.utils.unita_organizzative === "undefined" &&
            typeof this.props.utils.unita_organizzative === "undefined"
        ) {
            this.props.dispatch(utilsActions.getUnitaOrganizzative());
        }
    }

    getData(idUO) {
        this.props.dispatch(userActions.getUOById(idUO));
    }

    componentDidMount() {
        //const { id, users, utils } = this.props;
        //const { ruoli_utente, unita_organizzative } = utils;
        //if ((typeof (users) === "undefined" || typeof (users.loading) === "undefined" || !users.loading) &&
        //    typeof (id) !== "undefined" &&
        //    typeof (users.current) === "undefined" &&
        //    typeof(users.error) === "undefined") {
        //    this.getData(this.props.id);
        //}
    }

    handleSelect = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleOpenModalFirmatario() {
        this.setState({
            showModalFirmatario: true,
        });
    }

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }

    handleClose() {
        this.setState(initialState);
        this.props.dispatch(userActions.resetUtente());
        this.props.onClose();
    }

    handleSubmit() {
        const { users, utils } = this.props;

        let nomeState = FormValidationHelper.validateField("required", this.getFormFieldValue("nome"), 1);

        if (nomeState === "error") {
            this.props.dispatch(alertActions.error("Errori di compilazione nella scheda GENERALE"));

            this.setState({
                nomeState: nomeState,
            });

            return;
        }

        this.setState({
            nomeState: nomeState,
        });
        var idCorrente = users.current ? users.current.id_uo_group : undefined;
        let wUO = {
            id: this.props.id ? this.props.id : idCorrente,
            nome: this.getFormFieldValue("nome"),
            id_parent: this.getFormFieldValue("id_parent"),
            cod_integrazione: this.getFormFieldValue("cod_integrazione"),
        };

        //CAMUSI per evitare che la UO "A" possa essere sia padre che figlia della UO "B"
        let id_uo_padre = "";
        id_uo_padre = wUO.id_parent;
        var nome_uo_corrente = users.current ? users.current.nome : undefined;

        var path_uo_padre = "";
        var totale_uo = utils.unita_organizzative.length;
        if (utils && utils.unita_organizzative) {
            for (var i = 0; i < totale_uo; i++) {
                if (utils.unita_organizzative[i].id_uo_group === id_uo_padre) {
                    path_uo_padre = utils.unita_organizzative[i].path;
                    break;
                }
            }
        }
        if (path_uo_padre.indexOf(nome_uo_corrente) !== -1) {
            this.props.dispatch(
                alertActions.error("Unita Organizzativa superiore coincide o è figlia della UO corrente.")
            );
            return;
        }
        //CAMUSI

        this.props.dispatch(userActions.saveUO(wUO));
    }

    handleClickRemoveUnitaOrganizzativa(idUnitaOrganizzativa) {
        let uoList = this.getFormFieldValue("unita_organizzative");

        for (var i = 0; i < uoList.length; i++) {
            if (uoList[i].id_uo_group === idUnitaOrganizzativa) {
                uoList.splice(i, 1);
                break;
            }
        }

        this.setState({ unitaOrganizzative: uoList });
    }

    handleClickRemoveRuolo(idRuolo) {
        let ruoliList = this.getFormFieldValue("ruoli");

        for (var i = 0; i < ruoliList.length; i++) {
            if (ruoliList[i].id_ruolo === idRuolo) {
                ruoliList.splice(i, 1);
                break;
            }
        }

        this.setState({ ruoli: ruoliList });
    }

    handleComprimiVisibilita() {
        this.setState({ ids_uo_visibili_expanded: [] });
    }

    handleEspandiVisibilita() {
        const { utils } = this.props;

        let espandi = [];
        if (typeof utils.unita_organizzative !== "undefined") {
            espandi = utils.unita_organizzative.map((prop, key) => {
                return prop.id_uo_group;
            });
        }

        this.setState({ ids_uo_visibili_expanded: espandi });
    }

    handleAddUnitaOrganizzativa() {
        const { utils, user } = this.props;

        if (typeof this.state.id_unita_organizzativa === "undefined") {
            return;
        }

        for (var i = 0; i < utils.unita_organizzative.length; i++) {
            if (utils.unita_organizzative[i].id_uo_group === this.state.id_unita_organizzativa) {
                let wUnitaOrganizzativeList = this.getFormFieldValue("unita_organizzative");

                for (var j = 0; j < wUnitaOrganizzativeList.length; j++) {
                    if (wUnitaOrganizzativeList[j].id_uo === this.state.id_unita_organizzativa) {
                        return;
                    }
                }

                let wSelectedUO = utils.unita_organizzative[i];
                if (this.state.responsabile_uo) {
                    wSelectedUO.id_responsabile = user.id_utente;
                }
                wUnitaOrganizzativeList.push(wSelectedUO);

                this.setState({
                    unita_organizzative: wUnitaOrganizzativeList,
                    id_unita_organizzativa: undefined,
                    responsabile_uo: undefined,
                });
                return;
            }
        }
    }

    handleAddRuolo() {
        if (this.state.id_ruolo === "undefined") {
            return;
        }

        for (var i = 0; i < Constants.ruoli.length; i++) {
            if (Constants.ruoli[i].id_ruolo === this.state.id_ruolo) {
                let wRuoliList = this.getFormFieldValue("ruoli");

                for (var j = 0; j < wRuoliList.length; j++) {
                    if (wRuoliList[j].id_ruolo === this.state.id_ruolo) {
                        return;
                    }
                }

                wRuoliList.push(Constants.ruoli[i]);
                this.setState({
                    ruoli: wRuoliList,
                    id_ruolo: undefined,
                });
                return;
            }
        }
    }

    handleClickOpenFirmaForm(ruolo) {
        this.setState({
            selected_ruolo: ruolo,
            showModalRuolo: true,
        });
    }

    handleSubmitModalFirma(ruolo) {
        //this.props.dispatch(userActions.editSignature(firma));
    }

    handleCloseModalRuolo() {
        this.setState({
            showModalRuolo: false,
        });
    }

    getFormFieldValue(pFieldName) {
        const { id, users } = this.props;

        if (typeof this.state[pFieldName] !== "undefined") {
            return this.state[pFieldName];
        } else if (typeof id !== "undefined" && typeof users.current !== "undefined") {
            let wReturn = users.current;
            return wReturn[pFieldName];
        }

        return "";
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        const { value } = event.target;

        let wState = {};

        wState[stateName] = value;

        wState[stateName + "State"] = FormValidationHelper.validateField(type, event.target.value, 1);

        this.setState(wState);
    }

    render() {
        const { t, classes, users, utils, tema } = this.props;
        let cardColor = tema.theme_color;
        let alertMessage = "";
        if (alert && typeof alert.message !== "undefined") {
            const message = t(`alert.${alert.message}`);
            if (alert.type === "alert-success") {
                alertMessage = (
                    <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
                        {message}
                    </SweetAlert>
                );
            } else if (alert.type === "alert-danger") {
                alertMessage = (
                    <SweetAlert
                        danger
                        title={t("alert.warning")}
                        onConfirm={() => {
                            this.handleCloseAlert();
                            if (alert.message === "sessionExpired") {
                                this.props.dispatch(userActions.logout());
                            }
                        }}
                    >
                        {message}
                    </SweetAlert>
                );
            }
        }

        let loading;
        if (users && users.loading) {
            loading = (
                <div css={overlay}>
                    <div css={override}>
                        <ClipLoader sizeUnit={"px"} size={80} color={"#3a783d"} loading={users.loading} />
                    </div>
                </div>
            );
        }

        let wButtonSalva = "";
        if (Authorize.checkAuthorizeFunc("FNCCMO")) {
            wButtonSalva = (
                <Button color={cardColor} onClick={() => this.handleSubmit()}>
                    <Save style={{ margin: "0px" }} /> {t("action.save")}
                </Button>
            );
        }

        let optionUnitaOrganizzative = [];
        if (utils && utils.unita_organizzative) {
            optionUnitaOrganizzative = utils.unita_organizzative.map((unitaOrganizzativa) => ({
                value: unitaOrganizzativa.id_uo_group,
                label: unitaOrganizzativa.nome,
            }));
        }

        let wTitle = this.props.title;
        if (typeof users.current !== "undefined") {
            wTitle = "UO: " + this.getFormFieldValue("nome");
        }

        return (
            <div>
                <Dialog
                    PaperProps={
                        {
                            //style: {
                            //    maxWidth: "700px"
                            //},
                        }
                    }
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    scroll="body"
                    fullScreen
                    onClose={() => this.handleClose()}
                    aria-labelledby="notice-modal-slide-title"
                    aria-describedby="notice-modal-slide-description"
                >
                    {loading}
                    {alertMessage}
                    <DialogContent id="notice-modal-slide-description" className={classes.modalBody}>
                        <Card style={{ marginBottom: "0px" }}>
                            <CardHeader color={cardColor} icon>
                                <CardIcon color={cardColor}>
                                    <User />
                                </CardIcon>
                                <h4 className={classes.cardTitle} style={{ color: "#777" }}>
                                    {wTitle}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <NavPills
                                    color={cardColor}
                                    tabs={[
                                        {
                                            tabButton: t("uo.generalTab"),
                                            tabContent: (
                                                <GridContainer style={{ width: "100%", margin: "0px" }}>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <CustomInput
                                                            success={this.state.nomeState === "success"}
                                                            error={this.state.nomeState === "error"}
                                                            labelText={t("uo.name")}
                                                            id="nome"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            inputProps={{
                                                                onChange: (event) =>
                                                                    this.change(event, "nome", "required"),
                                                                type: "nome",
                                                                value: this.getFormFieldValue("nome"),
                                                            }}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <SelectSearch
                                                            label={t("uo.parent")}
                                                            placeholder={t("uo.select")}
                                                            options={optionUnitaOrganizzative}
                                                            isClearable={true}
                                                            id={"id_parent"}
                                                            isSearchable={true}
                                                            onChange={this.handleSelect}
                                                            value={this.getFormFieldValue("id_parent")}
                                                            disabled={false}
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <CustomInput
                                                            labelText={t("uo.intCode")}
                                                            id="cod_integrazione"
                                                            formControlProps={{
                                                                fullWidth: true,
                                                            }}
                                                            inputProps={{
                                                                onChange: (event) =>
                                                                    this.change(event, "cod_integrazione"),
                                                                value: this.getFormFieldValue("cod_integrazione"),
                                                            }}
                                                        />
                                                    </GridItem>
                                                </GridContainer>
                                            ),
                                        },
                                    ]}
                                />
                            </CardBody>
                        </Card>
                    </DialogContent>
                    <DialogActions className={classes.modalFooter + " " + classes.modalFooterCenter}>
                        {wButtonSalva}
                        <Button color="info" onClick={() => this.handleClose()}>
                            <Close style={{ margin: "0px" }} /> {t("action.close")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, users, utils, tema } = state;
    const { user } = authentication ?? {};
    return {
        authentication,
        alert,
        users,
        utils,
        user,
        tema,
    };
}

const connectedUOModalForm = compose(connect(mapStateToProps), withStyles(styles), withTranslation())(UOModalForm);
export default connectedUOModalForm;
