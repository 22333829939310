import React from 'react';
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { List, ListItem, Switch, withStyles } from '@material-ui/core';

import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

const dashboardList = css`
    padding: 0 !important;
    text-align: center;
`;

const switchStyle = () => ({
    ...extendedFormsStyle,
    root: {
        width: 50,
    },
    icon: {
        width: 16,
        height: 16,
        marginRight: 6,
    },
    switchBase: {},
    colorSecondary: {},
    bar: {
        width: 26,
        height: 11,
        marginTop: -5.5,
    },
    checked: {},
    blueBase: {
        width: 36,
        height: 36,
        '&$checked': {
            color: '#3f51b5',
        },
    },
    blueSecondary: {
        '&$checked': {
            '& + $bar': {
                'background-color': '#3f51b5',
            },
        },
    },
    greenBase: {
        width: 36,
        height: 36,
        '&$checked': {
            color: 'green',
        },
    },
    greenSecondary: {
        '&$checked': {
            '& + $bar': {
                'background-color': 'green',
            },
        },
    },
});

const FilterSwitch = ({ t, classes, color, title, icon, checked, onChange, switchColor }) => {
    const { blueBase, blueSecondary, greenBase, greenSecondary } = classes;

    return (
        <GridItem xs={12} sm={6} md={4} lg={3}>
            <Card style={{ marginTop: '30px', marginBottom: '10px' }}>
                <CardHeader color={color} stats icon>
                    <CardIcon color={color} style={{ padding: '13px', marginRight: '4px', width: '95px' }}>
                        <List css={dashboardList}>
                            <ListItem css={dashboardList}>
                                {icon}
                                <Switch
                                    classes={{
                                        root: classes.root,
                                        icon: classes.icon,
                                        bar: classes.bar,
                                        checked: classes.checked,
                                        switchBase: switchColor === 'green' ? greenBase : blueBase,
                                        colorSecondary: switchColor === 'green' ? greenSecondary : blueSecondary,
                                    }}
                                    checked={checked}
                                    onChange={onChange}
                                />
                            </ListItem>
                        </List>
                    </CardIcon>
                    <p className={classes.cardTitle} style={{ marginTop: '10px' }}>
                        {t('document.title')} {title}
                    </p>
                </CardHeader>
            </Card>
        </GridItem>
    );
};

export default compose(withStyles(switchStyle), withTranslation())(FilterSwitch);
