import React from 'react';
import { Tabs, Tab, withStyles } from '@material-ui/core';
import FEA from './FEA';
import FirmaDigitale from './FirmaDigitale';
import { primaryColor } from 'assets/jss/material-dashboard-pro-react';

const styles = () => ({
    tab: {
        borderRadius: '5px',
        minHeight: '30px',
    },
    indicator: {
        backgroundColor: primaryColor,
    },
});

class FD_FEA extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            activeTab: 0,
        };
    }

    setTab = (event, value) => {
        this.setState({
            activeTab: value,
        });
    };
    render() {
        const { t, classes, firmatario, setSignature, applySignature, documenti } = this.props;
        const { activeTab } = this.state;

        return (
            <>
                <Tabs
                    value={activeTab}
                    onChange={this.setTab}
                    classes={{ indicator: classes.indicator }}
                >
                    {['FEA', 'Firma digitale'].map((mode, index) => (
                        <Tab
                            disabled={documenti?.firmatario_esterno?.firmato ? true : false}
                            key={index}
                            classes={{
                                root: classes.tab,
                            }}
                            label={mode}
                        />
                    ))}
                </Tabs>
                <TabPanel value={activeTab} index={0}>
                    <FEA
                        firmatario={firmatario}
                        setSignature={setSignature}
                        applySignature={applySignature}
                    />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <FirmaDigitale />
                </TabPanel>
            </>
        );
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} {...other} style={{ width: '100%' }}>
            {value === index && children}
        </div>
    );
}

export default withStyles(styles)(FD_FEA);
