import React from 'react';
import { withStyles, DialogActions } from '@material-ui/core';
import CustomButton from 'components/CustomButtons/Button.jsx';


const styles = () => ({
    footerWrapper: {
        margin: '1em 0 0 0',
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
});

function Footer(props) {
    const {
        buttonDisabledProp,
        resendOTPDisabled,
        onClickButton,
        buttonTitle,
        resendOTP,
        fetching,
        setPhase,
        verified,
        classes,
        step,
    } = props;

    return (
        <DialogActions disableActionSpacing={true} className={classes.footerWrapper}>
            {!verified && !fetching ? (
                <>
                    {step === 2 ? (
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CustomButton size="sm" color="primary" onClick={resendOTP} disabled={resendOTPDisabled}>
                                Reinvia codice
                            </CustomButton>
                            <CustomButton size="sm" color="primary" onClick={() => setPhase(1)}>
                                Back
                            </CustomButton>
                        </div>
                    ) : null}
                    <CustomButton
                        size="sm"
                        color="primary"
                        disabled={buttonDisabledProp && buttonDisabledProp !== '' ? false : true}
                        onClick={onClickButton}
                    >
                        {buttonTitle}
                    </CustomButton>
                </>
            ) : null}
        </DialogActions>
    );
}
export default withStyles(styles)(Footer);
