import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { MenuItem, withStyles, TextField } from '@material-ui/core';

import { analyticsActions } from '../../../../Actions';

import { MenuProps, STYLES, TIMEFRAME } from '../Constants';

const { scopeOptions } = TIMEFRAME;

const AnalysisFilters = ({ classes }) => {
  const { t } = useTranslation();
  const { documentsState } = t('analytics', { returnObjects: true }).filtersLabels;
  const states = t('analytics', { returnObjects: true }).states;
  const dispatch = useDispatch();
  const commonFilters = useSelector((state) => state.analytics.overall.options);
  const options = useSelector((state) => state.analytics.timeframe.options);
  const { scope } = options;

  const onChange = ({ target: { value, name } }) => {
    dispatch(analyticsActions.setTimeframeOptions({ [name]: value }));
    dispatch(
      analyticsActions.getTimeframeAnalysis({ ...commonFilters, ...options, [name]: value })
    );
  };

  const analysisInputs = [
    {
      value: scope,
      name: 'scope',
      label: documentsState,
      onChange: onChange,
      options: scopeOptions(states),
    },
  ];

  return (
    <div className={classes.flexHorizontal}>
      {analysisInputs.map(({ value, name, label, onChange, options, endAdornment }) => (
        <TextField
          key={label}
          select
          name={name}
          margin="dense"
          value={value}
          label={label}
          style={{ width: '200px', margin: 0 }}
          InputLabelProps={{ FormLabelClasses: { focused: classes.selectLabel } }}
          InputProps={{ endAdornment }}
          SelectProps={{
            style: { fontSize: '14px' },
            MenuProps,
            classes: { outlined: classes.outlined },
          }}
          variant="outlined"
          onChange={onChange}
        >
          {options.map(({ label, value }) => (
            <MenuItem dense key={value} value={value} classes={{ root: classes.selectMenuItem }}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      ))}
    </div>
  );
};

export default withStyles(STYLES)(AnalysisFilters);
