import { authHeader } from '../Helpers';
import { utils } from '../Helpers/utils.js';

var wMount;
if (process.env.NODE_ENV === 'production') {
    wMount = process.env.REACT_APP_SERVICES_URL;
} else {
    wMount = process.env.REACT_APP_SEARCH_URL;
}

const search = (tipoRicerca, ambito, filters, aggregate, pagination, sort, user) => {
    const { size } = pagination;
    const from = size * pagination.pageIndex;
    let formatAmbito = 'all';
    if (ambito.value && ambito.name !== '') formatAmbito = ambito.name[0].toLowerCase().concat(ambito.name.slice(1));

    let url = wMount + `/api/v1/elastic/${formatAmbito}`;

    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ filters, tipoRicerca, aggregate, from, size, sort, user }),
    };
    return fetch(url, requestOptions).then(handleResponse);
};

const handleResponse = (response) => {
    return response.text().then((text) => {
        var data;
        if (text && utils.isJson(text)) data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                return Promise.reject('Operazione non autorizzata, riaccedere al sistema');
            } else {
                return Promise.reject('Sistema momentaneamente non disponibile');
            }
        } else {
            if (data.Result.Code !== 'E100') {
                const error = data?.Result?.Message;
                return Promise.reject(error);
            } else if (typeof data.Data.errors !== 'undefined' && data.Data.errors.length !== 0) {
                let error = '';
                for (var i = 0; i < data.Data.errors.length; i++) {
                    error = data.Data.errors[i].error + '(' + data.Data.errors[i].id_documento + ')\n';
                }
                return Promise.reject(error);
            }
        }

        return data.Data;
    });
};

export const searchService = { search };
