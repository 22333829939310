import { posFirmaConstants } from '../Constants/posFirma.constants';

export function posizionamentoFirma(state = {}, action) {
    switch (action.type) {
        case posFirmaConstants.SET_POS_FIRMA_DIMENSIONS:
            const { dimensions } = action;
            return { ...state, dimensions };
        case posFirmaConstants.SET_POS_FIRMA_TAGS_FETCHED:
            const { tagsFetched } = action;
            return { ...state, tagsFetched };
        case posFirmaConstants.SET_POS_FIRMA_TAGS:
            const { tags } = action;
            return { ...state, tags };
        case posFirmaConstants.RESET_POS_FIRMA:
            return {};
        default:
            return state;
    }
}
