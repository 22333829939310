/// <reference path="../../Custom/Profilo/ProfiloUnitaOrganizzative.jsx" />
/// <reference path="../../Custom/Profilo/ProfiloFirma.jsx" />
import React from "react";
import { connect } from "react-redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import DeviceHub from "@material-ui/icons/DeviceHub";
import SignDocument from "@material-ui/icons/BorderColor";
import Style from "@material-ui/icons/Style";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import Mail from "@material-ui/icons/Mail";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import ProfiloUnitaOrganizzativa from "Custom/Profilo/ProfiloUnitaOrganizzative.jsx";
import ProfiloFirma from "Custom/Profilo/ProfiloFirma.jsx";
import ProfiloNotifiche from "Custom/Profilo/ProfiloNotifiche.jsx";
import ProfiloDelegati from "Custom/Profilo/ProfiloDelegati.jsx";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import Table from "components/Table/Table.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import FormValidationHelper from "../../Helpers/form-validation.js";
import DelegatoIcon from "@material-ui/icons/SupervisorAccount";
import { userActions, alertActions } from "../../Actions";
import { Authorize } from "Helpers/model-view.js";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";

const overlay = css`
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(18, 37, 19, 0.35); /* Black w/ opacity */
`;

const override = css`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
`;

const initialState = {
    nome_utente: undefined,
    nome_utenteState: undefined,
    cognome_utente: undefined,
    cognome_utenteState: undefined,
    codice_fiscale_utente: undefined,
    email_utente: undefined,
    telefono_utente: undefined,
    alert: undefined,
};

class UserProfile extends React.Component {
    constructor(props, context) {
        super(props, context);

        const { user } = this.props;

        initialState.nome_utente = user.nome;
        initialState.cognome_utente = user.cognome;
        initialState.codice_fiscale_utente = user.codice_fiscale;
        initialState.email_utente = user.email;
        initialState.telefono_utente = user.telefono;

        this.state = initialState;
    }

    handleSubmit() {
        const { user } = this.props;

        let nome_utenteState = FormValidationHelper.validateField("required", this.getFormFieldValue("nome_utente"), 1);
        let cognome_utenteState = FormValidationHelper.validateField(
            "required",
            this.getFormFieldValue("cognome_utente"),
            1
        );

        let wUpdateState = {
            nome_utenteState: nome_utenteState,
            cognome_utenteState: cognome_utenteState,
        };

        if (nome_utenteState === "error" || cognome_utenteState === "error") {
            this.setState(wUpdateState);
            return;
        }

        this.setState(wUpdateState);

        let userEdit = user;

        userEdit.id_utente = user.id_utente;
        userEdit.nome = this.getFormFieldValue("nome_utente");
        userEdit.cognome = this.getFormFieldValue("cognome_utente");
        userEdit.telefono = this.getFormFieldValue("telefono_utente");
        userEdit.email = this.getFormFieldValue("email_utente");
        userEdit.codice_fiscale = this.getFormFieldValue("codice_fiscale_utente");

        this.props.dispatch(userActions.register(userEdit));
    }

    handleCloseAlert() {
        this.props.dispatch(alertActions.clear());
    }

    getFormFieldValue(pFieldName) {
        return this.state[pFieldName];
    }

    change(event, stateName, type, stateNameEqualTo, maxValue) {
        const { value } = event.target;

        this.setState({ [stateName]: value });

        this.setState({
            [stateName + "State"]: FormValidationHelper.validateField(type, value, stateNameEqualTo),
        });
    }

    render() {
        const { t, classes, user, users, alert, tema } = this.props;
        let cardColor = tema.theme_color;

        let loading;
        if (users && users.loading) {
            loading = (
                <div css={overlay}>
                    <div css={override}>
                        <ClipLoader sizeUnit={"px"} size={80} color={"#3a783d"} loading={users.loading} />
                    </div>
                </div>
            );
        }

        let alertMessage = "";
        if (alert && typeof alert.message !== "undefined") {
            const message = t(`alert.${alert.message}`);
            if (alert.type === "alert-success") {
                alertMessage = (
                    <SweetAlert success title="Info" onConfirm={() => this.handleCloseAlert()}>
                        {message}
                    </SweetAlert>
                );
            } else if (alert.type === "alert-danger") {
                alertMessage = (
                    <SweetAlert
                        danger
                        title={t("alert.warning")}
                        onConfirm={() => {
                            this.handleCloseAlert();
                            if (alert.message === "sessionExpired") {
                                this.props.dispatch(userActions.logout());
                            }
                        }}
                    >
                        {message}
                    </SweetAlert>
                );
            }
        }

        let elencoRuoli = [];
        if (typeof user.ruoli !== "undefined") {
            elencoRuoli = user.ruoli.map((prop, key) => {
                return [prop.desc_ruolo];
            });
        }

        return (
            <div>
                {alertMessage}
                {loading}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card>
                            <CardHeader color={cardColor} icon>
                                <CardIcon color={cardColor}>
                                    <PermIdentity />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>
                                    {t("user.data")} - <small>{user.username}</small>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            success={this.state.nome_utenteState === "success"}
                                            error={this.state.nome_utenteState === "error"}
                                            labelText={t("user.name")}
                                            id="nome_utente"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) => this.change(event, "nome_utente", "required"),
                                                type: "nome_utente",
                                                value: this.getFormFieldValue("nome_utente") || "",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            success={this.state.cognome_utenteState === "success"}
                                            error={this.state.cognome_utenteState === "error"}
                                            labelText={t("user.lastName")}
                                            id="cognome_utente"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) => this.change(event, "cognome_utente", "required"),
                                                type: "nome_utente",
                                                value: this.getFormFieldValue("cognome_utente") || "",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText={t("user.taxCode")}
                                            id="codice_fiscale_utente"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) =>
                                                    this.change(event, "codice_fiscale_utente", "required"),
                                                type: "nome_utente",
                                                value: this.getFormFieldValue("codice_fiscale_utente") || "",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText={t("user.phoneNumber")}
                                            id="telefono_utente"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) => this.change(event, "telefono_utente", "required"),
                                                type: "telefono_utente",
                                                value: this.getFormFieldValue("telefono_utente") || "",
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                            labelText={t("user.email")}
                                            id="email_utente"
                                            formControlProps={{
                                                fullWidth: true,
                                            }}
                                            inputProps={{
                                                onChange: (event) => this.change(event, "email_utente", "required"),
                                                type: "nome_utente",
                                                value: this.getFormFieldValue("email_utente") || "",
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <Button
                                    color={cardColor}
                                    className={classes.updateProfileButton}
                                    onClick={() => this.handleSubmit()}
                                >
                                    {t("action.save")}
                                </Button>
                                <Clearfix />
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                        <CustomTabs
                            headerColor={cardColor}
                            tabs={[
                                {
                                    tabName: t("user.uoTab"),
                                    tabIcon: DeviceHub,
                                    tabContent: <ProfiloUnitaOrganizzativa />,
                                },
                                {
                                    tabName: t("user.userRolesTab"),
                                    tabIcon: Style,
                                    tabContent: (
                                        <Table
                                            hover
                                            tableData={elencoRuoli}
                                            customCellClasses={[classes.tcSignTableCell]}
                                            customClassesForCells={[0]}
                                        />
                                    ),
                                },
                                {
                                    tabName: t("user.digitalSignaturesTab"),
                                    tabIcon: SignDocument,
                                    tabContent: <ProfiloFirma />,
                                },
                                {
                                    tabName: t("user.notificationsTab"),
                                    tabIcon: Mail,
                                    tabContent: <ProfiloNotifiche />,
                                },
                            ]}
                        />
                    </GridItem>
                    {Authorize.checkAuthorizeFunc("FNCGDG") ? (
                        <GridItem xs={12} sm={12} md={12}>
                            <CustomTabs
                                headerColor={cardColor}
                                tabs={[
                                    {
                                        tabName: t("user.delegatesTab"),
                                        tabIcon: DelegatoIcon,
                                        tabContent: <ProfiloDelegati />,
                                    },
                                ]}
                            />
                        </GridItem>
                    ) : (
                        ""
                    )}
                </GridContainer>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { alert, authentication, utils, users, tema } = state;
    const { user } = authentication ?? {};

    return {
        user,
        alert,
        utils,
        users,
        tema,
    };
}

const connectedUserProfile = compose(
    connect(mapStateToProps),
    withStyles(userProfileStyles),
    withTranslation()
)(UserProfile);
export default connectedUserProfile;
