import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Loading from '../ReusableComponents/Loading';
import Chart from './Chart';
import DownloadChart from './DownloadChart';
import AnalysisFilters from './Filters';

const TimeframeAnalysis = () => {
	const { t } = useTranslation();
	const { temporalAnalysis } = t('analytics', { returnObjects: true }).chartsLabels;
	const cardColor = useSelector(({ tema }) => tema.theme_color);
	const loading = useSelector((state) => state.analytics.timeframe.loading);
	const result = useSelector((state) => state.analytics.timeframe.result);

	return (
		<Card>
			<CardHeader style={{ width: 'fit-content' }} color={cardColor}>
				<Typography
					style={{
						color: 'white',
						fontWeight: 600,
						textTransform: 'uppercase',
						fontSize: '0.65rem'
					}}
				>
					{temporalAnalysis}
				</Typography>
			</CardHeader>
			<CardBody style={{ height: '400px' }}>
				<AnalysisFilters />
				{loading && result.length === 0 ? (
					<Loading />
				) : (
					<div style={{ width: '100%', height: '100%' }}>
						<Chart />
						<div style={{ opacity: 0, position: 'absolute' }}>
							<DownloadChart />
						</div>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

export default TimeframeAnalysis;
