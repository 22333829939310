import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { withStyles, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import { searchActions } from '../../../Actions';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

const styles = {
    ...extendedFormsStyle,
    card: {
        marginTop: '0px',
        marginBottom: '2em',
        padding: '5px',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
};

class GroupBySidebar extends React.Component {
    state = {
        showTipologiaOptions: true,
        showStatoOptions: true,
    };

    handleCheckbox = (event) => {
        const { filters } = this.props;
        const { name, value, checked } = event.target;
        const parsedValue = parseInt(value);

        //Redux
        let shallowCopy = filters[name] ? [...filters[name]] : [];

        if (checked && !shallowCopy.includes(parsedValue)) shallowCopy.push(parsedValue);
        else if (!checked && shallowCopy.includes(parsedValue))
            shallowCopy = shallowCopy.filter((val) => val !== parsedValue);

        this.props.dispatch(searchActions.setFilterAndSearch(name, shallowCopy));
    };

    toggleExpansion = (name) => {
        const prop = `show${name}Options`;

        this.setState({
            [prop]: !this.state[prop],
        });
    };

    render() {
        const { showTipologiaOptions, showStatoOptions } = this.state;
        const { classes, utils, aggregati, filters, tema, t } = this.props;
        
        const status = t('document.status', { returnObjects: true });
        const type = t('document.documentType', { returnObjects: true });

        const optionsTipologia = [],
            optionsStatoDocumento = [];

        let cardColor = tema.theme_color;
        if (aggregati.tipo) {
            utils.tipologia_documento.forEach((uo) => {
                const tipoKeys = aggregati.tipo.map((tipo) => tipo.key);

                if (tipoKeys.includes(uo.id_tipo_documento))
                    optionsTipologia.push({
                        value: uo.id_tipo_documento,
                        label: type[uo.desc_tipo_documento],
                    });
            });
        }

        if (aggregati.stato) {
            utils.stato_documento.map((uo) => {
                const tipoKeys = aggregati.stato.map((stato) => stato.key);
                if (tipoKeys.includes(uo.id_stato_documento))
                    optionsStatoDocumento.push({
                        value: uo.id_stato_documento,
                        label: status[uo.desc_stato_documento],
                    });
            });
        }

        const groupByList = [
            {
                title: t('table.headers.type'),
                name: 'id_tipo_documento',
                showOptions: showTipologiaOptions,
                options: optionsTipologia,
            },
            {
                title: t('table.headers.status'),
                name: 'id_stato_documento',
                showOptions: showStatoOptions,
                options: optionsStatoDocumento,
            },
        ];

        return groupByList.map((filter) => {
            const { title, showOptions, options, name } = filter;
            return (
                <Card className={classes.card} key={name}>
                    <CardHeader color={cardColor}>
                        <div className={classes.spaceBetween}>
                            {title}
                            <Button
                                style={{ padding: 0, minWidth: 0, color: 'white' }}
                                onClick={() => this.toggleExpansion(title)}
                            >
                                {showOptions ? <ExpandLess /> : <ExpandMore />}
                            </Button>
                        </div>
                    </CardHeader>
                    {showOptions && (
                        <CardBody>
                            {options.map((option, index) => {
                                const { value, label } = option;
                                const checked = filters[name]?.includes(value) ? true : false;
                                return (
                                    <div className={classes.spaceBetween} key={index}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    name={name}
                                                    style={{ padding: '2px 5px' }}
                                                    onChange={(event) => this.handleCheckbox(event)}
                                                    checked={checked}
                                                    value={value.toString()}
                                                />
                                            }
                                            label={label}
                                            labelPlacement="end"
                                        />
                                    </div>
                                );
                            })}
                        </CardBody>
                    )}
                </Card>
            );
        });
    }
}

const mapStateToProps = (state) => {
    const { utils, search, tema } = state;
    const { tipoRicerca, ambito, filters, pagination, aggregati, sort } = search;

    return {
        utils,
        tipoRicerca,
        ambito,
        pagination,
        filters,
        sort,
        aggregati,
        tema,
    };
};
export default connect(mapStateToProps)(withTranslation()(withStyles(styles)(GroupBySidebar)));
