import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Typography } from '@material-ui/core';

import { getTypesOptions, TIMEFRAME } from '../Constants';

import { analyticsActions } from '../../../../Actions';

const DownloadChart = () => {
  const { t } = useTranslation();
  const { documentsType, documentsState } = t('analytics', { returnObjects: true }).filtersLabels;
  const states = t('analytics.states', { returnObjects: true });
  const all = t('document.all');

  const dispatch = useDispatch();
  const documentiTypes = useSelector((state) => state.utils.tipologia_documento);
  const tipo_documento = useSelector((state) => state.analytics.overall.options.tipo_documento);
  const language = useSelector((state) => state.settings.language);
  const { result, timeframe, options } = useSelector((state) => state.analytics.timeframe);

  const { scope } = options;

  const timeframeRefCB = useCallback((el) => {
    if (el) dispatch(analyticsActions.setChartRef({ chartName: 'timeframe', ref: el }));
  }, []);

  const documentTypeLabel = useMemo(
    () =>
      getTypesOptions(documentiTypes, all).filter(({ value }) => value === tipo_documento)[0].label,
    [documentiTypes, tipo_documento, language, all]
  );

  return (
    <div ref={timeframeRefCB} style={{ width: '1080px', height: '500px', padding: '10px' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
        }}
      >
        <div>
          <Typography>
            {t('research.from')}{' '}
            <span style={{ fontWeight: 600 }}>{moment(timeframe?.from).format('DD/MM/YYYY')}</span>{' '}
            {t('research.to')}{' '}
            <span style={{ fontWeight: 600 }}>{moment(timeframe?.to).format('DD/MM/YYYY')}</span>
          </Typography>
        </div>
        <div>
          <div style={{ display: 'flex', gap: '30px' }}>
            <Typography>
              {documentsType}: <span style={{ fontWeight: 600 }}>{documentTypeLabel}</span>
            </Typography>
            <Typography>
              {documentsState}:{' '}
              <span style={{ fontWeight: 600 }}>
                {TIMEFRAME.scopeOptions(states)?.filter(({ value }) => value === scope)[0].label}
              </span>
            </Typography>
          </div>
        </div>
      </div>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          /* width={900}
          height={400} */
          data={result}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            yAxisId="left"
            orientation="left"
            stroke="#8884d8"
            dataKey="count"
            tickCount={10}
            allowDecimals={false}
          />
          <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
          <Tooltip />
          <Legend />
          <Bar yAxisId="left" name={`No. ${t('menu.documents')}`} dataKey="count" fill="#8884d8" />
          {/* <Bar yAxisId="right" dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DownloadChart;
