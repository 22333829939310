import React from 'react';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom';
import { Button } from '@material-ui/core';

import './assets/bootstrap/css/bootstrap.min.css';
import './assets/scss/material-dashboard-pro-react.css?v=1.2.0';

import './Custom/css/style.css';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { Provider } from 'react-redux';
import { store } from './Helpers/store.js';
import { SnackbarProvider } from 'notistack';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import App from './routes/App.jsx';

import './i18n';
import i18n from './i18n';
//import common_it from "./translations/it/common.json";
//import common_en from "./translations/en/common.json";

export function LoginRedirect() {
    return <Redirect to="/login" />;
}

export function NotFoundRedirect() {
    return <Redirect to="/not_found" />;
}

const notistackRef = React.createRef();

const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <SnackbarProvider
                ref={notistackRef}
                max={3}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                action={(key) => (
                    <Button size="small" style={{ width: '100%', height: '100%' }} onClick={onClickDismiss(key)}>
                        {i18n.t('action.close')}
                    </Button>
                )}
                autoHideDuration={3000}
                preventDuplicate
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '350px',
                    height: 'auto',
                    textAlign: 'justify',
                    whiteSpace: 'pre-wrap',
                }}
            >
                <App />
            </SnackbarProvider>
        </DndProvider>
    </Provider>,

    document.getElementById('root')
);
